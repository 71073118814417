import React from "react";
import { withRouter } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { post } from "../../../api/axios";
import { openAlert } from "simple-react-alert";

const NegocioSincronizarCadete = props => {
    const [cadeteId, setCadeteId] = React.useState("");
    const [loading, setloading] = React.useState(false)

    const handleSubmit = () => {
        setloading(true);
        post("/admin-negocios/cadetes/independientes/asociar/" + cadeteId, {}).then(response => {
            openAlert({ message: "Guardado exitoso", type: "success" });
            setloading(false);
        })
            .catch((error) => {
                openAlert({ message: error.response.data, type: "warning" });
                setloading(false);
            });
    };
    return (
        <>
            <Container className="container-tournament-form">
                <ValidatorForm onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }}>
                            <Row>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Ingresa ID del cadete"
                                        value={cadeteId}
                                        onChange={e =>
                                            setCadeteId(e.target.value)
                                        }
                                        validators={[
                                            "required",
                                        ]}
                                        variant="filled"
                                        className="textInput"
                                        errorMessages={[
                                            "Este campo es requerido"]}
                                    />
                                </Col>
                            </Row>
                            <Col xs={12}>
                                <div className="form-card-header">
                                    <div className="buttons-header">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            className=""
                                            onClick={() =>
                                                props.history.goBack()
                                            }
                                        >
                                            Volver
                                        </Button>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            className="ml-2"
                                            disabled={loading}
                                        >
                                            Guardar
                                        </Button>
                                        {/* {props.editar && (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                className="ml-2"
                                                onClick={() => eliminarCadete()}
                                            >
                                                Eliminar
                                            </Button>
                                        )} */}
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </ValidatorForm>
            </Container>
        </>
    );
};

export default withRouter(NegocioSincronizarCadete);
