import axios from "axios";
import { constants } from "../context/keys";
const BASE_URL = process.env.REACT_APP_API_URL + "/api";

export function get(path) {
    let headers = getTokens();
    return axios.get(BASE_URL + path, { headers: headers });
}

export function login(body) {
    return axios.post(BASE_URL + "/users/login/admin", body);
}

export function getTokens() {
    return {
        Authorization: "Bearer " + sessionStorage.getItem(constants.token),
        Ciudad: JSON.parse(sessionStorage.getItem(constants.userInfo)).ciudad_id,
        Negocio: JSON.parse(sessionStorage.getItem(constants.userInfo)).negocios[0] && JSON.parse(sessionStorage.getItem(constants.userInfo)).negocios[0].id
    };
}

export function post(path, body) {
    let headers = getTokens();
    return axios.post(BASE_URL + path, body, { headers: headers });
}

export function del(path, id) {
    let headers = getTokens();
    return axios.delete(BASE_URL + path + id, { headers: headers });
}

export function update(path, body) {
    let headers = getTokens();
    return axios.put(BASE_URL + path, body, { headers: headers });
}

export function postImage(path, body) {
    let headers = getTokens();
    headers = { ...headers, "Content-Type": "multipart/form-data" };
    return axios.post(BASE_URL + path, body, { headers: headers });
}

export function updateImage(path, body) {
    let headers = getTokens();
    headers = { ...headers, "Content-Type": "multipart/form-data" };
    return axios.put(BASE_URL + path, body, { headers: headers });
}
