import React from 'react';
import moment from 'moment';
import './style.css';

const NumerosDeHoy = ({ completados, cancelados, programados, ...props }) => {

    const horaActual = moment().hour();

    const programadosPendientes = programados.filter(pedido => {
        let horaPedido = moment(pedido.hora, "HH:mm");
        if (horaPedido.hour() - horaActual > 1) {
            return pedido;
        }
    })

    return (
        <div className='containerNumeros'>
            <p><b>Completados:</b> {completados}  <b>Cancelados: </b>{cancelados}  <b>Programados pendientes:</b> {programadosPendientes.length}</p>
        </div>
    )

}

export default NumerosDeHoy;