import React from "react";
import { Button } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { get } from "../../api/axios";
import { List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import "./style.css";
const MenuNegocios = props => {
    const [users, setUsers] = React.useState([]);

    React.useEffect(() => {
        get("/negocios/users").then(response => {
            setUsers(response.data);
        });
    }, []);

    return (
        <>
            <Row
                xs={{ span: 12, offset: 2 }}
                className="justify-content-md-center rowContainer"
            >
                <Col xs={8}>
                    <Button
                        size="large"
                        variant="contained"
                        className="ml-2"
                        onClick={() =>
                            props.history.push(props.match.url + "crear")
                        }
                    >
                        Agregar usuario negocio
                    </Button>

                </Col>
                <Col xs={8}>
                    <List
                        component="nav"
                        aria-label="main mailbox folders"
                        style={{ maxHeight: "400px", overflow: "auto" }}
                    >
                        {users.map((user, key) => {
                            return (
                                <ListItem
                                    button
                                    onClick={() => {
                                        props.history.push({
                                            pathname:
                                                props.match.url + "/editar/" +
                                                user.id
                                        });
                                    }}
                                    key={key}
                                >
                                    <ListItemText primary={user.nombre + ' ' + user.apellido + ' - ' + user.negocios.length + ' negocios'} />
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                </ListItem>
                            );
                        })}
                    </List>
                </Col>
            </Row>
        </>
    );
};

export default withRouter(MenuNegocios);
