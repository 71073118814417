import React, { useState } from "react";
import { Button } from "@material-ui/core";
import NegociosList from "./NegociosList";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "./style.css";
import { openAlert } from 'simple-react-alert';
import { post } from "../../api/axios";

const Options = props => {

  const [loading, setLoading] = useState(false);

  const handleOnOrdenarNegocios = () => {
    setLoading(true);
    post("/negocios/ordenar").then(response => {
      if (response.status == 200) {
        openAlert({ message: "Negocios ordenados exitosamente!", type: "success" });
      } else {
        openAlert({ message: "Error al ordenar negocios", type: "error" });
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <React.Fragment>
      <Row className={"rowContainer"}>
        <Col xs={{ span: 8 }} md={{ offset: 2, span: 4 }}>
          <Button
            variant="contained"
            size='large'
            onClick={() => {
              props.history.push(props.history.location.pathname + "/nuevo");
            }}
          >
            Agregar nuevo negocio
          </Button>
        </Col>
        <Col xs={{ span: 8 }} md={{ offset: 2, span: 4 }}>
          <Button
            variant="contained"
            size='large'
            disabled={loading}
            onClick={handleOnOrdenarNegocios}
          >
            Ordenar negocios
          </Button>
        </Col>
        <Col xs={{ span: 8, offset: 2 }}>
          <NegociosList />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(Options);
