import React, { useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { FormControlLabel, Checkbox, RadioGroup, FormLabel, Radio, Typography } from "@material-ui/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withRouter } from "react-router";
import "./style.css";
import {
    ValidatorForm,
    TextValidator,
} from "react-material-ui-form-validator";
import { get } from "../../../api/axios";
import { update, post } from "../../../api/axios";
import Loader from "../../../Loader";
import { openAlert } from "simple-react-alert";
const DatosEnvioForm = props => {
    const [loader, setLoader] = React.useState(false);
    const [precioBase, setPrecioBase] = React.useState(0);
    const [precioSuma, setPrecioSuma] = React.useState(0);
    const [kmsBase, setKmsBase] = React.useState(0);
    const [kmsSuma, setKmsSuma] = React.useState(0);
    const [tipoEnvio, setTipoEnvio] = React.useState("fijo");

    useEffect(() => {
        get("/admin-negocios/tarifas").then(response => {
            setKmsBase(response.data.kms_envio_base);
            setKmsSuma(response.data.kms_envio_suma);
            setPrecioBase(response.data.precio_envio_base);
            setPrecioSuma(response.data.precio_envio_suma);
            setTipoEnvio(response.data.precio_envio_suma === 0 ? 'fijo' : 'variable');
        });
    }, []);

    const handleSubmit = () => {
        setLoader(true);
        let body = {
            kms_envio_base: tipoEnvio === 'fijo' ? 10000 : kmsBase,
            kms_envio_suma: tipoEnvio === 'fijo' ? 10000 : kmsSuma,
            precio_envio_base: precioBase,
            precio_envio_suma: tipoEnvio === 'fijo' ? 0 : precioSuma
        }
        post("/admin-negocios/tarifas", body).then(response => {
            openAlert({
                message: "Guardado exitoso",
                type: "success"
            });
            setLoader(false);
        }
        )
            .catch(error => {
                openAlert({
                    message: "Hubo un error",
                    type: "warning"
                });
                setLoader(false);
            })
    }

    const renderEnvio = () => {
        return (<>
            <Col xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Establezca su tipo de envio</FormLabel>
                    <RadioGroup row aria-label="Tipo de envio" value={tipoEnvio} onChange={(event) => { setTipoEnvio(event.target.value) }}>
                        <FormControlLabel value="fijo" control={<Radio />} label="Fijo" />
                        <FormControlLabel value="variable" control={<Radio />} label="Variable" />
                    </RadioGroup>
                </FormControl>
            </Col>
            { tipoEnvio === "variable" ?
                <>
                    <Col xs={12}>
                        <Typography variant="h6" style={{ marginBottom: '2rem' }}> Por favor, complete la siguiente frase:</Typography>
                    </Col>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Col xs={2} className="mb-2">
                            <Typography variant="h6"> Hasta</Typography>
                        </Col>
                        <Col xs={2} className="mb-2">
                            <TextValidator
                                label="Kms"
                                value={kmsBase}
                                onChange={e => setKmsBase(e.target.value)}
                                validators={["required"]}
                                variant="filled"
                                className="textInput"
                                errorMessages={["Este campo es requerido"]}
                            />
                        </Col>
                        <Col xs={3} className="mb-2">
                            <Typography variant="h6"> Kms, cobrar </Typography>
                        </Col>
                        <Col xs={2} className="mb-2">
                            <TextValidator
                                label="Pesos"
                                value={precioBase}
                                onChange={e => setPrecioBase(e.target.value)}
                                validators={["required"]}
                                variant="filled"
                                className="textInput"
                                errorMessages={["Este campo es requerido"]}
                            />
                        </Col>
                        <Col xs={2} className="mb-2">
                            <Typography variant="h6"> Pesos.</Typography>
                        </Col>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
                        <Col xs={3} className="mb-2">
                            <Typography variant="h6"> Luego, sumar </Typography>
                        </Col>
                        <Col xs={2} className="mb-2">
                            <TextValidator
                                label="Pesos"
                                value={precioSuma}
                                onChange={e => setPrecioSuma(e.target.value)}
                                validators={["required"]}
                                variant="filled"
                                className="textInput"
                                errorMessages={["Este campo es requerido"]}
                            />
                        </Col>
                        <Col xs={2} className="mb-2">
                            <Typography variant="h6"> cada </Typography>
                        </Col>
                        <Col xs={2} className="mb-2">
                            <TextValidator
                                label="Kms"
                                value={kmsSuma}
                                onChange={e => setKmsSuma(e.target.value)}
                                validators={["required"]}
                                variant="filled"
                                className="textInput"
                                errorMessages={["Este campo es requerido"]}
                            />
                        </Col>
                        <Col xs={2} className="mb-2">
                            <Typography variant="h6"> kms. </Typography>
                        </Col>
                    </div>
                </>
                :
                <Col xs={6} className="mb-2">
                    <TextValidator
                        label="Precio de envio"
                        value={precioBase}
                        onChange={e => setPrecioBase(e.target.value)}
                        validators={["required"]}
                        variant="filled"
                        className="textInput"
                        errorMessages={["Este campo es requerido"]}
                    />
                </Col>
            }
        </>
        )
    }

    return (
        <Container className="container-tournament-form">
            <ValidatorForm onSubmit={handleSubmit}>
                <Row>
                    <Col xs={{ span: 8, offset: 2 }}>
                        <Row>
                            {renderEnvio()}
                            <Col xs={12}>
                                <div className="form-card-header">
                                    <div className="buttons-header">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            onClick={() =>
                                                props.history.goBack()
                                            }
                                        >
                                            Volver
                                        </Button>
                                        {loader ? (
                                            <Loader />
                                        ) : (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                                className="ml-2"
                                            >
                                                Guardar
                                            </Button>
                                        )}
                                    </div>
                                </div>{" "}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ValidatorForm>
        </Container>
    );
};

export default withRouter(DatosEnvioForm);
