import React from "react";
import {
    Container,
    FormControlLabel,
    Checkbox,
    Button
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Loader from "../../Loader";
import { withRouter } from "react-router";
import "./style.css";
/*
"gusto2" => [
    "modificaPrecio" => false,
    "min" => 1,
    "max" => 2,
    "prefijo" => "",
    "error"  => "Debe seleccionar al menos 1 gusto y como máximo 2",
    "titulo" => "Seleccionar los gustos",
    "nombre" => "2 Gustos"
],*/

const AdministrarTipos = props => {
    const [modifica, setModifica] = React.useState(false);
    const [minimo, setMinimo] = React.useState(0);
    const [maximo, setMaximo] = React.useState(0);
    const [prefijo, setPrefijo] = React.useState("");
    const [error, setError] = React.useState("");
    const [titulo, setTitulo] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    const [nombre, setNombre] = React.useState("");

    const handleSubmit = () => { };
    return (
        <Container className="container">
            <ValidatorForm onSubmit={handleSubmit}>
                <Row>
                    <Col xs={{ span: 8, offset: 2 }}>
                        <Row>
                            <Col xs={6} className="mb-2">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={modifica}
                                            onChange={e =>
                                                setModifica(e.target.value)
                                            }
                                            value="Modifica precio"
                                        />
                                    }
                                    label="Modifica precio"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Minimo"
                                    value={minimo}
                                    onChange={e => setMinimo(e.target.value)}
                                    validators={["required", "isNumber"]}
                                    errorMessages={[
                                        "Es requerido",
                                        "Debe ser un numero"
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Maximo"
                                    value={maximo}
                                    onChange={e => setMaximo(e.target.value)}
                                    validators={["required", "isNumber"]}
                                    errorMessages={[
                                        "Es requerido",
                                        "Debe ser un numero"
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Prefijo"
                                    placeholder="Extra"
                                    value={prefijo}
                                    onChange={e => setPrefijo(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={["Es requerido"]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Mensaje de error"
                                    value={error}
                                    placeholder="Debe seleccionar al menos 1 gusto y como máximo 2"
                                    onChange={e => setError(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={["Es requerido"]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Titulo para la app"
                                    placeholder="Seleccionar los gustos"
                                    value={titulo}
                                    onChange={e => setTitulo(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={["Es requerido"]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Nombre del tipo"
                                    placeholder="Gusto"
                                    value={nombre}
                                    onChange={e => setNombre(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={["Es requerido"]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={12}>
                                <div className="form-card-header">
                                    <div className="buttons-header">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            className=""
                                            onClick={() =>
                                                props.history.goBack()
                                            }
                                        >
                                            Volver
                                        </Button>
                                        {loader ? (
                                            <Loader />
                                        ) : (
                                                <Button
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    className="ml-2"
                                                >
                                                    Guardar
                                            </Button>
                                            )}
                                    </div>
                                </div>{" "}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ValidatorForm>
        </Container>
    );
};

export default withRouter(AdministrarTipos);
