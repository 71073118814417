import React, { useState, useEffect } from 'react'
import Pedidos from './Pedidos';
import PedidosNegocio from '../betaNegocios/pedidos/NegocioPedidos';
import { isNegocio } from '../../context/methods/isNegocio';
import { isCadeteria } from '../../context/methods/isCadeteria';
import { StoreContext } from '../../context/Store'
import PedidosCadeteria from '../betaCadeterias/pedidos/CadeteriaPedidos';

const PedidosContainer = () => {
    const { state } = React.useContext(StoreContext);
    const [negocio, setNegocio] = useState(0);
    const [cadeteria, setCadeteria] = useState(0);

    useEffect(() => {
        state.userInfo && setNegocio(isNegocio(state.userInfo));
        state.userInfo && setCadeteria(isCadeteria(state.userInfo));
    }, [state])

    return negocio === 0 ? <p>cargando</p> : (negocio ? <PedidosNegocio /> : (cadeteria ? <PedidosCadeteria /> : <Pedidos />))
}

export default PedidosContainer;