import React from "react";
import { withRouter } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { SelectValidator, ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { post, del, get, update } from "../../api/axios";
import { openAlert } from "simple-react-alert";
import { FormControl, MenuItem, TextField } from "@material-ui/core";
import moment from 'moment';

const AgregarPromocion = props => {
    const [comision, setComision] = React.useState(20);
    const [limite, setLimite] = React.useState(1);
    const [negociosList, setNegociosList] = React.useState([]);
    const [negocio, setNegocio] = React.useState(0);
    const [productosList, setProductosList] = React.useState([]);
    const [producto, setProducto] = React.useState(0);
    const [fechaFin, setFechaFin] = React.useState(moment());
    const [fechaInicio, setFechaInicio] = React.useState(moment());


    React.useEffect(() => {
        get('/negocios').then(res => {
            setNegociosList(res.data);
        })
        if (props.editar) {
            get("/promociones/" + props.match.params.id).then(response => {
                get('/negocios').then(res => {
                    setNegociosList(res.data);
                    get("/negocios/" + response.data.producto.negocio.id + "/productos").then(
                        respo => {
                            respo.data.map((tipo) => {
                                productosList.push(...tipo.productos)
                            }
                            )
                            setProductosList(productosList);
                            setFechaFin(moment(response.data.fecha_fin));
                            setFechaInicio(moment(response.data.fecha_inicio));
                            setLimite(response.data.limite);
                            setComision(response.data.comision * 100);
                            setNegocio(response.data.producto.negocio.id);
                            setProducto(response.data.producto.id)
                        }
                    );

                })

            });
        }
    }, []);



    const eliminarPublicidad = () => {
        del("/promociones/" + props.match.params.id).then(response => {
            openAlert({ message: "Guardado exitoso", type: "success" });
            props.history.goBack();
        });
    };

    const setNegocios = (negocioId) => {
        setNegocio(negocioId)
        let productosList = [];
        get("/negocios/" + negocioId + "/productos").then(
            response => {
                response.data.map((tipo) => {
                    productosList.push(...tipo.productos)
                }
                )
                setProductosList(productosList);
            }
        );
    }
    const handleSubmit = () => {
        let body = {
            comision: comision / 100,
            limite,
            fecha_inicio: fechaInicio.format('YYYY-MM-DD').concat('T00:00:00'),
            fecha_fin: fechaFin.format('YYYY-MM-DD').concat('T23:59:59'),
            producto_id: producto
        };

        if (props.editar) {
            update("/promociones/" + props.match.params.id, body).then(response => {
                openAlert({ message: "Guardado exitoso", type: "success" });
            });
        } else {
            post("/promociones", body).then(response => {
                openAlert({ message: "Guardado exitoso", type: "success" });
            });
        }
    };
    return (
        <>
            <Container className="container-tournament-form">
                <ValidatorForm onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }}>
                            <Row>
                                <Col xs={6} style={{ marginBottom: '20px' }}>
                                    <TextField
                                        id="fecha"
                                        label="Desde"
                                        type="date"
                                        value={fechaInicio.format("YYYY-MM-DD")}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        className="datePicker"
                                        onChange={e => setFechaInicio(moment(e.target.value))}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <TextField
                                        id="fecha"
                                        label="Hasta"
                                        type="date"
                                        value={fechaFin.format("YYYY-MM-DD")}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        className="datePicker"
                                        onChange={e => setFechaFin(moment(e.target.value))}
                                    />
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                                        <SelectValidator
                                            label="Negocio"
                                            value={negocio}
                                            onChange={e => setNegocios(e.target.value)}
                                            validators={["required"]}
                                            variant="filled"
                                            errorMessages={["Este campo es requerido"]}
                                        >
                                            {negociosList.map((negocio, key) => {

                                                return <MenuItem key={key} value={negocio.id}>{negocio.nombre}</MenuItem>
                                            })}
                                        </SelectValidator>
                                    </FormControl>
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                                        <SelectValidator
                                            label="Producto"
                                            value={producto}
                                            onChange={e => setProducto(e.target.value)}
                                            validators={["required"]}
                                            variant="filled"
                                            errorMessages={["Este campo es requerido"]}
                                        >
                                            {productosList.map((producto, key) => {
                                                return <MenuItem value={producto.id}>{producto.titulo}</MenuItem>
                                            })}
                                        </SelectValidator>
                                    </FormControl>
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Comision en porcentaje"
                                        value={comision}
                                        onChange={e =>
                                            setComision(e.target.value)
                                        }
                                        validators={[
                                            "required",
                                        ]}
                                        variant="filled"
                                        className="textInput"
                                        errorMessages={[
                                            "Este campo es requerido",
                                        ]}
                                    />
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Limite por usuario"
                                        value={limite}
                                        onChange={e =>
                                            setLimite(e.target.value)
                                        }
                                        validators={[
                                            "required",
                                        ]}
                                        variant="filled"
                                        type="number"
                                        className="textInput"
                                        errorMessages={[
                                            "Este campo es requerido",
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Col xs={12}>
                                <div className="form-card-header">
                                    <div className="buttons-header">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            className=""
                                            onClick={() =>
                                                props.history.goBack()
                                            }
                                        >
                                            Volver
                                        </Button>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            className="ml-2"
                                        >
                                            Guardar
                                        </Button>
                                        {props.editar && (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                className="ml-2"
                                                onClick={() => eliminarPublicidad()}
                                            >
                                                Eliminar
                                            </Button>
                                        )}
                                    </div>
                                </div>{" "}
                            </Col>
                        </Col>
                    </Row>
                </ValidatorForm>
            </Container>
        </>
    );
};

export default withRouter(AgregarPromocion);
