import React from 'react';
import moment from 'moment';
import './style.css';

const NumerosDeHoy = ({ completados, cancelados, cadetes, ...props }) => {

    return (
        <div className='containerNumeros'>
            <p><b>Completados:</b> {completados}  <b>Cancelados: </b>{cancelados}  <b>Cadetes disponibles:</b> {cadetes.length}</p>
        </div>
    )

}

export default NumerosDeHoy;