import React, { useContext } from "react";
import { Grid, Card, Switch, FormControl, FormControlLabel, FormGroup } from "@material-ui/core";
import "./style.css";
import ItemPedidoConfirmado from "./ItemPedidoConfirmado";
import ItemCompletado from "./ItemCompletado";
import Item from '../../newDashboard/Item'
import { get, post } from "../../../api/axios";
import Audio from "./Audio";
import Disponibilidad from "../betaDashboardNegocio/Disponibilidad";
import ItemProgramado from './ItemProgramado';
import NumerosDeHoy from './NumerosDeHoy';
import { StoreContext } from "./pedidosContext/Store";
import { StoreContext as UserContext } from '../../../context/Store';
import { openAlert } from 'simple-react-alert';
import ItemEnEntrega from "./ItemEnEntrega";
import ItemAfuera from "./ItemAfuera";
import { useAudio } from '../../../components/audio/Audio';
import NotificacionPlayer from '../../../components/audio/NotificacionPlayer';


import moment from 'moment';

import echo from "../../../echo";
const channel = echo.channel(process.env.REACT_APP_LARAVEL_ECHO_CHANNEL);

const TIMEFORMAT = "YYYY-MM-DD HH:mm:ss";


const PedidosNegocio = () => {
    const { state, dispatch } = useContext(StoreContext)
    const userContext = useContext(UserContext)
    const [nuevos, setNuevos] = React.useState([]);
    const [confirmados, setConfirmados] = React.useState([]);
    const [enEntrega, setEnEntrega] = React.useState([]);
    const [afuera, setAfuera] = React.useState([]);
    const [completados, setCompletados] = React.useState([]);
    const [sinPagar, setSinPagar] = React.useState([]);
    const [cancelados, setCancelados] = React.useState([]);
    const [cadetes, setCadetes] = React.useState([]);
    const [programados, setProgramados] = React.useState([]);
    const [numCompletados, setNumCompletados] = React.useState(0);
    const [numCancelados, setNumCancelados] = React.useState(0);
    const [numProgramados, setNumProgramados] = React.useState(0);
    const [flag, setFlag] = React.useState(true);
    const [actualizar, setActualizar] = React.useState(true);
    const [procesandoEstado, setProcesandoEstado] = React.useState(false);
    const [playingAudio, toggleAudio] = useAudio("http://privacidad.fasterdelivery.com.ar/oficial.mp3", true);
    const [idToCheck, setIdToCheck] = React.useState({ id: 0, type: "" });

    React.useEffect(() => {
        get("/admin-negocios/cadetes/disponibles").then(response => {
            setCadetes(response.data);
            setActualizar(true);
        });
        userContext.state.userInfo.negocios.map((negocio) => {
            console.log(`.negocios-${negocio.id}`);
            channel.listen(`.negocios-${negocio.id}`, (data) => {
                if (data.message === "nuevo" || data.message === "cambio" || data.message === 'confirmado' || data.message === 'pago realizado' || data.message === 'cancelado' || data.message === 'rechazado'  || data.message === 'timer') {
                    if (data.message === 'nuevo') {
                            if (!data.value.tarjeta) {
                                toggleAudio();
                            }
                            setActualizar(true);
                            pedirCadetes();
                            dispatch({
                                type: 'PULL',
                                pull: 1
                            })
                    }
                    if (data.message === 'rechazado') {
                        setIdToCheck({ id: data.value.pedido_id, type: "rechazado" });
                    }
    
                }
                if (data.message === 'cancelado') {
                    setIdToCheck({ id: data.value.pedido_id, type: "cancelado" });
                    setActualizar(true);
                    dispatch({
                        type: 'PULL',
                        pull: 1
                    });
                }
                if (data.message === 'cambio') {
                    setIdToCheck({ id: data.value, type: "modificado su metodo de pago" });
                    setActualizar(true);
                    dispatch({
                        type: 'PULL',
                        pull: 1
                    });
                }
                if (data.message === 'confirmado') {
                    setActualizar(true);
                    dispatch({
                        type: 'PULL',
                        pull: 1
                    });
                }
                if (data.message === 'pago realizado') {
                    setIdToCheck({ id: data.value, type: "pagado" });
                    setActualizar(true);
                    dispatch({
                        type: 'PULL',
                        pull: 1
                    });
                }
                if (data.message === 'cadete-disponible') {
                    pedirCadetes();
                }
                if (data.message === 'cadete-set' && data.value.viejo !== null) {
                    setActualizar(true);
                    dispatch({
                        type: 'PULL',
                        pull: 1
                    })
                    pedirCadetes();
                }
                if (data.message === 'estado') {
                    setActualizar(true);
                    dispatch({
                        type: 'PULL',
                        pull: 1
                    })
                    if (data.value.viejo === 'Afuera') {
                        pedirCadetes();
                    }
                }
                if (data.message === 'estado' && data.value.viejo === 'Búsqueda') {
                    setActualizar(true);
                    dispatch({
                        type: 'PULL',
                        pull: 1
                    })
                }
                if (data.message === 'reasignado') {
                    setIdToCheck({ id: data.value, type: "rechazado por el cadete" });
                    setActualizar(true);
                    dispatch({
                        type: 'PULL',
                        pull: 1
                    })
                }
            });
        })
    }, []);

    React.useEffect(() => {
        if (state.dirty && actualizar && flag) {
            dispatch({
                type: 'PULL',
                pull: 1
            });
        }
        else actualizar && flag && pedirPedidos();
    }, [actualizar, flag])

    React.useEffect(() => {
        if (idToCheck.id && checkId(idToCheck.id)) {
            setActualizar(true);
            pedirCadetes();
            dispatch({
                type: 'PULL',
                pull: 1
            })
            openAlert({
                message: "El pedido con ID: " + idToCheck.id + " fue " + idToCheck.type,
                type: "info",
                duration: 10000
            })
            toggleAudio();
        }
    }, [idToCheck])

    React.useEffect(() => {
        !state.dirty && state.pull && flag && pedirPedidos();
    }, [state.pull, state.dirty])

    const pedirPedidos = () => {
        if (flag) {
            let date = moment().format("YYYY-MM-DD");
            get("/admin-negocios/pedidos?fecha=" + date).then(response => {
                setPedidos(response.data);
                dispatch({
                    type: "PULL",
                    pull: 0
                })
            });
            setActualizar(false);
        }
    };

    const dispatchDirty = (value) => {
        dispatch({
            type: 'MODIFYING',
            dirty: value
        });
    }

    const pedirCadetes = () => {
        get("/admin-negocios/cadetes/disponibles").then(response => {
            setCadetes(response.data);
        });
    }

    const checkId = (id) => {
        let enNuevos = nuevos.find(pedido => pedido.id == id);
        let enConfirmados = false;
        if (!enNuevos) {
            enConfirmados = confirmados.find(pedido => pedido.id == id);
            return enConfirmados;
        }
        if (!enNuevos && !enConfirmados) {
            return sinPagar.find(pedido => pedido.id == id);
        }
        else {
            return enNuevos;
        }
    }

    const setPedidos = pedidos => {
        let nuevos = [];
        let confirmados = [];
        let enEntrega = [];
        let completados = [];
        let afuera = [];
        let cancelados = [];
        let programados = [];
        let sinPagar = [];
        let numProgramados = 0;
        const horaActual = moment().hour();
        pedidos.map(pedido => {
            if (pedido.hora !== 'Inmediata' && (pedido.estado === 'Recibido') && pedido.estado !== 'Cancelado') {
                programados.push(pedido);
                numProgramados++;
                //let horaPedido = moment(pedido.hora, "HH:mm");
                /*if (horaPedido.hour() - horaActual <= 1) {
                    numProgramados++;
                }*/
            }
            else {
                switch (pedido.estado) {
                    case "Recibido": {
                        if ((pedido.tarjeta && pedido.pagado) || !pedido.tarjeta) {
                            nuevos.push(pedido);
                        }
                        if (pedido.tarjeta && !pedido.pagado) {
                            sinPagar.push(pedido);
                        }
                        break;
                    }
                    case "Búsqueda": {
                        confirmados.push(pedido);
                        break;
                    }
                    case "En Camino": {
                        enEntrega.push(pedido);
                        break;
                    }
                    case "Finalizado": {
                        completados.push(pedido);
                        break;
                    }
                    case "Afuera": {
                        afuera.push(pedido);
                        break;
                    }
                    case "Cancelado": {
                        cancelados.push(pedido);
                    }
                    default: {
                        break;
                    }
                }

                return 0;
            }
        });

        setNuevos(nuevos);
        setConfirmados(confirmados);
        setEnEntrega(enEntrega);
        completados.sort((a, b) => {
            let d1 = new Date(a.created_at);
            let d2 = new Date(b.created_at);
            return d2 - d1;
        });
        setNumCompletados(completados.length);
        setNumCancelados(cancelados.length);
        cancelados.sort((a, b) => {
            let d1 = new Date(a.updated_at);
            let d2 = new Date(b.updated_at);
            return d2 - d1;
        })
        setCancelados(cancelados);
        setCompletados(completados);
        setAfuera(afuera);
        setSinPagar(sinPagar);
        setNumProgramados(numProgramados);
        setProgramados(programados);
        if (nuevos.length > 0) {
            return <Audio />;
        }
    };

    const cancelarPedido = (id, razon, setShowModal) => {
        post("/pedidos/" + id + "/cancelar", { razon: razon }).then(() => {
            dispatch({
                action: "PULL",
                pull: 1
            });

            openAlert({
                message: "pedido eliminado con exito ",
                type: "success",
                duration: 10000
            });
            setShowModal(false);
        }).catch(openAlert({
            message: "El pedido no pudo ser eliminado",
            type: "error"
        }));
    };

    const setTimestamp = (id, timestamp) => {
        let estimatedTime = moment().utc(true).add(timestamp, "minute");
        post("/pedidos/" + id + "/timer", { timer: estimatedTime.format(TIMEFORMAT) }).then(() => {
            setActualizar(true);
            dispatch({
                action: "PULL",
                pull: 1
            })
        })
    };

    const modificarPedido = (body) => {
        post("/pedidos/cambio", body).then(() => {
            dispatch({
                action: "PULL",
                pull: 1
            })
        });
    };

    const estaEnProgramado = (pedido) => {
        return programados.find(prog => prog.id === pedido.id);
    }

    const aConfirmado = pedido => {
        setProcesandoEstado(true);
        dispatchDirty(1);
        let pedidoAux = { ...pedido, estado: "Búsqueda" };
        let programado = estaEnProgramado(pedido);
        let endpoint = "/pedidos/" + pedido.id + "/estado";
        post(endpoint, { estado: "Búsqueda" }).then(response => {
            setConfirmados([...confirmados, pedidoAux]);
            if (programado) {
                let temp = programados.filter(nuevo => {
                    return nuevo.id !== pedido.id;
                });
                setProgramados([...temp]);
            }
            else {
                let temp = nuevos.filter(nuevo => {
                    return nuevo.id !== pedidoAux.id;
                });
                setNuevos([...temp]);
            }
            dispatchDirty(0);
            setProcesandoEstado(false);
        });
    };

    const aEntrega = pedido => {
        setProcesandoEstado(true);
        dispatchDirty(1);
        let endpoint = "/pedidos/" + pedido.id + "/estado";
        if(pedido.tipo === "TakeAway") {
            post(endpoint, { estado: "Finalizado" }).then(response => {
                setCompletados([...completados, pedido]);
                let tempAfuera = afuera.filter(nuevo => {
                    return nuevo.id !== pedido.id;
                });
                setAfuera([...tempAfuera]);
                dispatchDirty(0);
                setProcesandoEstado(false);
            });
        } else if (pedido.cadete) {
            post(endpoint, { estado: "En Camino" }).then(response => {
                setEnEntrega([...enEntrega, pedido]);
                let tempConfirmados = confirmados.filter(nuevo => {
                    return nuevo.id !== pedido.id;
                });
                setConfirmados([...tempConfirmados]);
                dispatchDirty(0);
                setProcesandoEstado(false);

            });

        }
    };
    const aAfuera = pedido => {
        setProcesandoEstado(true);

        dispatchDirty(1);
        let endpoint = "/pedidos/" + pedido.id + "/estado";
        post(endpoint, { estado: "Afuera" }).then(response => {
            setAfuera([...afuera, pedido]);
            let tempEntrega = enEntrega.filter(nuevo => {
                return nuevo.id !== pedido.id;
            });
            setEnEntrega([...tempEntrega]);
            dispatchDirty(0);
            setProcesandoEstado(false);

        });
    };
    const aCompletados = pedido => {
        setProcesandoEstado(true);

        dispatchDirty(1);
        let endpoint = "/pedidos/" + pedido.id + "/estado";
        post(endpoint, { estado: "Finalizado" }).then(response => {
            setCompletados([...completados, pedido]);
            let tempAfuera = afuera.filter(nuevo => {
                return nuevo.id !== pedido.id;
            });
            setAfuera([...tempAfuera]);
            dispatchDirty(0);
            setProcesandoEstado(false);
        });
    };

    const findCadete = (id, ciudad_id) => {
        let cadete = cadetes.find(cadete => cadete.id === id);
        return cadete
    };
    const setCadete = (pedido, cadete_id) => {
        setProcesandoEstado(true);
        console.log('procesandoEstado ' + procesandoEstado)
        let cadeteNuevo = findCadete(cadete_id, pedido.ciudad_id);
        let endpoint = "/pedidos/" + pedido.id + "/cadete";
        post(endpoint, { cadete_id: cadete_id }).then(response => {
            console.log('procesandoEstado ' + procesandoEstado)
            let temp = nuevos.filter(nuevo => {
                if (pedido.id === nuevo.id) {
                    nuevo.cadete = cadeteNuevo;
                    return nuevo;
                } else {
                    return nuevo;
                }
            });
            setNuevos([...temp]);
            let temp2 = confirmados.filter(nuevo => {
                if (pedido.id === nuevo.id) {
                    nuevo.cadete = cadeteNuevo;
                    return nuevo;
                } else {
                    return nuevo;
                }
            });
            setConfirmados([...temp2]);
            let temp3 = programados.filter(nuevo => {
                if (pedido.id === nuevo.id) {
                    nuevo.cadete = cadeteNuevo;
                    return nuevo;
                } else {
                    return nuevo;
                }
            });
            setProgramados([...temp3]);
            setProcesandoEstado(false);
        });
    };


    /*  const calcularTimer = () => {
          if (nuevos.length > 0) {
  
              let nuevosAux = nuevos.map(pedido => {
                  if (pedido.timer) {
                      let timer = moment(pedido.timer, TIMEFORMAT);
                      let now = moment().utc(true).format(TIMEFORMAT);
                      let diff = moment.duration(timer.diff(now)).minutes()
                      return { ...pedido, timer: diff };
                  }
                  else return pedido;
              })
              setNuevos(nuevosAux);
          }
  
          if (confirmados.length > 0) {
              confirmados.map(pedido => {
                  if (pedido.timer) {
                      let timer = moment(pedido.timer, TIMEFORMAT);
                      let now = moment().utc(true).format(TIMEFORMAT);
                      let diff = moment.duration(timer.diff(now)).minutes()
                      confirmadosAux.push({ ...pedido, timer: diff });
                  }
                  else confirmadosAux.push(pedido);
              })
  
              setConfirmados(confirmadosAux);
          }
      }
      */

    const stopIfNeeded = () => {
        if (playingAudio) {
            toggleAudio();
        }
    }
    return (
        <>
            <NotificacionPlayer playing={playingAudio} toggle={toggleAudio} />
            <Disponibilidad pedirPedidos={pedirPedidos} />
            <NumerosDeHoy completados={numCompletados} cancelados={numCancelados} cadetes={cadetes} />
            <FormControl component="fieldset" style={{ marginLeft: '177px' }}>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        control={<Switch color="secondary" checked={flag} onChange={() => setFlag(!flag)} />}
                        label="Refresh automatizado"
                        labelPlacement="start"

                    /></FormGroup></FormControl>
            <div className="pedidosContainer">
                <Grid
                    container
                    spacing={1}
                    className={"gridPedidos"}
                    justify={"center"}
                    onClick={() => stopIfNeeded()}
                >
                    <Grid item xs={4} className={"pedidosLabel"}>
                        <p>Nuevos: {nuevos.length}</p>
                        <Grid container xs={12}>
                            {nuevos.map((pedido, key) => {
                                return (
                                    <Grid item xs={6}>
                                        <ItemPedidoConfirmado
                                            pedido={pedido}
                                            key={key}
                                            className={'itemPedido'}
                                            setCadete={setCadete}
                                            cadetes={cadetes}
                                            cadete={pedido.cadete}
                                            avanzar={aConfirmado}
                                            setTimestamp={setTimestamp}
                                            modificarPedido={modificarPedido}
                                            cancelarPedido={cancelarPedido}
                                            procesandoEstado={procesandoEstado}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={4} className={"pedidosLabel"}>
                        <p>Confirmados: {confirmados.length}</p>
                        <Grid container xs={12}>
                            {confirmados.map((pedido, key) => {
                                return (
                                    <Grid item xs={6}>
                                        <ItemPedidoConfirmado
                                            className={'itemConfirmado'}
                                            avanzar={aEntrega}
                                            pedido={pedido}
                                            key={key}
                                            setCadete={setCadete}
                                            cadetes={cadetes}
                                            cadete={pedido.cadete}
                                            setTimestamp={setTimestamp}
                                            modificarPedido={modificarPedido}
                                            cancelarPedido={cancelarPedido}
                                            procesandoEstado={procesandoEstado}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={2} className={"pedidosLabel"}>
                        <p>En entrega: {enEntrega.length}</p>
                        {enEntrega.map((pedido, key) => {
                            return (
                                <ItemEnEntrega
                                    pedido={pedido}
                                    key={key}
                                    avanzar={aAfuera}
                                />
                            );
                        })}
                    </Grid>
                    <Grid item xs={2} className={"pedidosLabel"}>
                        <p>Afuera: {afuera.length}</p>
                        {afuera.map((pedido, key) => {
                            return (
                                <ItemAfuera
                                    pedido={pedido}
                                    key={key}
                                    avanzar={aCompletados}
                                />
                            );
                        })}
                    </Grid> */}
                    <Grid item xs={2} className={"pedidosLabel"}>
                        <ItemProgramado numProgramados={numProgramados} programados={programados} setCadete={setCadete} cadetes={cadetes} aConfirmado={aConfirmado} aCompletados={aCompletados} modificarPedido={modificarPedido} cancelarPedido={cancelarPedido} aEntrega={aEntrega} aAfuera={aAfuera} setTimestamp={setTimestamp}/>
                    </Grid>
                </Grid>
            </div>
            <Grid container className={'cardProgramados'} justify='flex-start'>
                <Grid
                    xs={12}
                >
                    <Grid container xs={5} className={"pedidosLabel"} style={{ marginLeft: '44px' }}>
                        <p>Completados: {numCompletados}  Cancelados: {numCancelados}</p>
                        <Grid container xs={12}>
                            {enEntrega.map((pedido, key) => {
                                return <Grid item xs={5} style={{ marginLeft: '15px' }}>
                                    <ItemEnEntrega pedido={pedido} key={key} />
                                </Grid>;
                            })}
                            {afuera.map((pedido, key) => {
                                return <Grid item xs={5} style={{ marginLeft: '15px' }}>
                                    <ItemAfuera pedido={pedido} key={key} />
                                </Grid>;
                            })}
                            {completados.map((pedido, key) => {
                                return <Grid item xs={5} style={{ marginLeft: '15px' }}>
                                    <ItemCompletado pedido={pedido} key={key} />
                                </Grid>;
                            })}
                            {cancelados.map((pedido, key) => {
                                return <Grid item xs={5} style={{ marginLeft: '15px' }}>
                                    <ItemCompletado pedido={pedido} key={key} />
                                </Grid>;
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PedidosNegocio;
