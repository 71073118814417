import React from "react";
import "./style.css";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { renderDetalle } from "./utils";
import ForwardIcon from "@material-ui/icons/Forward";
import ItemWrapper from "./card/ItemWrapper";
const useStyles = makeStyles(theme => ({
    paper: {
        marginLeft: "34%",
        marginTop: "10%",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4)
    }
}));
const ItemEnEntrega = props => {
    const classes = useStyles();

    const [showModal, setShowModal] = React.useState(false);
    const horaPedido = new Date(props.pedido.created_at);

    return (
        <ItemWrapper className="itemEnEntrega" pedido={props.pedido}>
            {renderDetalle(props.pedido, setShowModal, showModal)}
            <Button size="small" onClick={() => props.avanzar(props.pedido, props.index)}>
                <ForwardIcon />
            </Button>
        </ItemWrapper>
    );
};

export default ItemEnEntrega;
