import React from "react";
import { get, post, update, del } from "../../api/axios";
import {
    Container,
    Button,
    FormControlLabel,
    Checkbox, TextField
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    ValidatorForm,
    TextValidator
} from "react-material-ui-form-validator";
import { Row, Col } from "react-bootstrap";
import Loader from "../../Loader";
import { openAlert } from "simple-react-alert";
import { withRouter } from "react-router-dom";
import './cupones-style.css';

const moment = require('moment');
const AgregarCupon = props => {
    const [es_porcentaje, setPorcentaje] = React.useState(false);
    const [valor, setValor] = React.useState(0);
    const [nombre, setNombre] = React.useState("");
    const [negociosUser, setNegociosUser] = React.useState([]);
    const [negocios, setNegocios] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [limite, setLimite] = React.useState(0);
    const [fechaVencimiento, setFechaVencimiento] = React.useState(null);


    React.useEffect(() => {
        get("/negocios").then(response => {
            setNegocios(response.data);
        });

        if (props.editar) {
            get("/cupones/" + props.match.params.id).then(response => {
                setPorcentaje(response.data.es_porcentaje ? true : false);
                setValor(response.data.descuento);
                setNombre(response.data.nombre);
                setNegociosUser(response.data.negocios);
            });
        }
    }, []);

    const eliminarCupon = () => {
        setLoader(true);

        if (props.editar) {
            del('/cupones/' + props.match.params.id).then(res => {
                setLoader(false);
                openAlert({
                    message: "Cupon eliminado cone exito",
                    type: "success"
                });
                props.history.goBack();
            }).catch(() => {
                setLoader(false);
                openAlert({
                    message: "Error al eliminar cupon",
                    type: "danger"
                });
            })
        }
    }

    const handleSubmit = () => {
        setLoader(true);

        const mappedNegocios = negociosUser.map(negocio => negocio.id);
        const body = {
            nombre,
            negocios: mappedNegocios,
            es_porcentaje,
            descuento: valor,
            limite: limite,
            fecha_vencimiento: fechaVencimiento ? fechaVencimiento.format('YYYY-MM-DD').concat('T23:59:59') : null,

        };

        console.log(props.editar)
        if (props.editar) {
            update("/cupones/" + props.match.params.id, body).then(res => {
                setLoader(false);
                openAlert({
                    message: "Cupon actualizado con exito",
                    type: "success"
                });
            }).catch(error => {
                setLoader(false);
                openAlert({
                    message: "Error al actualizar cupon",
                    type: "danger"
                });
            });
        } else {
            post("/cupones", body)
                .then(response => {
                    setLoader(false);
                    openAlert({
                        message: "Cupon generado con exito",
                        type: "success"
                    });
                })
                .catch(() => {
                    setLoader(false);
                    openAlert({
                        message: "Error al generar cupon",
                        type: "danger"
                    });
                });
        }

    };
    return (
        <Container className="container">
            <ValidatorForm onSubmit={handleSubmit}>
                <Row>
                    <Col xs={{ span: 8, offset: 2 }}>
                        <Row>
                            <Col xs={6} className="mb-2">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={es_porcentaje}
                                            onChange={e =>
                                                setPorcentaje(!es_porcentaje)
                                            }
                                            value="Es porcentaje"
                                        />
                                    }
                                    label="Es porcentaje"
                                />
                            </Col>
                            <Col xs={6} style={{ marginBottom: '20px' }}>
                                <TextField
                                    id="fecha"
                                    label="Vencimiento"
                                    type="date"
                                    value={fechaVencimiento ? fechaVencimiento.format("YYYY-MM-DD") : undefined}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    className="datePicker"
                                    onChange={e => setFechaVencimiento(moment(e.target.value))}
                                />
                            </Col>
                            <Col xs={4} className="mb-2">
                                <TextValidator
                                    label="Valor"
                                    value={valor}
                                    onChange={e => setValor(e.target.value)}
                                    validators={["required", "isNumber"]}
                                    errorMessages={[
                                        "Es requerido",
                                        "Debe ser un numero"
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={4} className="mb-2">
                                <TextValidator
                                    label="Limite total"
                                    value={limite}
                                    onChange={e => setLimite(e.target.value)}
                                    validators={["required", "isNumber"]}
                                    errorMessages={[
                                        "Es requerido",
                                        "Debe ser un numero"
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={4} className="mb-2">
                                <TextValidator
                                    label="Nombre"
                                    placeholder="Descuento 20%"
                                    value={nombre}
                                    onChange={e => setNombre(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={["Es requerido"]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={12} className="mb-2" >
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    variant="filled"
                                    onChange={(e, v) => setNegociosUser(v)}
                                    options={negocios}
                                    style={{ marginBottom: '40px' }}
                                    getOptionLabel={(option) => option.nombre}
                                    value={negociosUser}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Negocios"
                                            placeholder='Si este campo queda vacio se puede aplicar a cualquier negocio'

                                        />
                                    )}
                                />
                            </Col>
                            <Col xs={12}>
                                <div className="form-card-header">
                                    <div className="buttons-header">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            className=""
                                            onClick={() =>
                                                props.history.goBack()
                                            }
                                        >
                                            Volver
                                        </Button>
                                        {loader ? (
                                            <Loader />
                                        ) : (
                                                <>
                                                    <Button
                                                        size="large"
                                                        variant="contained"
                                                        type="submit"
                                                        className="ml-2"
                                                    >
                                                        Guardar
                                            </Button>
                                                    {props.editar && (
                                                        <Button
                                                            size="large"
                                                            variant="contained"
                                                            className="ml-2"
                                                            onClick={() => eliminarCupon()}
                                                        >
                                                            Eliminar
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                    </div>
                                </div>{" "}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ValidatorForm>
        </Container>
    );
};

export default withRouter(AgregarCupon);
