import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import "./style.css";
import { Box, Typography, Table, TableBody, TableCell, TableRow, TableHead, Paper, Collapse, IconButton } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto"
  },
  table: {
    width: "100%"
  },
  paper: {
    marginLeft: "34%",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4)
  }
}));

const TablaPuntajes = props => {
  const { puntajes } = props;
  const [showDetails, setShowDetails] = useState(null);

  const classes = useStyles();
  return (
    <div className="tablaPuntajes">
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Cadete</TableCell>
              <TableCell>Puntaje</TableCell>
              <TableCell>Pedidos</TableCell>
              <TableCell>Detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {puntajes
              .map((puntaje, index) => (
                <React.Fragment>
                  <TableRow key={index}>
                    <TableCell><b>{index + 1}</b></TableCell>
                    <TableCell component='th' scope='row'>{puntaje.cadete.nombre + ' ' + puntaje.cadete.apellido}</TableCell>
                    <TableCell component="th" scope="row">
                      {puntaje.puntaje}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {puntaje.cant_pedidos}
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="expand row" size="small" onClick={() => {
                        if (showDetails) {
                          if(showDetails.cadete.id === puntaje.cadete.id) {
                            setShowDetails(null);
                          } else {
                            setShowDetails(puntaje);
                          }
                        } else {
                          setShowDetails(puntaje);
                        }
                      }}>
                        {showDetails && showDetails.cadete.id === puntaje.cadete.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {
                    showDetails && showDetails.cadete.id === puntaje.cadete.id &&
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={showDetails !== null} timeout="auto" unmountOnExit>
                          <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                              Detalle de pedidos
                          </Typography>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>ID Pedido</TableCell>
                                  <TableCell>Puntaje</TableCell>
                                  <TableCell>Fecha</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {showDetails.pedidos.map((pedido) => (
                                  <TableRow key={pedido.id}>
                                    <TableCell component="th" scope="row">{pedido.id}</TableCell>
                                    <TableCell>{pedido.puntaje}</TableCell>
                                    <TableCell>{pedido.fecha}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  }
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};



export default TablaPuntajes;
