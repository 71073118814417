import React from "react";
import { Button, Typography } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { get } from "../../api/axios";
import { List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import "./style.css";

const OpcionesPromocion = props => {
    const [promocionesVigentes, setPromocionesVigentes] = React.useState([]);
    const [promocionesVencidas, setPromocionesVencidas] = React.useState([]);
    React.useEffect(() => {
        get("/promociones").then(response => {
            setPromocionesVigentes(response.data.activas ? response.data.activas : []);
            setPromocionesVencidas(response.data.inactivas ? response.data.inactivas : []);
        });
    }, []);

    return (
        <>
            <Row
                xs={{ span: 12, offset: 2 }}
                className="justify-content-md-center rowContainer"
            >
                <Col xs={8}>
                    <Button
                        size="large"
                        variant="contained"
                        className="ml-2"
                        onClick={() =>
                            props.history.push(props.match.url + "/nuevo")
                        }
                    >
                        Agregar Promocion
                    </Button>
                </Col>
                <Col xs={8}>
                    <Typography style={{ fontSize: '23px', fontWeight: 'bold' }}>Promociones vigentes</Typography>
                    <List
                        component="nav"
                        aria-label="main mailbox folders"
                        style={{ maxHeight: "400px", overflow: "auto" }}
                    >
                        {promocionesVigentes.map((promo, key) => {
                            return (
                                <ListItem
                                    button
                                    onClick={() => {
                                        props.history.push({
                                            pathname:
                                                props.match.url +
                                                "/editar/" +
                                                promo.id
                                        });
                                    }}
                                    key={key}
                                >
                                    <ListItemText primary={promo.producto.negocio.nombre + ' - ' + promo.producto.titulo} />
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                </ListItem>
                            );
                        })}
                    </List>
                    <Typography style={{ fontSize: '23px', fontWeight: 'bold' }}>Promociones vencidas</Typography>
                    <List
                        component="nav"
                        aria-label="main mailbox folders"
                        style={{ maxHeight: "400px", overflow: "auto" }}
                    >
                        {promocionesVencidas.map((promo, key) => {
                            return (
                                <ListItem
                                    button
                                    onClick={() => {
                                        props.history.push({
                                            pathname:
                                                props.match.url +
                                                "/editar/" +
                                                promo.id
                                        });
                                    }}
                                    key={key}
                                >
                                    <ListItemText primary={promo.producto.negocio.nombre + ' - ' + promo.producto.titulo} />
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                </ListItem>
                            );
                        })}
                    </List>
                </Col>
            </Row>
        </>
    );
};

export default withRouter(OpcionesPromocion);
