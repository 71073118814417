import React from "react";
import { get, post, update, del } from "../../api/axios";
import {
    Container,
    Button
} from "@material-ui/core";
import {
    ValidatorForm,
    TextValidator,
    SelectValidator
} from "react-material-ui-form-validator";
import { Row, Col } from "react-bootstrap";
import Loader from "../../Loader";
import { openAlert } from "simple-react-alert";
import { withRouter } from "react-router-dom";
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";

const AgregarCiudad = props => {
    const [nombre, setNombre] = React.useState("");
    const [latitud, setLatitud] = React.useState("");
    const [longitud, setLongitud] = React.useState("");
    const [precioEnvioSuma, setPrecioEnvioSuma] = React.useState(10);
    const [precioEnvioBase, setPrecioEnvioBase] = React.useState(40);
    const [kmsEnvioBase, setKmsEnvioBase] = React.useState(2.5);
    const [comision, setComision] = React.useState(0);
    const [esPorcentaje, setEsPorcentaje] = React.useState(0);
    const [telefonoOperaciones, setTelefonoOperaciones] = React.useState(0);
    const [telefonoAtencion, setTelefonoAtencion] = React.useState(0);
    const [kmsEnvioSuma, setKmsEnvioSuma] = React.useState(0.5);
    const [envioFaster, setEnvioFaster] = React.useState(30);
    const [zonas, setZonas] = React.useState('');
    const [loader, setLoader] = React.useState(false);

    React.useEffect(() => {
        if (props.editar) {
            get("/ciudades/" + props.match.params.id).then(response => {
                setLatitud(response.data.latitud);
                setLongitud(response.data.longitud);
                setNombre(response.data.nombre);
                setPrecioEnvioBase(response.data.precio_envio_base);
                setPrecioEnvioSuma(response.data.precio_envio_suma);
                setKmsEnvioBase(response.data.kms_envio_base);
                setKmsEnvioSuma(response.data.kms_envio_suma);
                setEnvioFaster(response.data.envio_faster);
                setEsPorcentaje(response.data.es_porcentaje_personalizado);
                setComision(response.data.comision_personalizado);
                setTelefonoAtencion(response.data.telefono_atencion);
                setTelefonoOperaciones(response.data.telefono_operaciones);
                setZonas(response.data.zonas);
            });
        }
    }, []);

    const eliminarCiudad = () => {
        setLoader(true);

        if (props.editar) {
            del('/ciudades/' + props.match.params.id).then(res => {
                setLoader(false);
                openAlert({
                    message: "Ciudad eliminada con exito",
                    type: "success"
                });
                props.history.goBack();
            }).catch(() => {
                setLoader(false);
                openAlert({
                    message: "Error al eliminar ciudad",
                    type: "danger"
                });
            })
        }
    }

    const handleSubmit = () => {
        setLoader(true);

        const body = {
            nombre,
            latitud,
            longitud,
            kms_envio_base: kmsEnvioBase,
            kms_envio_suma: kmsEnvioSuma,
            precio_envio_base: precioEnvioBase,
            precio_envio_suma: precioEnvioSuma,
            envio_faster: envioFaster,
            es_porcentaje_personalizado: esPorcentaje,
            comision_personalizado: comision,
            telefono_atencion: telefonoAtencion,
            telefono_operaciones: telefonoOperaciones,
            zonas: zonas
        };

        if (props.editar) {
            update("/ciudades/" + props.match.params.id, body).then(res => {
                setLoader(false);
                openAlert({
                    message: "Ciudad actualizado con exito",
                    type: "success"
                });
            }).catch(error => {
                setLoader(false);
                openAlert({
                    message: "Error al actualizar ciudad",
                    type: "danger"
                });
            });
        } else {
            post("/ciudades", body)
                .then(response => {
                    setLoader(false);
                    openAlert({
                        message: "Ciudad generada con exito",
                        type: "success"
                    });
                })
                .catch(() => {
                    setLoader(false);
                    openAlert({
                        message: "Error al generar ciudad",
                        type: "danger"
                    });
                });
        }

    };
    return (
        <Container className="container">
            <ValidatorForm onSubmit={handleSubmit}>
                <Row>
                    <Col xs={{ span: 8, offset: 2 }}>
                        <Row>
                            <Col xs={12} className="mb-2">
                                <TextValidator
                                    label="Nombre"
                                    placeholder="Bahia Blanca"
                                    value={nombre}
                                    onChange={e => setNombre(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={["Es requerido"]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Latitud"
                                    value={latitud}
                                    onChange={e => setLatitud(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={["Es requerido"]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Longitud"
                                    value={longitud}
                                    onChange={e => setLongitud(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={["Es requerido"]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Precio envio base"
                                    value={precioEnvioBase}
                                    onChange={e => setPrecioEnvioBase(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={[
                                        "Es requerido",
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Precio envio suma"
                                    value={precioEnvioSuma}
                                    onChange={e => setPrecioEnvioSuma(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={[
                                        "Es requerido",
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Kms envio base"
                                    value={kmsEnvioBase}
                                    onChange={e => setKmsEnvioBase(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={[
                                        "Es requerido",
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Kms envio suma"
                                    value={kmsEnvioSuma}
                                    onChange={e => setKmsEnvioSuma(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={[
                                        "Es requerido",
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Comision personalizados"
                                    value={comision}
                                    onChange={e => setComision(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={[
                                        "Es requerido",
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={{ span: 4, offset: 1 }} className="checkbox checkboxCadete">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={esPorcentaje}
                                            onChange={e =>
                                                setEsPorcentaje(!esPorcentaje)
                                            }
                                            value="Es porcentaje"
                                        />
                                    }
                                    label={`Es porcentaje?`}
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Telefono operaciones"
                                    value={telefonoOperaciones}
                                    onChange={e => setTelefonoOperaciones(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={[
                                        "Es requerido",
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Telefono Atencion al cliente"
                                    value={telefonoAtencion}
                                    onChange={e => setTelefonoAtencion(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={[
                                        "Es requerido",
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Monto envio faster"
                                    value={envioFaster}
                                    onChange={e => setEnvioFaster(e.target.value)}
                                    validators={["required", "isNumber"]}
                                    errorMessages={[
                                        "Es requerido", 'Debe ser un numero'
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={12} className="mb-2">
                                <TextValidator
                                    label="Link de zonas de alcance"
                                    value={zonas}
                                    onChange={e => setZonas(e.target.value)}
                                    validators={["required"]}
                                    errorMessages={[
                                        "Es requerido",
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={12}>
                                <div className="form-card-header">
                                    <div className="buttons-header">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            className=""
                                            onClick={() =>
                                                props.history.goBack()
                                            }
                                        >
                                            Volver
                                        </Button>
                                        {loader ? (
                                            <Loader />
                                        ) : (
                                                <>
                                                    <Button
                                                        size="large"
                                                        variant="contained"
                                                        type="submit"
                                                        className="ml-2"
                                                    >
                                                        Guardar
                                            </Button>
                                                    {props.editar && (
                                                        <Button
                                                            size="large"
                                                            variant="contained"
                                                            className="ml-2"
                                                            onClick={() => eliminarCiudad()}
                                                        >
                                                            Eliminar
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                    </div>
                                </div>{" "}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ValidatorForm>
        </Container>
    );
};

export default withRouter(AgregarCiudad);
