import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import BusinessIcon from "@material-ui/icons/Store";
import BikeIcon from "@material-ui/icons/Motorcycle";
import UserIcon from "@material-ui/icons/People";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HistorialIcon from "@material-ui/icons/History";
import BannerIcon from '@material-ui/icons/Tv'
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import CouponIcon from "@material-ui/icons/CardGiftcard";
import CiudadesIcon from '@material-ui/icons/LocationCity'
import EstadisticasIcon from "@material-ui/icons/Assessment";
import OffIcon from '@material-ui/icons/LocalOffer'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NotificacionesIcon from "@material-ui/icons/Notifications";
import Collapse from '@material-ui/core/Collapse';
import { withRouter } from "react-router-dom";
import ChartIcon from '@material-ui/icons/BarChart'
import './sidebar-style.css';
import OperadorList from './OperadorList';
import NegocioList from './NegocioList';
import { isAdmin } from "../../context/methods/isAdmin";
import { isNegocio } from "../../context/methods/isNegocio";
import { StoreContext } from '../../context/Store';
import { get } from '../../api/axios';
import { isCadeteria } from "../../context/methods/isCadeteria";
import CadeteriaList from './CadeteriaList';
const useStyles = makeStyles({
    list: {
        width: 250
    },
    fullList: {
        width: "auto"
    }
});

const TemporaryDrawer = props => {
    const { state, dispatch } = React.useContext(StoreContext);
    const toggleDrawer = () => {
        props.setShow(!props.show);
    };
    const [openAdministrar, setOpenAdministrar] = React.useState(false);
    const [openUsuarios, setOpenUsuarios] = React.useState(false);
    const [openReportes, setOpenReportes] = React.useState(false);
    const [pendientes, setPendientes] = React.useState(0);
    const [user, setUser] = React.useState(null);

    React.useEffect(() => {
        state && state.userInfo && setUser(state.userInfo);
    }, [state])

    React.useEffect(() => {
        user && get("/productos/pendientes").then(
            response => {
                let pendientes = 0;
                response.data.map(wrapper => {
                    pendientes += wrapper.productos.length;
                })
                setPendientes(pendientes);
            }
        ).catch();
        setInterval(() => {
            get("/productos/pendientes").then(
                response => {
                    let pendientes = 0;
                    response.data.map(wrapper => {
                        pendientes += wrapper.productos.length;
                    })
                    setPendientes(pendientes);
                }
            ).catch();
        }, 1800000);
    }, [user])

    const sideList = () => (
        <div
            className={useStyles.list}
            role="presentation"
        >
            <List>
                <ListItem button onClick={() => { toggleDrawer(); props.history.push("/pedidos") }}>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Pedidos"} />
                </ListItem>
                <ListItem button onClick={() => { toggleDrawer(); props.history.push("/panel") }}>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Panel Cadetes"} />
                </ListItem>
                <ListItem
                    button
                    onClick={() => setOpenAdministrar(!openAdministrar)}
                >
                    <ListItemIcon>
                        <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Administraciones: " + pendientes} />
                    {openAdministrar ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openAdministrar} timeout="auto" className='colapsable' unmountOnExit>
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/billetera") }}
                    >
                        <ListItemIcon>
                            <AccountBalanceWallet />
                        </ListItemIcon>
                        <ListItemText primary={"Billeteras"} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/pendientes") }}
                    >
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Productos pendientes"} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/negocios") }}
                    >
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Negocios"} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/cadeterias") }}
                    >
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Cadeterias"} />
                    </ListItem>
                    <ListItem button onClick={() => { toggleDrawer(); props.history.push("/cadetes") }}>
                        <ListItemIcon>
                            <BikeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Cadetes"} />
                    </ListItem>
                    <ListItem button onClick={() => { toggleDrawer(); props.history.push("/cupones") }}>
                        <ListItemIcon>
                            <CouponIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Cupones"} />
                    </ListItem>
                    <ListItem button onClick={() => { toggleDrawer(); props.history.push("/ciudades") }}>
                        <ListItemIcon>
                            <CiudadesIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Ciudades"} />
                    </ListItem>
                    <ListItem button onClick={() => { toggleDrawer(); props.history.push("/publicidad") }}>
                        <ListItemIcon>
                            <BannerIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Publicidades"} />
                    </ListItem>
                    <ListItem button onClick={() => { toggleDrawer(); props.history.push("/promociones") }}>
                        <ListItemIcon>
                            <OffIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Promociones"} />
                    </ListItem>
                </Collapse>
                <ListItem
                    button
                    onClick={() => setOpenUsuarios(!openUsuarios)}
                >
                    <ListItemIcon>
                        <UserIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Usuarios"} />
                    {openUsuarios ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openUsuarios} timeout="auto" className='colapsable' unmountOnExit>
                    <ListItem button onClick={() => { toggleDrawer(); props.history.push("/negocios/usuarios") }}>
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Usuarios de negocio"} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/usuarios") }}
                    >
                        <ListItemIcon>
                            <UserIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Usuarios clientes"} />
                    </ListItem>
                </Collapse>
                <ListItem
                    button
                    onClick={() => setOpenReportes(!openReportes)}
                >
                    <ListItemIcon>
                        <ChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Reportes y Estadisticas"} />
                    {openReportes ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openReportes} timeout="auto" className='colapsable' unmountOnExit>
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/historial") }}
                    >
                        <ListItemIcon>
                            <HistorialIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Historial de pedidos"} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/cadetes/reporte") }}
                    >
                        <ListItemIcon>
                            <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Reporte cadetes"} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/negocios/reporte") }}
                    >
                        <ListItemIcon>
                            <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Reporte negocios"} />
                    </ListItem>
                    {/*<ListItem button onClick={() => props.history.push("/tipos")}>
                    <ListItemIcon>
                        <CreateIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Administrar tipos"} />
                </ListItem>*/}
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/estadisticas/pedidos") }}
                    >
                        <ListItemIcon>
                            <EstadisticasIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Estadisticas de pedidos"} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/estadisticas/usuarios") }}
                    >
                        <ListItemIcon>
                            <EstadisticasIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Estadisticas de usuarios"} />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => { toggleDrawer(); props.history.push("/cadetes/puntajes") }}
                    >
                        <ListItemIcon>
                            <FormatListNumberedIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Puntajes de cadetes"} />
                    </ListItem>
                </Collapse>
                <ListItem
                    button
                    onClick={() => { toggleDrawer(); props.history.push("/notificaciones") }}
                >
                    <ListItemIcon>
                        <NotificacionesIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Notificaciones"} />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div>
            <Drawer open={props.show} onClose={() => toggleDrawer()}>
                {(isAdmin(user)) ? sideList() : (isNegocio(user) ?
                    <NegocioList
                        openAdministrar={openAdministrar}
                        openUsuarios={openUsuarios}
                        setOpenAdministrar={setOpenAdministrar}
                        setOpenUsuarios={setOpenUsuarios}
                        toggleDrawer={toggleDrawer}
                    />
                    :
                    isCadeteria(user) ?
                        <CadeteriaList
                            openAdministrar={openAdministrar}
                            openUsuarios={openUsuarios}
                            setOpenAdministrar={setOpenAdministrar}
                            setOpenUsuarios={setOpenUsuarios}
                            toggleDrawer={toggleDrawer}
                        />
                        :
                        <OperadorList
                            openAdministrar={openAdministrar}
                            openUsuarios={openUsuarios}
                            setOpenAdministrar={setOpenAdministrar}
                            setOpenUsuarios={setOpenUsuarios}
                            toggleDrawer={toggleDrawer}
                        />
                )
                }
            </Drawer>
        </div>
    );
};

export default withRouter(TemporaryDrawer);
