import React from "react";
import { Button, Typography } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { get } from "../../../api/axios";
import { List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import "./style.css";
const CadeteriaOpciones = props => {
    const [cadetes, setCadetes] = React.useState([]);
    React.useEffect(() => {
        get("/admin-cadeterias/cadetes").then(response => {
            setCadetes(response.data)
        });
    }, []);

    return (
        <>
            <Row
                xs={{ span: 12, offset: 2 }}
                className="justify-content-md-center rowContainer"
            >
                <Col xs={8}>
                    <Button
                        size="large"
                        variant="contained"
                        className="ml-2"
                        onClick={() =>
                            props.history.push(props.match.url + "/admincadeteria/nuevo")
                        }
                    >
                        Agregar Cadete
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        className="ml-2"
                        onClick={() =>
                            props.history.push(props.match.url + "/admincadeteria/disponibles")
                        }
                    >
                        Cadetes disponibles
                    </Button>
                </Col>
                {cadetes && cadetes.map && cadetes.map((cadete, key) => {
                    return <Col xs={8}>
                        <List
                            component="nav"
                            aria-label="main mailbox folders"
                            style={{ maxHeight: "400px", overflow: "auto" }}
                        >
                            <ListItem
                                button
                                onClick={() => {
                                    props.history.push({
                                        pathname:
                                            props.match.url +
                                            "/admincadeteria/editar/" +
                                            cadete.id
                                    });
                                }}
                                key={key}
                            >
                                <ListItemText primary={cadete.nombre + ' ' + cadete.apellido} />
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                            </ListItem>
                        </List>
                    </Col>
                })}
            </Row>
        </>
    );
};

export default withRouter(CadeteriaOpciones);
