import React from 'react'
import { withRouter } from "react-router";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import ImageUploader from "../../components/imageUploader/ImageUploader";
import { FormControlLabel, Checkbox, FormControl, MenuItem, Button } from "@material-ui/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loader from "../../Loader";
import { get, post, del, update, postImage } from "../../api/axios";
import { openAlert } from "simple-react-alert";

const EditarPublicidad = (props) => {

    const publicidadId = props.match.params.id;

    const [titulo, setTitulo] = React.useState('');
    const [descripcion, setDescripcion] = React.useState('');
    const [disponible, setDisponible] = React.useState(false);
    const [imagen, setImagen] = React.useState(null);
    const [color, setColor] = React.useState('black');
    const [negocios, setNegocios] = React.useState([]);
    const [negocioId, setNegocioId] = React.useState(0);
    const [link, setLink] = React.useState('');
    const [isExterno, setIsExterno] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [ciudadId, setCiudadId] = React.useState(0);
    const [ciudades, setCiudades] = React.useState([]);
    const [tipos, setTipos] = React.useState([
        { id: 1, nombre: 'negocio' },
        { id: 2, nombre: 'Con link externo' },
        { id: 3, nombre: 'con cupon' }
    ])
    const [cupones, setCupones] = React.useState([]);
    const [cupon, setCupon] = React.useState(0);
    const [tipo, setTipo] = React.useState(1);
    React.useEffect(() => {
        if (publicidadId) {
            get("/publicidades/" + publicidadId).then(response => {
                let publicidad = response.data;
                let tipoInt = 1;
                switch (response.data.tipo) {
                    case 'negocio': { tipoInt = 1; break; }
                    case 'link': { tipoInt = 2; setLink(publicidad.link); break; }
                    case 'cupon': { tipoInt = 3; setCupon(publicidad.cupon_id); break; }

                }
                setTitulo(publicidad.titulo);
                setTipo(tipoInt);
                setDescripcion(publicidad.descripcion);
                setDisponible(publicidad.disponible);
                setColor(publicidad.color);
                setNegocioId(publicidad.negocio_id);
                setCiudadId(publicidad.ciudad_id);
            });
        }

        get("/cupones").then(response => {
            setCupones(response.data);
        });
        get('/ciudades').then(res => {
            setCiudades(res.data);
        })

        get("/negocios").then(response => {
            let negocios = response.data.sort(function (a, b) {
                if (a.nombre < b.nombre) { return -1; }
                if (a.nombre > b.nombre) { return 1; }
                return 0;
            });
            setNegocios(negocios);
        });
    }, []);

    const handleSubmit = () => {
        setLoader(true);
        let tipoString = '';
        switch (tipo) {
            case 1: { tipoString = 'negocio'; break; }
            case 2: { tipoString = 'link'; break; }
            case 3: { tipoString = 'cupon'; break; }

        }
        let body = {
            titulo: titulo,
            descripcion: descripcion,
            disponible: disponible,
            negocio_id: (tipo !== 1) ? null : negocioId,
            ciudad_id: ciudadId,
            link: tipo === 2 ? link : null,
            color: color,
            tipo: tipoString,
            cupon_id: tipo === 3 ? cupon : null
        };

        var formData = new FormData();
        formData.append("imagen", imagen);
        if (publicidadId) {
            update("/publicidades/" + publicidadId, body).then(response => {
                openAlert({ message: "Guardado exitoso", type: "success" });
                if (response.data) {
                    imagen && postImage("/publicidades/" + publicidadId + "/imagen", formData);
                }
                props.history.push('/publicidad');
            });
        } else {
            post("/publicidades", body).then(response => {
                openAlert({ message: "Guardado exitoso", type: "success" });
                setLoader(false);
                postImage("/publicidades/" + response.data.id + "/imagen", formData);
                props.history.push('/publicidad');
            });
        }
    }

    const eliminarPublicidad = () => {
        del("/publicidades/", publicidadId).then(response => {
            openAlert({ message: "Eliminado exitosamente", type: "success" });
            props.history.push('/publicidad');
        });
    }

    const renderTipo = () => {
        switch (tipo) {
            case 2: {
                return <>
                    <Col xs={12} className="mb-2" visible={isExterno}>
                        <TextValidator
                            label="Link externo"
                            value={link}
                            onChange={e => setLink(e.target.value)}
                            variant="filled"
                            className="textInput"
                        />
                    </Col>
                </>
            }
            case 3: {
                return <Col xs={12} className="mb-2">
                    <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                        <SelectValidator
                            label="Seleccionar Cupon"
                            value={cupon}
                            onChange={e => setCupon(e.target.value)}
                            variant="filled"
                        >
                            {cupones.map((cupon, key) => {
                                return <MenuItem value={cupon.id} key={key}>{cupon.nombre}</MenuItem>
                            })}
                        </SelectValidator>
                    </FormControl>
                </Col>
            }
            case 1: {
                return <Col xs={12} className="mb-2" >
                    <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                        <SelectValidator
                            label="Negocio"
                            value={negocioId}
                            onChange={e => setNegocioId(e.target.value)}
                            validators={["required"]}
                            variant="filled"
                            errorMessages={["Este campo es requerido"]}
                        >
                            {negocios.map((negocio, key) => {

                                return <MenuItem key={key} value={negocio.id}>{negocio.nombre}</MenuItem>
                            })}
                        </SelectValidator>
                    </FormControl>
                </Col>
            }
        }
    }
    return <Container className="container-tournament-form">
        <ValidatorForm onSubmit={handleSubmit}>
            <Row>
                <Col xs={{ span: 10, offset: 1 }}>
                    <Row>
                        <Col xs={12} className="mb-2">
                            <TextValidator
                                label="Titulo"
                                value={titulo}
                                onChange={e => setTitulo(e.target.value)}
                                validators={["required", "minStringLength:3"]}
                                variant="filled"
                                className="textInput"
                                errorMessages={["Este campo es requerido", "Deben ser al menos 3 caracteres"]}
                            />
                        </Col>
                        <Col xs={12} className="mb-2">
                            <TextValidator
                                label="Descripcion"
                                onChange={e => setDescripcion(e.target.value)}
                                name="description"
                                variant="filled"
                                multiline
                                rows="4"
                                className="descriptionInput"
                                value={descripcion}
                            />
                        </Col>
                        <Col xs={6} className="mb-2">
                            <TextValidator
                                label="Color"
                                value={color}
                                onChange={e => setColor(e.target.value)}
                                variant="filled"
                                className="textInput"
                            />
                        </Col>
                        <Col xs={6} className="mb-2 checkbox">
                            <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">

                                <SelectValidator
                                    label="Seleccionar tipo de publicidad"
                                    value={tipo}
                                    onChange={e => setTipo(e.target.value)}
                                    variant="filled"
                                >
                                    {tipos.map((tipo, key) => {
                                        return <MenuItem value={tipo.id} key={key}>{tipo.nombre}</MenuItem>
                                    })}
                                </SelectValidator>
                            </FormControl>
                        </Col>
                        {renderTipo()}
                        <Col xs={12} className="mb-2">
                            <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                                <SelectValidator
                                    label="Seleccionar Ciudad"
                                    value={ciudadId}
                                    onChange={e => setCiudadId(e.target.value)}
                                    variant="filled"
                                >
                                    {ciudades.map((ciudad, key) => {
                                        return <MenuItem value={ciudad.id} key={key}>{ciudad.nombre}</MenuItem>
                                    })}
                                </SelectValidator>
                            </FormControl>
                        </Col>
                        <Col xs={12} className="mb-2">
                            <ImageUploader setImagen={setImagen} imagen={imagen} />
                        </Col>
                        <Col xs={6} className="mb-2 checkbox">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={disponible}
                                        onChange={e => setDisponible(!disponible)}
                                        value="Disponible"
                                    />
                                }
                                label="Esta disponible al publico?"
                            />
                        </Col>
                        <Col xs={12}>
                            <div className="form-card-header">
                                <div className="buttons-header">
                                    <Button
                                        size="large"
                                        variant="contained"
                                        className=""
                                        onClick={() => props.history.goBack()}
                                    >
                                        Volver
                                    </Button>
                                    {loader ? (
                                        <Loader />
                                    ) : (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                                className="ml-2"
                                            >
                                                Guardar
                                            </Button>
                                        )}
                                    {
                                        publicidadId &&
                                        <Button
                                            size="large"
                                            variant="contained"
                                            className="ml-2"
                                            onClick={() => {
                                                eliminarPublicidad();
                                            }}
                                        >
                                            Eliminar
                                            </Button>
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ValidatorForm>
    </Container >

}

export default withRouter(EditarPublicidad);