import React from "react";
import "./style.css";
import PedidoConfirmadoChildren from './PedidoConfirmadoChildren';
import ItemWrapper from "./card/ItemWrapper";

const ItemPedidoConfirmado = props => {
    return (
        <ItemWrapper className={props.className} pedido={props.pedido}>
            <PedidoConfirmadoChildren
                cadete={props.cadete}
                pedido={props.pedido}
                avanzar={props.avanzar}
                setCadete={props.setCadete}
                cancelarPedido={props.cancelarPedido}
                setTimestamp={props.setTimestamp}
                modificarPedido={props.modificarPedido}
                cadetes={props.cadetes}
                procesandoEstado={props.procesandoEstado}
            />

        </ItemWrapper>
    );
};

export default ItemPedidoConfirmado;
