import React from 'react'
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import ImageUploader from "../../components/imageUploader/ImageUploader";
import { FormControlLabel, Checkbox, FormControl, MenuItem, Button } from "@material-ui/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loader from "../../Loader";
import { get, post } from "../../api/axios";
import { withRouter } from "react-router-dom";
import { openAlert } from 'simple-react-alert';
import { isAdmin } from "../../context/methods/isAdmin";
import { StoreContext } from '../../context/Store';

const Notificaciones = (props) => {
    const { state, dispatch } = React.useContext(StoreContext);

    const [titulo, setTitulo] = React.useState('');
    const [mensaje, setMensaje] = React.useState('');
    const [ciudades, setCiudades] = React.useState([]);
    const [ciudadId, setCiudadId] = React.useState(0);
    const [receptor, setReceptor] = React.useState('cadetes');
    const [loader, setLoader] = React.useState(false);
    const [user, setUser] = React.useState(null);

    React.useEffect(() => {
        state && state.userInfo && setUser(state.userInfo);
        state && state.userInfo && get("/ciudades").then(response => {
            if (!isAdmin(state.userInfo)) {
                console.log("en la noti", user)
                setCiudades(state.userInfo.ciudades)
            }
            else setCiudades(response.data);
            state.userInfo && !isAdmin(state.userInfo) && setCiudadId(state.userInfo.ciudades[0].id)
        });
    }, [state])



    const handleSubmit = () => {
        setLoader(true);
        let body = {
            titulo: titulo,
            mensaje: mensaje,
            ciudad_id: ciudadId,
            receptor: receptor
        };

        post("/notificaciones/global", body).then(response => {
            openAlert({ message: "Notificacion emitida exitosamente!", type: "success" });
            setLoader(false);
            props.history.goBack();
        });
    }

    return <Container className="container-tournament-form">
        <ValidatorForm onSubmit={handleSubmit}>
            <Row>
                <Col xs={{ span: 10, offset: 1 }}>
                    <Row>
                        <Col xs={12} className="mb-2">
                            <TextValidator
                                label="Titulo"
                                value={titulo}
                                onChange={e => setTitulo(e.target.value)}
                                validators={["required", "minStringLength:3"]}
                                variant="filled"
                                className="textInput"
                                errorMessages={["Este campo es requerido", "Deben ser al menos 3 caracteres"]}
                            />
                        </Col>
                        <Col xs={12} className="mb-2">
                            <TextValidator
                                label="Mensaje"
                                onChange={e => setMensaje(e.target.value)}
                                name="mensaje"
                                variant="filled"
                                multiline
                                rows="4"
                                className="descriptionInput"
                                value={mensaje}
                            />
                        </Col>
                        <Col xs={6}>
                            <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                                <SelectValidator
                                    label="Seleccionar ciudad"
                                    value={ciudadId}
                                    onChange={e => setCiudadId(e.target.value)}
                                    validators={["required"]}
                                    variant="filled"
                                    errorMessages={["Este campo es requerido"]}
                                >
                                    {ciudades.map((ciudad, key) => {
                                        return <MenuItem value={ciudad.id} key={key}>{ciudad.nombre}</MenuItem>
                                    })}
                                </SelectValidator>
                            </FormControl>
                        </Col>
                        <Col xs={6}>
                            <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                                <SelectValidator
                                    label="Seleccionar receptores"
                                    value={receptor}
                                    disabled={!isAdmin(user)}
                                    onChange={e => setReceptor(e.target.value)}
                                    validators={["required"]}
                                    variant="filled"
                                    errorMessages={["Este campo es requerido"]}
                                >
                                    <MenuItem value={'clientes'} >{'Usuarios'}</MenuItem>
                                    <MenuItem value={'cadetes'} >{'Cadetes'}</MenuItem>
                                </SelectValidator>
                            </FormControl>
                        </Col>
                        <Col xs={12}>
                            <div className="form-card-header">
                                <div className="buttons-header">
                                    <Button
                                        size="large"
                                        variant="contained"
                                        className=""
                                        onClick={() => props.history.goBack()}
                                    >
                                        Volver
                    </Button>
                                    {loader ? (
                                        <Loader />
                                    ) : (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className="ml-2"
                                            >
                                                Emitir
                                            </Button>
                                        )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ValidatorForm>
    </Container >

}

export default withRouter(Notificaciones);