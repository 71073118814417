import React from "react";
import { Modal, FormControl, Button, List, ListItem, Checkbox, ListItemIcon, ListItemText } from "@material-ui/core";
import { openAlert } from "simple-react-alert";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { get, post } from "../../../api/axios";
import Loader from "../../../Loader";

const ModalCopiarProductos = ({
  destino,
  open,
  onClose
}) => {
  const [productosSeleccionados, setProductosSeleccionados] = React.useState([]);
  const [todosProductos, setTodosProductos] = React.useState([]);
  const [productos, setProductos] = React.useState([]);
  const [filtro, setFiltro] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    get("/negocios/" + 89 + "/productos").then(
      response => {
        let todos = [];

        response.data.map((tipo) => {
          tipo.productos.map((producto) => {
            todos = [producto.id, ...todos];
          })
        })

        setProductos(response.data);
        setTodosProductos([...todos]);
        setLoading(false);
      }
    );
  }, []);


  const onSeleccionarProducto = (id) => {
    if (productosSeleccionados.includes(id)) {
      setProductosSeleccionados(productosSeleccionados.filter(p => p !== id));
    } else {
      setProductosSeleccionados([...productosSeleccionados, id]);
    }
  }

  const toggleTodosSeleccionados = () => {
    if (todosProductos.length === productosSeleccionados.length) {
      setProductosSeleccionados([]);
    } else {
      setProductosSeleccionados([...todosProductos]);
    }
  }

  const onCopiarProductos = () => {
    post("/productos/copy", {
      negocio_destino: destino,
      productos: productosSeleccionados
    }).then(() => {
      openAlert({ message: "Asignado con exito", type: "success" });
      onClose(true);
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      className={"modalContainer"}
      style={{minWidth: '80%', maxWidth: '80%'}}
    >
      <div className="modalBodyCopiarProductos">
        <ValidatorForm onSubmit={onCopiarProductos}>
          <FormControl
            fullWidth
            margin="dense"
            variant="filled"
            className="tipoInput"
          >

            <h5>Productos a copiar</h5>
            <List
              component="nav"
              aria-label="main mailbox folders"
              style={{ maxHeight: "400px", overflow: "auto" }}
            >
              <ListItem>
                <TextValidator
                  label="Filtrar por nombre"
                  value={filtro}
                  onChange={e => {
                    setFiltro(e.target.value);
                  }}
                  variant="filled"
                  className="textInput"
                />
              </ListItem>
              {
                filtro === '' &&
                <ListItem>
                  <ListItemText primary={'TODOS'} />
                  <ListItemIcon>
                    <Checkbox
                      checked={productosSeleccionados.length === todosProductos.length}
                      className="pointer"
                      onClick={toggleTodosSeleccionados}
                    />
                  </ListItemIcon>
                </ListItem>
              }
              {
                loading &&
                <Loader />
              }
              {productos.map((tipo, key) => {
                return (
                  <div key={key}>
                    <p className="mb-2"><b>▬  {tipo.tipo}</b></p>
                    {tipo.productos.filter(p => p.titulo.toLowerCase().includes(filtro.toLowerCase())).map((producto, key) => {
                      return (
                        <ListItem key={key}>
                          <ListItemText primary={producto.titulo} />
                          <ListItemIcon>
                            <Checkbox
                              checked={productosSeleccionados.includes(producto.id)}
                              className="pointer"
                              onClick={() => onSeleccionarProducto(producto.id)}
                            />
                          </ListItemIcon>
                        </ListItem>
                      );
                    })}
                  </div>
                );
              })}
            </List>
          </FormControl>
          <Button
            variant="outlined"
            type="submit"
            className="btnVerificar"
          >
            Copiar
                    </Button>
        </ValidatorForm>
      </div>
    </Modal>
  );
};

export default ModalCopiarProductos;
