import React from "react";
import { Modal, Input, Select, Grid, Typography, TextField } from "@material-ui/core";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { FormControl, MenuItem, FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { CustomButton } from '../../../components/button/CustomButton';
import { get, post } from '../../../api/axios';
import './style.css';
import { openAlert } from "simple-react-alert";


const ModalPedidoPersonalizado = props => {
    const [telefono, setTelefono] = React.useState('');
    const [nombre, setNombre] = React.useState('');
    const [apellido, setApellido] = React.useState('');
    const [desde, setDesde] = React.useState('');
    const [pedido, setPedido] = React.useState('');
    const [hasta, setHasta] = React.useState('');
    const [user, setUser] = React.useState(null);
    const [buscado, setBuscado] = React.useState(false);
    const [ciudadId, setCiudadId] = React.useState(false);
    const [ciudades, setCiudades] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [comentario, setComentario] = React.useState('');
    const [envio, setEnvio] = React.useState(undefined);
    const [valorPedido, setValorPedido] = React.useState(undefined);
    const [delivery, setDelivery] = React.useState('1');
    const [tarjeta, setTarjeta] = React.useState('0');

    React.useEffect(() => {
        get('/status/ciudades').then(
            statusRes => {
                setCiudades(statusRes.data.ciudades);
                statusRes.data.ciudades.length === 1 && setCiudadId(statusRes.data.ciudades[0].id)
            });

    }, [])

    const buscarTelefono = () => {
        setLoading(true);
        get(`/users/search-telefono?telefono=${telefono}`).then(response => {
            setLoading(false);
            setBuscado(true);
            let user = response.data;
            if (user.nombre) {
                setNombre(user.nombre);
                setApellido(user.apellido);
                setUser(user);
                user.ciudad_id && setCiudadId(user.ciudad_id)
            }
        })
    }

    const crearPedido = () => {
        let body = {
            direccion_entrega: hasta,
            direccion_origen: desde,
            detalle: pedido,
            user_id: user ? user.id : null,
            nombre: nombre,
            apellido: apellido,
            telefono: telefono,
            take_away: false,
            precio_envio: envio,
            total: valorPedido,
            tarjeta: tarjeta ? true : false

        }
        setLoading(true);
        if (pedido.length < 1 || hasta.length < 1 || nombre.length < 1 || apellido.length < 1) {
            openAlert({ message: 'parece que faltan completar datos', status: 'warning' });
            setLoading(false);
            return;
        }
        post('/admin-cadeterias/pedidos/', body).then(response => {
            openAlert({ message: 'pedido creado', status: 'success' });
            setLoading(false);
            close(true);
            setNombre('');
            setApellido('');
            setUser(null);
            setHasta('');
            setDesde('');
            setComentario('');
        })
    }

    const close = (pedidoHecho = false) => {
        if (pedidoHecho) {
            props.recargarPedidos();
        }
        props.setShowModal(!props.showModal);
    };

    return (
        <Modal
            open={props.showModal}
            onClose={() => close()}
            className="modalContainer"
        >
            <div className="modalCiudades" style={{ minHeight: '35rem', width: '44rem' }}
            >
                <Grid container justify='space-between' alignItems='center' direction='column'>
                    <Grid item style={{ marginTop: '2rem' }}>
                        <TextField
                            variant="outlined"
                            type="string"
                            value={telefono}
                            placeholder='ingresa un telefono'
                            onChange={e => setTelefono(e.target.value)}
                        />
                    </Grid>
                    {buscado &&
                        <Grid container justify='center' alignItems='center' direction='row'>
                            <Grid item md={6} lg={6} style={{ marginTop: '2rem' }}>
                                <FormControl component="fieldset">
                                    <RadioGroup value={tarjeta} onChange={(event) => setTarjeta(event.target.value)}>
                                        <FormControlLabel value="0" control={<Radio />} label="Efectivo" />
                                        <FormControlLabel value="1" control={<Radio />} label="Tarjeta" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component="fieldset">
                                    <RadioGroup value={delivery} onChange={(event) => setDelivery(event.target.value)}>
                                        <FormControlLabel value="1" control={<Radio />} label="Delivery" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item md={11} lg={11} style={{ marginTop: '2rem' }}>
                                <TextField
                                    type="string"
                                    value={nombre}
                                    style={{ marginRight: '1rem' }}
                                    disabled={user !== null}
                                    variant="outlined"
                                    placeholder='nombre'
                                    onChange={e => setNombre(e.target.value)}
                                />
                                <TextField
                                    type="string"
                                    variant="outlined"
                                    value={apellido}
                                    style={{ marginRight: '1rem' }}
                                    disabled={user !== null}
                                    placeholder='apellido'
                                    onChange={e => setApellido(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={11} lg={11} style={{ marginTop: '2rem' }}>
                                <TextField
                                    type="string"
                                    variant="outlined"
                                    value={hasta}
                                    style={{ marginRight: '1rem' }}
                                    placeholder='direccion entrega'
                                    onChange={e => setHasta(e.target.value)}
                                />
                                <TextField
                                    type="string"
                                    variant="outlined"
                                    value={desde}
                                    style={{ marginRight: '1rem' }}
                                    placeholder='direccion origen'
                                    onChange={e => setDesde(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={11} lg={11} style={{ marginTop: '2rem' }}>
                                <TextField
                                    type="string"
                                    value={pedido}
                                    style={{ width: '95.1%' }}
                                    multiline
                                    variant="outlined"
                                    placeholder='ingresa el pedido'
                                    onChange={e => setPedido(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={11} style={{ marginTop: '2rem' }}>
                                <TextField
                                    type="number"
                                    value={valorPedido}
                                    variant="outlined"
                                    style={{ marginRight: '1rem' }}
                                    placeholder='monto del pedido'
                                    onChange={e => setValorPedido(e.target.value)}
                                />
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    value={envio}
                                    style={{ marginRight: '1rem' }}
                                    placeholder='monto del envio'
                                    onChange={e => setEnvio(e.target.value)}
                                />
                            </Grid>
                            <Grid item lg={3} xs md={3} style={{ marginTop: '2rem' }}>
                                <CustomButton
                                    onClick={() => crearPedido()}
                                    label='Crear pedido'
                                    isLoading={loading}

                                />
                            </Grid>
                        </Grid>}
                    {!buscado && <Grid item lg xs style={{ marginTop: '2rem' }}>
                        <CustomButton
                            onClick={() => buscarTelefono()}
                            label='Buscar usuario'
                            isLoading={loading}

                        >
                        </CustomButton>
                    </Grid>}
                </Grid>
            </div>
        </Modal >
    );
};

export default ModalPedidoPersonalizado;
