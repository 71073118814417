import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./bootstrap.min.css"
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { StoreProvider as PedidosProvider } from "./views/pedidos/pedidosContext/Store";
import { StoreProvider } from './context/Store';
import { ThemeProvider } from '@material-ui/core';
import NavBar from "./navigation/navbar/NavBar";
import Negocios from "./views/administrarNegocios/Options";
import NuevoNegocio from "./views/administrarNegocios/NuevoNegocio";
import EditarNegocio from "./views/administrarNegocios/editarNegocio/EditarNegocio";
import EditarProducto from "./views/administrarNegocios/editarNegocio/EditarProducto";
import EditarInformacion from "./views/administrarNegocios/editarNegocio/EditarInformacion";
import NuevoProducto from "./views/administrarNegocios/editarNegocio/NuevoProducto";
import AgregarBebida from "./views/administrarNegocios/editarNegocio/AgregarBebida";
import AdministrarGlobales from './views/administrarNegocios/editarNegocio/AdministrarGlobales';
import AgregarCadete from "./views/administrarCadetes/AgregarCadete";
import CadetesDisponibles from "./views/administrarCadetes/CadetesDisponibles";
import Middleware from "./Middleware";
import Login from "./loginModule/Login";
import Alert from "simple-react-alert";
import EditarCadete from "./views/administrarCadetes/EditarCadete";
import TablaUsuarios from "./views/usuarios/TablaUsuarios";
import Historial from "./views/historialPedidos/Historial";
import ReporteCadetes from "./views/reporteCadetes/ReporteCadetes";
import EstadisticasPedidos from "./views/estadisticas/EstadisticasPedidos";
import EstadisticasUsuarios from "./views/estadisticas/EstadisticasUsuarios";
import AdministrarTipos from "./views/administrarTipos/AdministrarTipos";
import CuponesList from "./views/cupones/CuponesList";
import EditarCupon from "./views/cupones/EditarCupon";
import MenuNegocios from "./views/usuariosNegocios/MenuNegocios";
import PanelContainer from './views/newDashboard/PanelContainer';
import AgregarCupon from "./views/cupones/AgregarCupon";
import CiudadesList from "./views/ciudades/CiudadesList";
import EditarCiudad from "./views/ciudades/EditarCiudad";
import AgregarCiudad from "./views/ciudades/AgregarCiudad";
import AgregarUsuario from "./views/usuariosNegocios/AgregarUsuario";
import EditarUsuario from "./views/usuariosNegocios/EditarUsuario";
import ReporteNegocios from "./views/reporteNegocios/ReporteNegocios";
import MainPublicidad from "./views/administrarPublicidad/MainPublicidad";
import EditarPublicidad from "./views/administrarPublicidad/EditarPublicidad";
import Notificaciones from "./views/notificaciones/Notificaciones";
import OpcionesPromocion from './views/administrarPromociones/Opciones';
import EditarPromocion from "./views/administrarPromociones/EditarPromocion";
import AgregarPromocion from './views/administrarPromociones/AgregarPromocion';
import temaConfig from './temaConfig';
import PuntajesCadetes from "./views/puntajesCadetes/PuntajesCadetes";
import MainPendientes from './views/pendientes/MainPendientes';
import PedidosContainer from "./views/pedidos/PedidoContainer";
import BetaEditarCadete from './views/betaNegocios/betaAdministrarCadetes/BetaEditarCadete';
import CadetesContainer from "./views/administrarCadetes/CadetesContainer";
import NegocioCadetesDisponibles from "./views/betaNegocios/betaAdministrarCadetes/NegocioCadetesDisponibles";
import NegocioAgregarCadete from "./views/betaNegocios/betaAdministrarCadetes/NegocioAgregarCadete";
import BetaEditarCadeteCadeteria from "./views/betaCadeterias/betaAdministrarCadetes/BetaEditarCadeteCadeteria";
import CadeteriaCadetesDisponibles from "./views/betaCadeterias/betaAdministrarCadetes/CadeteriaCadetesDisponibles";
import CadeteriaAgregarCadete from "./views/betaCadeterias/betaAdministrarCadetes/CadeteriaAgregarCadete";
import PanelCadetesNegocio from "./views/betaNegocios/betaCadetes/PanelCadetesNegocio";
import PanelCadetesCadeteria from "./views/betaCadeterias/betaCadetesCadeteria/PanelCadetesCadeteria";
import OptionsCadeteria from './views/administrarCadeterias/Options';
import NuevaCadeteria from "./views/administrarCadeterias/NuevaCadeteria";
import EditarInformacionCadeteria from "./views/administrarCadeterias/editarCadeteria/EditarInformacionCadeteria";
import NegocioSincronizarCadete from "./views/betaNegocios/betaAdministrarCadetes/NegocioSincronizarCadete";
import HistorialNegocio from "./views/betaNegocios/historialPedidos/HistorialNegocio";
import DatosEnvioForm from "./views/betaNegocios/envios/DatosEnvioForm";
import MainBilleteras from "./views/administrarBilleteras/MainBilleteras";
ReactDOM.render(
    <ThemeProvider theme={temaConfig}>
        <StoreProvider>
            <PedidosProvider>
                <BrowserRouter>
                    <Alert />
                    <NavBar />
                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <Switch>
                        <Middleware path="/pedidos" component={PedidosContainer} />
                        <Middleware exact path="/panel" component={PanelContainer} />
                        <Middleware exact path="/cadeterias" component={OptionsCadeteria} />
                        <Middleware exact path="/cadeterias/nuevo" component={NuevaCadeteria} />
                        <Middleware exact path="/cadeterias/editar/:id" component={EditarInformacionCadeteria} />
                        <Middleware exact path="/negocios" component={Negocios} />
                        <Middleware path="/negocios/nuevo" component={NuevoNegocio} />
                        <Middleware
                            exact
                            path="/negocios/editar/:id"
                            component={EditarNegocio}
                        />
                        <Middleware
                            path="/negocios/editar/:id/info"
                            component={EditarInformacion}
                        />
                        <Middleware
                            path="/negocios/reporte"
                            component={ReporteNegocios}
                        />
                        <Middleware
                            path="/negocios/editar/:id/producto/:prodId"
                            component={EditarProducto}
                        />
                        <Middleware
                            path="/negocios/editar/:id/nuevo/producto"
                            component={NuevoProducto}
                        />
                        <Middleware
                            path="/negocios/editar/:id/nuevo/bebida"
                            component={AgregarBebida}
                        />
                        <Middleware path="/negocios/editar/:id/globales" component={AdministrarGlobales} />
                        <Middleware exact path="/negocios/usuarios" component={MenuNegocios} />
                        <Middleware exact path="/negocios/usuarios/crear" component={AgregarUsuario} />
                        <Middleware exact path="/negocios/usuarios/editar/:id" component={EditarUsuario} />
                        <Middleware exact path="/cadetes" component={CadetesContainer} />
                        <Middleware
                            path="/cadetes/reporte"
                            component={ReporteCadetes}
                        />

                        <Middleware path="/cadetes/nuevo" component={AgregarCadete} />
                        <Middleware
                            path="/cadetes/disponibles"
                            component={CadetesDisponibles}
                        />
                        <Middleware
                            path="/cadetes/editar/:id"
                            component={EditarCadete}
                        />
                        <Middleware
                            path="/cadetes/adminnegocio/editar/:id"
                            component={BetaEditarCadete}
                        />
                        <Middleware
                            path="/cadetes/adminnegocio/disponibles"
                            component={NegocioCadetesDisponibles}
                        />
                        <Middleware
                            path="/cadetes/adminnegocio/nuevo"
                            component={NegocioAgregarCadete}
                        />
                        <Middleware
                            path="/cadetes/adminnegocio/sincronizar"
                            component={NegocioSincronizarCadete}
                        />
                        <Middleware path="/billetera" component={MainBilleteras} />

                        <Middleware
                            path="/panel/adminnegocio"
                            component={PanelCadetesNegocio}
                        />
                        <Middleware
                            path="/historial/adminnegocio"
                            component={HistorialNegocio}
                        />
                        <Middleware
                            path="/envios/adminnegocio"
                            component={DatosEnvioForm}
                        />
                        <Middleware
                            path="/panel/admincadeteria"
                            component={PanelCadetesCadeteria}
                        />
                        <Middleware
                            path="/cadetes/admincadeteria/editar/:id"
                            component={BetaEditarCadeteCadeteria}
                        />
                        <Middleware
                            path="/cadetes/admincadeteria/disponibles"
                            component={CadeteriaCadetesDisponibles}
                        />
                        <Middleware
                            path="/cadetes/admincadeteria/nuevo"
                            component={CadeteriaAgregarCadete}
                        />
                        <Middleware
                            path="/historial/admincadeteria"
                            component={HistorialNegocio}
                        />
                        <Middleware exact path="/usuarios" component={TablaUsuarios} />
                        <Middleware path="/historial" component={Historial} />
                        <Middleware path="/estadisticas/pedidos" component={EstadisticasPedidos} />
                        <Middleware path="/estadisticas/usuarios" component={EstadisticasUsuarios} />
                        <Middleware path="/tipos" component={AdministrarTipos} />
                        <Middleware exact path="/publicidad" component={MainPublicidad} />
                        <Middleware exact path="/publicidad/editar/:id" component={EditarPublicidad} />
                        <Middleware exact path="/publicidad/nuevo" component={EditarPublicidad} />
                        <Middleware exact path="/promociones" component={OpcionesPromocion} />
                        <Middleware exact path="/promociones/nuevo" component={AgregarPromocion} />
                        <Middleware exact path="/promociones/editar/:id" component={EditarPromocion} />
                        <Middleware exact path="/cupones" component={CuponesList} />
                        <Middleware path="/cupones/editar/:id" component={EditarCupon} />
                        <Middleware path="/cupones/nuevo" component={AgregarCupon} />
                        <Middleware exact path="/ciudades" component={CiudadesList} />
                        <Middleware path="/ciudades/editar/:id" component={EditarCiudad} />
                        <Middleware path="/ciudades/nuevo" component={AgregarCiudad} />
                        <Middleware path="/notificaciones" component={Notificaciones} />
                        <Middleware path="/cadetes/puntajes" component={PuntajesCadetes} />
                        <Middleware path="/pendientes" component={MainPendientes} />

                    </Switch>
                </BrowserRouter>
            </PedidosProvider>
        </StoreProvider>
    </ThemeProvider>,
    document.getElementById("root")
);
