import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { TextField, Button } from "@material-ui/core";
import "./style.css";
import { withRouter } from "react-router";
import { login } from "../api/axios";
import { connect } from "react-redux";
import { StoreContext } from "../context/Store";
import { saveLoginInfo } from '../context/actions/saveLoginInfo';
import { constants } from "../context/keys";
import { openAlert } from "simple-react-alert";
import { CustomButton } from "../components/button/CustomButton";

//import { login } from "../reduxModule/Actions";
const Login = props => {
    const { dispatch } = React.useContext(StoreContext)
    const [usuario, setUsuario] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        if (sessionStorage.getItem("fasterToken") !== null) {
            props.history.push("/pedidos");
        }
    });

    const onLogin = () => {
        setLoading(true);
        login({ email: usuario, password: password }).then(response => {
            sessionStorage.setItem(constants.token, response.data.token);
            saveLoginInfo(response.data.token, response.data.user, dispatch);
            props.history.push("/dashboard");
            setLoading(false);
        }).catch(response => {
            openAlert({ message: 'Credenciales invalidas', type: 'warning' });
            setLoading(false);

        });
    };

    return (
        <section className="cardContainer">
            <article className="cardLogin">
                <Row xs={8}>
                    <TextField
                        id="outlined-name"
                        label="Usuario"
                        value={usuario}
                        className={"textInputLogin"}
                        onChange={e => setUsuario(e.target.value)}
                        margin="normal"
                        variant="outlined"
                    />
                </Row>
                <Row xs={8}>
                    <TextField
                        id="outlined-uncontrolled"
                        label="Contraseña"
                        margin="normal"
                        className={"textInputLogin"}
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        variant="outlined"
                    />
                </Row>
                <Row xs={4} className={"loginBtnContainer"}>
                    <CustomButton variant="contained" isLoading={loading} onClick={onLogin} size='large' label='Iniciar Sesion' className={"loginBtn"} />
                </Row>
            </article>
        </section>
    );
};


export default withRouter(Login);
