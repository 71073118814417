import React from 'react'

const renderDetalles = (pedido) => {

    const renderPedido = () => {
        let id = pedido.items[0] ? pedido.items[0].producto_id: null;
        let detalle = pedido.items[0] ? pedido.items[0].detalles : "";
        let counts = [];
        let count = 0;
        let indice = -1;
        pedido.items.map(item => {
            if (id === item.producto_id && detalle === item.detalles) {
                count++;
                indice++;
            } else {
                counts.push({ count: count, index: indice, detalle: detalle });
                count = 1;
                detalle = item.detalles;
                indice++;
                id = item.producto_id;
            }
        });
        counts.push({ count: count, index: indice, detalle: detalle });
        return counts.map((count, key) => {
            return (
                <p key={key}>
                    {pedido.items[count.index].producto
                        ? pedido.items[count.index].producto.titulo
                        : pedido.items[count.index].detalle}{" "}
                        x{count.count}
                    <br></br> > {count.detalle}
                </p>
            );
        });
    };

    const renderDetalle = () => {
        switch (pedido.tipo) {
            case "Personalizado": {
                return (
                    <div>
                        <p>
                            <b>PEDIDO PERSONALIZADO</b> <br />
                            <br />
                            <b>ID: </b> {pedido.id} <br />
                            <b>Usuario: </b> {pedido.user.nombre + ' ' + pedido.user.apellido} <b>ID: </b>{pedido.user.id} <br />
                            <b>Telefono Usuario: </b> {pedido.user.telefono} <br />
                            <br />
                            <b>Direccion Origen: </b> {pedido.direccion_origen} <br />
                            <b>Direccion Entrega: </b> {pedido.direccion_entrega} <br />
                            <br />
                            <b>Pedido: </b> {pedido.detalle_pedido} <br />
                        </p>
                    </div>
                );
            }
            default: {
                return (
                    <div>
                        <p>
                            <b>ID: </b> {pedido.id}
                            <br />
                            <b>Usuario: </b> {pedido.user.nombre + ' ' + pedido.user.apellido} <b>ID: </b>{pedido.user.id}
                            <br />
                            <b>Negocio: </b> {pedido.negocio.nombre + " "}
                            <br />
                            <b>Direccion: </b> {pedido.negocio.ubicacion}
                            <br />
                            {
                                pedido.direccion &&
                                <><b>Lat-Lon: </b>{pedido.direccion.latitud} {pedido.direccion.longitud} {pedido.direccion.valida ? '(OK)' : '(NO OK)'}</>
                            }
                        </p>
                        <p>{pedido.tarjeta ? pedido.pagado ? <b>Tarjeta: PAGO</b> : <b>Tarjeta: NO PAGO</b> : <b>Efectivo</b>}</p>
                        <p>
                            <b>Precio: </b> {pedido.total} <br />
                            <b>Envio: </b> {pedido.precio_envio} <br />
                            {
                                pedido.descuento > 0 &&
                                <><b>Descuento: -</b> {pedido.descuento} <br /></>
                            }
                            <b>Total: </b> {pedido.total + pedido.precio_envio - pedido.descuento} <br />
                        </p>
                        <p>
                            <b>Direccion Entrega: </b>{" "}
                            {pedido.direccion_entrega}
                            <br />
                            <b>Entre calles: </b> {pedido.entre_calles}
                        </p>
                        <p>
                            <b>Telefono Usuario: </b>{" "}
                            {pedido.user.telefono}
                        </p>
                        <p>
                            <b>Comentarios: </b> {pedido.comentario}
                        </p>
                        <p>
                            <b>Productos: </b>
                        </p>
                        {renderPedido()}
                        <p>
                            <b> Cupones : </b>
                            {pedido.cupon
                                ? pedido.cupon.es_porcentaje
                                    ? "%" + pedido.cupon.descuento
                                    : "$" + pedido.cupon.descuento
                                : "NO"}
                            <br />
                            <b>Hora de entrega: </b> {pedido.hora}
                        </p>
                    </div>
                );
            }
        }
    };

    return renderDetalle()
}
export default renderDetalles;