import React, { useEffect } from 'react'
import { get, post } from '../../../api/axios';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import MenuItem from "@material-ui/core/MenuItem";
import Col from "react-bootstrap/Col";
import { withRouter } from 'react-router';
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { FormControlLabel, Checkbox, Button, FormControl } from "@material-ui/core";
import { openAlert } from "simple-react-alert";

const AdministrarGlobales = (props) => {
    const [globales, setGlobales] = React.useState({})
    const [tipos, setTipos] = React.useState([]);

    useEffect(() => {
        get(`/negocios/${props.match.params.id}/ingredientes`).then(response => {
            setGlobales(response.data);
        })
    }, []);
    useEffect(() => {
        get(`/negocios/${props.match.params.id}/ingredientes/tipos`).then(response => {
            setTipos(response.data);
        })
    }, []);

    const setGlobalProp = (indexProp, value, entrie, indexGlobal) => {
        const globalesTemp = { ...globales };
        globalesTemp[entrie][indexGlobal][indexProp] = value;
        setGlobales(globalesTemp);

    }

    const handleSubmit = () => {
        const ingredientes = [];
        Object.entries(globales).map(entries => {
            entries[1].map(ingrediente => {
                const ingr = { nombre: ingrediente.nombre, disponible: ingrediente.disponible, precio: ingrediente.precio, tipo: ingrediente.tipo };
                ingredientes.push(ingr)
            })
        });
        post(`/negocios/${props.match.params.id}/ingredientes`, { ingredientes: ingredientes }).then(response => {
            openAlert({ message: 'Guardado con exito', type: 'success' });
        }).catch(error => { openAlert({ message: 'Guardado fallido', type: 'error' }); });
    }

    const renderGlobales = () => {
        let index = -1;
        return Object.entries(globales).map(entries => {
            {
                index = -1;
                return <>
                    <p>{entries[0]}:</p>
                    {entries[1].map(ingrediente => {
                        let ind = ++index;
                        return (
                            <Row xs={{ span: 11, offset: 1 }} key={ind}>
                                <Col xs={3}>
                                    <TextValidator
                                        label="Ingrediente"
                                        value={ingrediente.nombre}
                                        onChange={e => {
                                            setGlobalProp("nombre", e.target.value, entries[0], ind);
                                        }}
                                        variant="filled"
                                        validators={["required"]}
                                        errorMessages={["Este campo es requerido"]}
                                        className="textInput"
                                    />
                                </Col>
                                <Col xs={3}>
                                    <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                                        <SelectValidator
                                            label="Tipo del ingrediente"
                                            value={ingrediente.tipo}
                                            onChange={e => setGlobalProp("tipo", e.target.value, entries[0], ind)}
                                            validators={["required"]}
                                            variant="filled"
                                            errorMessages={["Este campo es requerido"]}
                                        >
                                            <MenuItem value={"extra"}>Extra</MenuItem>
                                            <MenuItem value={"sacar"}>Ingrediente</MenuItem>
                                            <MenuItem value={"aderezo"}>Aderezo</MenuItem>
                                            <MenuItem value={"acomp"}>Acompañamiento</MenuItem>
                                            <MenuItem value={"gusto4"}>Gusto</MenuItem>
                                            <MenuItem value={"sugerido"}>Sugerido</MenuItem>
                                            <MenuItem value={"opcion"}>Opcion</MenuItem>
                                            <MenuItem value={"bebida"}>Bebida</MenuItem>
                                        </SelectValidator>
                                    </FormControl>
                                </Col>
                                <Col xs={2}>
                                    <TextValidator
                                        label="Precio"
                                        value={ingrediente.precio}
                                        onChange={e => {
                                            setGlobalProp("precio", e.target.value, entries[0], ind);
                                        }}
                                        variant="filled"
                                        className="textInput"
                                    />
                                </Col>
                                <Col xs={2}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={ingrediente.disponible}
                                            onChange={e => setGlobalProp("disponible", !ingrediente.disponible, entries[0], ind)}
                                            value="Disponible"
                                        />}
                                        label={'Disponible'}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <Button
                                        onClick={() => eliminarIngrediente(entries[0], ind)}
                                        className="MuiButton-containedPrimary"
                                    >
                                        Eliminar
                                    </Button>
                                </Col>
                            </Row>
                        );
                    })}
                </>
            }
        });
    };

    const eliminarIngrediente = (entrie, index) => {
        let globalesTemp = { ...globales };
        globalesTemp[entrie].splice(index, 1);
        setGlobales(globalesTemp);
    };

    const agregarGlobales = () => {
        let globalesTemp = { ...globales };
        globalesTemp['nuevo'] ? globalesTemp['nuevo'].push({ nombre: '', disponible: true, tipo: '' }) :
            globalesTemp = { ...globales, 'nuevo': [{ nombre: "", disponible: true, tipo: '' }] }
        setGlobales(globalesTemp);
    };

    return (<Container className="container-tournament-form">
        <ValidatorForm onSubmit={handleSubmit}>
            <Row>
                <Col xs={12} className="mb-2">
                    {renderGlobales()}
                    <Button onClick={() => agregarGlobales()} color="primary">
                        + Agregar Ingrediente Global
                    </Button>
                </Col>
                <Button className="MuiButton-containedPrimary"
                    type='submit'>Guardar</Button>
            </Row>
        </ValidatorForm>
    </Container>)

}

export default withRouter(AdministrarGlobales);