import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Container, Row, Col, Button } from "react-bootstrap";
import Loader from "../../../Loader";
import { get, post } from "../../../api/axios";
import { openAlert } from "simple-react-alert";
const AgregarBebida = props => {
    const [bebidas, setBebidas] = useState([]);
    const [loader, setLoader] = React.useState(false);

    useEffect(() => {
        get("/negocios/" + props.match.params.id + "/bebidas").then(response => {
            let bebidasString = response.data.map(bebida => {
                return bebida.nombre;
            });
            setBebidas(bebidasString);
        });
    }, []);

    const agregarBebida = () => {
        let bebidasTemp = [...bebidas];
        bebidasTemp.push("");
        setBebidas(bebidasTemp);
    };

    const setBebida = (bebida, index) => {
        let bebidasTemp = [...bebidas];
        bebidasTemp[index] = bebida;
        setBebidas(bebidasTemp);
    };

    const eliminarBebida = index => {
        let bebidasTemp = [...bebidas];
        bebidasTemp.splice(index, 1);
        setBebidas(bebidasTemp);
    };
    const renderBebidas = () => {
        let index = -1;
        return bebidas.map((bebida, key) => {
            let ind = ++index;
            return (
                <Row xs={12} key={key}>
                    <Col xs={{ span: 6 }}>
                        <TextValidator
                            label="bebida"
                            value={bebida}
                            onChange={e => {
                                setBebida(e.target.value, ind);
                            }}
                            variant="filled"
                            validators={["required"]}
                            errorMessages={["es requerido"]}
                            className="textInput"
                        />
                    </Col>
                    <Col xs={6}>
                        <Button
                            onClick={() => eliminarBebida(ind)}
                            className="MuiButton-containedPrimary"
                        >
                            Eliminar
                        </Button>
                    </Col>
                </Row>
            );
        });
    };

    const handleSubmit = () => {
        setLoader(true);
        post("/negocios/" + props.match.params.id + "/bebidas", { bebidas }).then(response => {
            setLoader(false);
            openAlert({ message: "Guardado exitoso", type: "success" });
        });
    };

    return (
        <Container className="container-tournament-form">
            <ValidatorForm onSubmit={handleSubmit}>
                <Row xs={{ span: 6, offset: 2 }}>
                    <Col xs={{ span: 12, offset: 2 }}>
                        {renderBebidas()}
                        <Button
                            onClick={() => agregarBebida()}
                            className="MuiButton-containedPrimary"
                            style={{ marginTop: "20px" }}
                        >
                            + Agregar Bebida
                        </Button>
                    </Col>
                    <Col xs={{ span: 6, offset: 2 }} style={{ marginTop: "20px" }}>
                        {loader ? (
                            <Loader />
                        ) : (
                                <Button
                                    size="large"
                                    type="submit"
                                    className="MuiButton-containedPrimary"
                                >
                                    Guardar
                            </Button>
                            )}
                        <Button
                            size="large"
                            className="ml-2 MuiButton-containedPrimary"
                            onClick={() => props.history.goBack()}
                        >
                            Volver
                        </Button>
                    </Col>
                </Row>
            </ValidatorForm>
        </Container>
    );
};

export default withRouter(AgregarBebida);
