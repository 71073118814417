import React from "react";

const Audio = () => {
    return (
        <div>
            <audio src="http://faster.qanchapp.com/sounds/pedidos.mp3" loop autoPlay />
        </div>
    );
};

export default Audio;
