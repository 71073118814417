import React from "react";
import { constants } from './keys';
const { createContext, useReducer } = React;

const initialState = {};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN": {
            return { ...state, token: action.token, userInfo: action.userInfo };
        }
        case "LOGOUT": {
            return { ...state, token: null, userInfo: null };
        }
        case "RELOAD": {
            return { ...state, token: action.token, userInfo: action.userInfo };
        }
        default: {
            return { ...state };
        }
    }
};

export const StoreContext = createContext({
    state: initialState,
    dispatch: () => { },
});

export async function getInfo(dispatch) {
    let token = await sessionStorage.getItem(constants.token);
    let userInfo = await sessionStorage.getItem(constants.userInfo);
    dispatch({
        type: "RELOAD",
        token: token,
        userInfo: JSON.parse(userInfo)
    });
    return JSON.parse(userInfo);
}

export const StoreProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const store = { state, dispatch };

    React.useEffect(() => {
        getInfo(dispatch);
    }, []);

    return (
        <StoreContext.Provider value={store}>
            {props.children}
        </StoreContext.Provider>
    );
};

export default { StoreContext, StoreProvider };