export const isNegocio = (userInfo) => {
    if (userInfo === null)
        return false
    else
        return userInfo.roles.find(rol => {
            if (rol.name == 'negocio') {
                return true
            }
        });

}