import React from "react";
import { Modal, Input, Button } from "@material-ui/core";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { FormControl, MenuItem } from "@material-ui/core";
import { get } from '../../api/axios';
import './style.css';


const ModalCiudades = ({ tipo, ...props }) => {
    const [razon, setRazon] = React.useState();


    React.useEffect(() => {
        get(`/faster/disponible/${props.ciudadId}`).then(response => {
            props.setDisponible(tipo === 'negocios' ? response.data.disponible : response.data.disponible_personalizados);
        });
    }, [props.ciudadId]);


    const close = () => {
        props.setShowModal(!props.showModal);
    };

    return (
        <Modal
            open={props.showModal}
            onClose={() => close()}
            className="modalContainer"
        >
            <div className="modalCiudades">
                <p>Selecciona la ciudad:</p>
                <ValidatorForm onSubmit={() => { }}>
                    <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                        <SelectValidator
                            label="Ciudad"
                            value={props.ciudadId}
                            onChange={e => props.setCiudadId(e.target.value)}
                            validators={["required"]}
                            variant="filled"
                            errorMessages={["Este campo es requerido"]}
                        >
                            {props.ciudades.map((ciudad, key) => {
                                return (
                                    <MenuItem value={ciudad.id} key={key}>
                                        {ciudad.nombre}
                                    </MenuItem>
                                );
                            })}
                        </SelectValidator>
                    </FormControl>
                </ValidatorForm>
                {

                    props.disponible &&
                    <>
                        {tipo === 'negocios' ? <Input
                            type="string"
                            value={razon}
                            placeholder='ingresa una razon'
                            onChange={e => setRazon(e.target.value)}
                        ></Input> : <></>}
                        <Button
                            variant="outlined"
                            className="btnVerificar"
                            onClick={() => props.postDisponible(false, razon, tipo)}
                        >
                            Cerrar
                        </Button>
                    </>
                }
                {
                    !props.disponible &&

                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        className="ml-2"
                        onClick={() => props.postDisponible(true, '', tipo)}
                    >
                        Habilitar App
                </Button>
                }
            </div>
        </Modal>
    );
};

export default ModalCiudades;
