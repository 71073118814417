import React from "react";
import { withRouter } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { SelectValidator, ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { post, del, get, update } from "../../api/axios";
import { openAlert } from "simple-react-alert";
import { FormControl, MenuItem, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

const AgregarUsuario = props => {
    const [password, setPassword] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [nombre, setNombre] = React.useState("");
    const [apellido, setApellido] = React.useState("");
    const [ciudadId, setCiudadId] = React.useState(0);
    const [ciudades, setCiudades] = React.useState([]);
    const [negocios, setNegocios] = React.useState([]);
    const [negociosUser, setNegociosUser] = React.useState([]);

    React.useEffect(() => {
        get("/ciudades").then(response => {
            setCiudades(response.data);
        });

        get('/negocios').then(response => {
            setNegocios(response.data);
            if (props.editar) {
                get("/negocios/users/" + props.match.params.id).then(res2 => {
                    setEmail(res2.data.email);
                    setApellido(res2.data.apellido);
                    setNombre(res2.data.nombre);
                    setCiudadId(res2.data.ciudad_id);
                    setNegociosUser(response.data.filter(negocio => res2.data.negocios.find(n => n.id === negocio.id)));
                });
            }
        });

    }, []);

    const eliminarCadete = () => {
        del("/cadetes/" + props.match.params.id).then(response => {
            openAlert({ message: "Guardado exitoso", type: "success" });
            props.history.goBack();
        });
    };

    const handleSubmit = () => {
        const negociosAux = negociosUser.map((negocio) => negocio.id);

        let body = {
            email,
            nombre,
            apellido,
            negocios: negociosAux,
            ciudad_id: ciudadId
        };

        if (password.length > 0) {
            body = { ...body, password: password };
        }

        if (props.match.params.id) {
            update("/negocios/users/" + props.match.params.id, body).then(response => {
                openAlert({ message: "Usuario de negocio actualizado correctamente", type: "success" });
                props.history.goBack();
            });
        } else {
            post("/negocios/users", body).then(response => {
                openAlert({ message: "Usuario de negocio creado correctamente", type: "success" });
                props.history.goBack();
            });
        }
    }

    return (
        <>
            <Container className="container-tournament-form">
                <ValidatorForm onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }}>
                            <Row>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        validators={["required", "isEmail"]}
                                        variant="filled"
                                        className="textInput"
                                        errorMessages={[
                                            "Este campo es requerido",
                                            "Debe ser un email"
                                        ]}
                                    />
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Contraseña"
                                        value={password}
                                        onChange={e =>
                                            setPassword(e.target.value)
                                        }
                                        variant="filled"
                                        className="textInput"
                                    />
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Nombre"
                                        value={nombre}
                                        onChange={e =>
                                            setNombre(e.target.value)
                                        }
                                        variant="filled"
                                        className="textInput"
                                    />
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Apellido"
                                        value={apellido}
                                        onChange={e =>
                                            setApellido(e.target.value)
                                        }
                                        variant="filled"
                                        className="textInput"
                                    />
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                                        <SelectValidator
                                            label="Ciudad"
                                            value={ciudadId}
                                            onChange={e => setCiudadId(e.target.value)}
                                            validators={["required"]}
                                            variant="filled"
                                            errorMessages={["Este campo es requerido"]}
                                        >
                                            {ciudades.map((ciudad, key) => {
                                                return (
                                                    <MenuItem value={ciudad.id} key={key}>
                                                        {ciudad.nombre}
                                                    </MenuItem>
                                                );
                                            })}
                                        </SelectValidator>
                                    </FormControl>
                                </Col>
                                <Col xs={12} className="mb-2">
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        variant="filled"
                                        onChange={(e, v) => setNegociosUser(v)}
                                        options={negocios}
                                        getOptionLabel={(option) => option.nombre}
                                        value={negociosUser}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Negocios"
                                                placeholder="Negocios..."
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Col xs={12}>
                                <div className="form-card-header">
                                    <div className="buttons-header">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            className=""
                                            onClick={() =>
                                                props.history.goBack()
                                            }
                                        >
                                            Volver
                                        </Button>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            className="ml-2"
                                        >
                                            Guardar
                                        </Button>
                                        {/* {props.editar && (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                className="ml-2"
                                                onClick={() => eliminarCadete()}
                                            >
                                                Eliminar
                                            </Button>
                                        )} */}
                                    </div>
                                </div>{" "}
                            </Col>
                        </Col>
                    </Row>
                </ValidatorForm>
            </Container>
        </>
    );
};

export default withRouter(AgregarUsuario);
