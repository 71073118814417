import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { get, post } from "../../../api/axios";
import { Row, Col, Button, Container } from "react-bootstrap";
import { ValidatorForm } from "react-material-ui-form-validator";
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { openAlert } from "simple-react-alert";

const CadeteriaCadetesDisponibles = props => {
    const [cadetes, setCadetes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        get("/admin-cadeterias/cadetes").then(response => {
            setCadetes(response.data);
        });
    }, []);

    const disponible = (value, index) => {
        let cadetesTemp = cadetes.slice();
        cadetesTemp[index].disponible = value === 1 ? 1 : 0;
        setCadetes(cadetesTemp);
    };

    const handleSubmit = () => {
        setLoading(true);
        let body = {
            cadetes: cadetes
        };
        post("/admin-cadeterias/cadetes/disponibles", body).then(() => {
            openAlert({ message: "Guardado exitoso", type: "success" });
            setLoading(false);
        }).catch(() => {
            setLoading(false); openAlert({ message: "Hubo un error", type: "error" });
        })
    };
    const renderCadetes = () => {
        let index = -1;
        return cadetes.map((cadete, key) => {
            let ind = ++index;
            return (
                <Row xs={{ span: 12, offset: 2 }} key={key}>
                    <Col xs className="checkbox checkboxCadete">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cadetes[ind].disponible === 1}
                                    onChange={e =>
                                        disponible(cadetes[ind].disponible === 1 ? 0 : 1, ind)
                                    }
                                    value="Disponible"
                                />
                            }
                            label={`Esta disponible ${cadete.nombre} ${cadete.apellido}?`}
                        />
                    </Col>
                </Row>
            );
        });
    };
    return (
        <Container className="container-tournament-form">
            <ValidatorForm onSubmit={handleSubmit}>
                <Row>
                    {renderCadetes()}
                    <Col xs={{ span: 8, offset: 1 }}>
                        <div className="form-card-header">
                            <div className="buttons-header">
                                <Button
                                    size="large"
                                    variant="contained"
                                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-sizeLarge"
                                    onClick={() => props.history.goBack()}
                                >
                                    Volver
                                </Button>
                                <Button
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                    disabled={loading}
                                    className="ml-2 MuiButtonBase-root MuiButton-root ml-2 MuiButton-contained  MuiButton-sizeLarge"
                                >
                                    Guardar
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ValidatorForm>
        </Container>
    );
};

export default withRouter(CadeteriaCadetesDisponibles);
