import React from "react";
import { Modal, Input, Button } from "@material-ui/core";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { FormControl, MenuItem } from "@material-ui/core";
import { get } from '../../../api/axios';
import './style.css';


const ModalCiudades = ({ tipo, ...props }) => {
    const [razon, setRazon] = React.useState();


    React.useEffect(() => {
        get(`/admin-cadeterias/details`).then(response => {
            props.setDisponible(response.data.disponible);
        });
    }, []);


    const close = () => {
        props.setShowModal(!props.showModal);
    };

    return (
        <Modal
            open={props.showModal}
            onClose={() => close()}
            className="modalContainer"
        >
            <div className="modalCiudades">
                {
                    props.disponible ?
                        <Button
                            variant="outlined"
                            className="btnVerificar"
                            onClick={() => props.postDisponible(false)}
                        >
                            Cerrar
                        </Button>

                        :
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            className="ml-2"
                            onClick={() => props.postDisponible(true)}
                        >
                            Habilitar en App
                </Button>
                }
            </div>
        </Modal>
    );
};

export default ModalCiudades;
