import React from "react";
import { Button } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { get, post } from "../../../api/axios";
import ModalCiudades from './ModalCiudades';
import { openAlert } from "simple-react-alert";
import { StoreContext } from '../../../context/Store';
import ModalPedidoPersonalizado from './ModalPedidoPersonalizado';

import "./style.css";

const estadosFaster = [
    'normal',
    'atraso',
    'demora'
]

const Disponibilidad = props => {
    const { state } = React.useContext(StoreContext);
    const [showModalDisp, setShowModalDisp] = React.useState(false);
    const [showModalPersonalizado, setShowModalPersonalizado] = React.useState(false);
    const [showModalPedido, setShowModalPedido] = React.useState(false);
    const [showModalEliminar, setShowModalEliminar] = React.useState(false);
    const [showModalStatus, setShowModalStatus] = React.useState(false);
    const [ciudades, setCiudades] = React.useState([]);
    const [ciudadId, setCiudadId] = React.useState(1);
    const [disponible, setDisponible] = React.useState(true);
    const [disponiblePersonalizado, setDisponiblePersonalizado] = React.useState(true);
    const [estadoBahia, setEstadoBahia] = React.useState(null);
    const [estadoMonte, setEstadoMonte] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    React.useEffect(() => {
        state.userInfo &&
            get('/status/ciudades').then(
                statusRes => {
                    setCiudades(statusRes.data.ciudades);
                    setStatus(statusRes.data.ciudades);
                });
    }, [state]);

    const postDisponibilidad = (disponible) => {
        setShowModalDisp(false);
        post("/admin-cadeterias/disponible", {
            disponible: disponible,
        }).then(() => {
            setDisponible(false);
            openAlert({ message: "Disponibilidad modificada", type: 'success' })
        });
    };

    return (
        <Row
            xs={{ span: 12, offset: 2 }}
            className="justify-content-md-center rowContainer"
        >
            <Col xs={8} md={4}>
                <Button
                    size="large"
                    variant="contained"
                    className="ml-2"
                    onClick={() => setShowModalPedido(true)}
                >
                    Crear pedido
                    </Button>
            </Col>
            <Button
                size="large"
                variant="contained"
                className="ml-2"
                onClick={() => setShowModalDisp(true)}
            >
                Disponibilidad de cadeteria
                    </Button>
            {
                showModalDisp && <ModalCiudades
                    cancelar={() => { }}
                    showModal={true}
                    setDisponible={setDisponible}
                    disponible={disponible}
                    setShowModal={setShowModalDisp}
                    postDisponible={postDisponibilidad}
                />
            }

            {
                showModalPedido && <ModalPedidoPersonalizado
                    showModal={showModalPedido}
                    setShowModal={setShowModalPedido}
                    recargarPedidos={props.pedirPedidos}
                />
            }
        </Row >
    );
};

export default Disponibilidad;
