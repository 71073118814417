import React, { useState } from "react";
import { Modal, Grid, TextField } from "@material-ui/core";
import { CustomButton } from '../../components/button/CustomButton';
import { post } from '../../api/axios';
import './style.css';
import { openAlert } from "simple-react-alert";


const ModalBilletera = props => {
    const [monto, setMonto] = useState()
    const [usuario, setUsuario] = useState();
    const [loading, setLoading] = useState(false);
    const modificarSaldo = () => {
        let body = {
            monto: monto
        };
        setLoading(true);
        post(`/users/${usuario}/billetera`, body).then(response => {
            openAlert({ message: 'saldo modificado', status: 'success' });
            setLoading(false);
            close(true);
            setMonto(0);
            setUsuario(0);
        })
    }

    const close = () => {
        props.setShowModal(false);
    }
    return (
        <Modal
            open={props.showModal}
            onClose={() => close()}
            className="modalContainer"
        >
            <div className="modalCiudades" style={{ minHeight: '35rem', width: '44rem' }}>
                <Grid container justify='space-between' alignItems='center' direction='column'>
                    <Grid item style={{ marginTop: '2rem' }} xs={5}>
                        <TextField
                            variant="outlined"
                            type="string"
                            value={usuario}
                            placeholder='ingresa id de usuario'
                            onChange={e => setUsuario(e.target.value)}
                        />
                    </Grid>
                    <Grid Item xs={5}>
                        <TextField
                            type="number"
                            value={monto}
                            style={{ marginTop: '2rem' }}
                            variant="outlined"
                            placeholder='monto'
                            onChange={e => setMonto(e.target.value)}
                        />
                    </Grid>
                    <Grid item lg={3} xs md={3} style={{ marginTop: '2rem' }}>
                        <CustomButton
                            onClick={() => modificarSaldo()}
                            label='Modificar saldo'
                            isLoading={loading}

                        />
                    </Grid>
                </Grid>
            </div>
        </Modal >
    );
};

export default ModalBilletera;
