import React, { useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withRouter } from "react-router";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import ImageUploader from "../../../components/imageUploader/ImageUploader";
import { get, post, del, update, postImage } from "../../../api/axios";
import Loader from "../../../Loader";
import { openAlert } from "simple-react-alert";

const EditarProducto = props => {
  const [nombre, setNombre] = React.useState("");
  const [precio, setPrecio] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [pendiente, setPendiente] = React.useState(false);
  const [imagen, setImagen] = React.useState(null);
  const [descripcion, setDescripcion] = React.useState("");
  const [disponible, setDisponible] = React.useState(false);
  const [ingredientes, setIngredientes] = React.useState([]);
  const [bebida, setBebida] = React.useState(false);
  const [destacado, setDestacado] = React.useState(false);
  const [min, setMin] = React.useState(0);
  const [max, setMax] = React.useState(0);
  const [tiposGlobal, setTiposGlobal] = React.useState([]);
  const [globales, setGlobales] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  useEffect(() => {
    if (props.edit !== false) {
      get("/productos/" + props.match.params.prodId).then(response => {
        const producto = response.data;
        setNombre(producto.titulo);
        setDescripcion(producto.descripcion);
        setDisponible(producto.disponible === 1);
        setPrecio(producto.precio);
        setTipo(producto.tipo);
        setImagen(producto.imagen)
        setBebida(producto.has_bebidas)
        setDestacado(producto.destacado);
        setIngredientes(producto.ingredientes);
        setPendiente(producto.pendiente === 1 ? true : false);
        setGlobales(producto.tipos_globales);
      });
    }
  }, [props.edit, props.match.params.prodId]);

  useEffect(() => {
    get(`/negocios/${props.match.params.id}/ingredientes/tipos`).then(response => {
      setTiposGlobal(response.data);
    })
  }, []);

  const handleSubmit = () => {
    setLoader(true);
    let body = {
      titulo: nombre,
      descripcion: descripcion,
      precio: precio,
      disponible: disponible,
      tipo: tipo,
      negocio_id: props.match.params.id,
      ingredientes: ingredientes,
      min_ingredientes: min,
      has_bebidas: bebida,
      destacado: destacado,
      ingredientes_globales: globales
    };
    if (tipo === "Helado") {
      body = { ...body, max_ingredientes: max };
    }
    var formData = new FormData();
    formData.append("imagen", imagen);

    if (props.edit !== false) {
      update("/productos/" + props.match.params.prodId, body).then(response => {
        openAlert({ message: "Guardado exitoso", type: "success" });
        setLoader(false);
        if (response.data)
          imagen && postImage("/productos/" + props.match.params.prodId + "/imagen", formData);
      });
    } else {
      post("/productos/", body).then(response => {
        openAlert({ message: "Guardado exitoso", type: "success" });
        setLoader(false);
        postImage("/productos/" + response.data.id + "/imagen", formData);
      });
    }
  };

  const setTipos = (tipo, index) => {
    let ingredientesTemp = [...ingredientes];
    ingredientesTemp[index].tipo = tipo;
    setIngredientes(ingredientesTemp);
  }
  const agregarIngrediente = () => {
    let ingredientesTemp = [...ingredientes];
    ingredientesTemp.push({ nombre: "", descripcion: "", extra: false, precio: null });
    setIngredientes(ingredientesTemp);
  };

  const setIngrediente = (ingrediente, index) => {
    let ingredientesTemp = [...ingredientes];
    ingredientesTemp[index].nombre = ingrediente;
    setIngredientes(ingredientesTemp);
  };

  const setPrecioExtraAux = (precio, index) => {
    let ingredientesTemp = [...ingredientes];
    ingredientesTemp[index].precio = precio;
    setIngredientes(ingredientesTemp);
  };

  const setIngredienteDisponible = (disponible, index) => {
    let ingredientesTemp = [...ingredientes];
    ingredientesTemp[index].disponible = disponible;
    setIngredientes(ingredientesTemp);
  }
  const eliminarIngrediente = index => {
    let ingredientesTemp = [...ingredientes];
    ingredientesTemp.splice(index, 1);
    setIngredientes(ingredientesTemp);
  };

  const eliminarCache = () => {
    post("/productos/cache-imagenes", { productos: [props.match.params.prodId] }).then(response => {
      openAlert({ message: "Cache eliminada", type: "success" });
    });
  };

  const aprobarModificacion = () => {
    post("/productos/pendientes/aprobar/" + props.match.params.prodId).then(response => {
      openAlert({ message: "Revisión aprobada", type: "success" });
      setPendiente(false);
    });
  }

  const renderIngredientes = () => {
    let index = -1;
    return ingredientes.map((ingrediente, key) => {
      let ind = ++index;
      return (
        <Row xs={{ span: 10, offset: 2 }} key={key}>
          <Col xs={3}>
            <TextValidator
              label="Ingrediente"
              value={ingrediente.nombre}
              onChange={e => {
                setIngrediente(e.target.value, ind);
              }}
              variant="filled"
              validators={["required"]}
              errorMessages={["Este campo es requerido"]}
              className="textInput"
            />
          </Col>
          <Col xs={3}>
            <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
              <SelectValidator
                label="Tipo del ingrediente"
                value={ingrediente.tipo}
                onChange={e => setTipos(e.target.value, ind)}
                validators={["required"]}
                variant="filled"
                errorMessages={["Este campo es requerido"]}
              >
                <MenuItem value={"extra"}>Extra</MenuItem>
                <MenuItem value={"sacar"}>Ingrediente</MenuItem>
                <MenuItem value={"aderezo"}>Aderezo</MenuItem>
                <MenuItem value={"acomp"}>Acompañamiento</MenuItem>
                <MenuItem value={"gusto"}>Gusto</MenuItem>
                <MenuItem value={"sugerido"}>Sugerido</MenuItem>
                <MenuItem value={"opcion"}>Opcion</MenuItem>
              </SelectValidator>
            </FormControl>
          </Col>
          <Col xs={2}>
            <TextValidator
              label="Precio"
              value={ingrediente.precio}
              key={key}
              onChange={e => {
                setPrecioExtraAux(e.target.value, ind);
              }}
              validators={["required", "isNumber"]}
              errorMessages={["El precio es requerido", "El precio debe ser un numero"]}
              variant="filled"
              className="textInput"
            />
          </Col>
          <Col xs={2}>
            <FormControlLabel
              control={<Checkbox
                checked={ingrediente.disponible}
                onChange={e => setIngredienteDisponible(!ingrediente.disponible, ind)}
                value="Disponible"
              />}
              label={'Disponible'}
            />
          </Col>
          <Col xs={2}>
            <Button
              onClick={() => eliminarIngrediente(ind)}
              className="MuiButton-containedPrimary"
            >
              Eliminar
                        </Button>
          </Col>
        </Row>
      );
    });
  };

  const eliminarProducto = () => {
    del("/productos/", props.match.params.prodId).then(response => {
      openAlert({ message: "Guardado exitoso", type: "success" });
    });
  };

  const setGlobal = (index, value) => {
    const globalTemp = [...globales];
    globalTemp[index] = value;
    setGlobales(globalTemp)
  }

  const renderGlobales = () => {
    let index = -1;
    return globales.map((global, key) => {
      let inde = ++index;
      return <Row key={key} xs={8}>
        <Col xs={3} className="mb-2">
          <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
            <SelectValidator
              label="Tipo del ingrediente global"
              value={global}
              onChange={e => setGlobal(inde, e.target.value)}
              validators={["required"]}
              variant="filled"
              errorMessages={["Este campo es requerido"]}
            >
              {tiposGlobal.map(tipo => { return <MenuItem value={tipo}>{tipo}</MenuItem> })}
            </SelectValidator>
          </FormControl>
          <Col xs={3}>
            <Button className="MuiButton-containedPrimary"
              onClick={() => eliminarGlobal(inde)}>Eliminar</Button>
          </Col>
        </Col>

      </Row>
    });
  }

  const agregarGlobal = () => {
    const globalTemp = [...globales];
    globalTemp.push('');
    setGlobales(globalTemp);
  }

  const eliminarGlobal = (index) => {
    const globalTemp = [...globales];
    globalTemp.splice(index, 1);
    setGlobales(globalTemp);
  }
  return (
    <Container className="container-tournament-form">
      <ValidatorForm onSubmit={handleSubmit}>
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <Row>
              <Col xs={6} className="mb-2">
                <TextValidator
                  label="Nombre del producto"
                  value={nombre}
                  onChange={e => setNombre(e.target.value)}
                  validators={["required", "minStringLength:3"]}
                  variant="filled"
                  className="textInput"
                  errorMessages={["Este campo es requerido", "Deben ser al menos 3 caracteres"]}
                />
              </Col>
              <Col xs={6} className="mb-2 checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={disponible}
                      onChange={e => setDisponible(!disponible)}
                      value="Disponible"
                    />
                  }
                  label="Esta disponible al publico?"
                />
              </Col>
              <Col xs={12} className="mb-2">
                <TextValidator
                  label="Descripcion"
                  onChange={e => setDescripcion(e.target.value)}
                  name="description"
                  variant="filled"
                  multiline
                  rows="4"
                  className="descriptionInput"
                  value={descripcion}
                />
              </Col>
              <Col xs={6} className="mb-2">
                <TextValidator
                  label="Precio"
                  value={precio}
                  onChange={e => setPrecio(e.target.value)}
                  validators={["required", "isNumber", "minNumber:0"]}
                  variant="filled"
                  className="textInput"
                  errorMessages={[
                    "Este campo es requerido",
                    "Tiene que ser un valor numerico",
                    "El valor minimo tiene que ser 0"
                  ]}
                />
              </Col>
              <Col xs={6} className="mb-2">
                <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                  <SelectValidator
                    label="Tipo del producto"
                    value={tipo}
                    onChange={e => setTipo(e.target.value)}
                    validators={["required"]}
                    variant="filled"
                    errorMessages={["Este campo es requerido"]}
                  >
                    <MenuItem value={"Chocolates"}>Chocolates</MenuItem>
                    <MenuItem value={"Alfajores"}>Alfajores</MenuItem>
                    <MenuItem value={"Cigarrillos"}>Cigarrillos</MenuItem>
                    <MenuItem value={"Jugos y Saborizadas"}>Jugos y Saborizadas</MenuItem>
                    <MenuItem value={"Perfumeria"}>Perfumeria</MenuItem>
                    <MenuItem value={"Snacks"}>Snacks</MenuItem>
                    <MenuItem value={"Galletitas"}>Galletitas</MenuItem>
                    <MenuItem value={"Chicles"}>Chicles</MenuItem>
                    <MenuItem value={"Lacteos"}>Lacteos</MenuItem>
                    <MenuItem value={"Varios"}>Varios</MenuItem>
                    <MenuItem value={"Empanadas al horno"}>Empanadas al horno</MenuItem>
                    <MenuItem value={"Empanadas fritas"}>Empanadas fritas</MenuItem>
                    <MenuItem value={"Empanadas"}>Empanadas</MenuItem>
                    <MenuItem value={"Pizzas"}>Pizzas</MenuItem>
                    <MenuItem value={"Hamburguesas"}>Hamburguesas</MenuItem>
                    <MenuItem value={"Hamburguesas Veganas"}>Hamburguesas Veganas</MenuItem>
                    <MenuItem value={"Helados"}>Helados</MenuItem>
                    <MenuItem value={"Papas"}>Papas</MenuItem>
                    <MenuItem value={"Sandwiches"}>Sandwiches</MenuItem>
                    <MenuItem value={"Platos"}>Platos</MenuItem>
                    <MenuItem value={"Acompañamientos"}>Acompañamientos</MenuItem>
                    <MenuItem value={"Pastas"}>Pastas</MenuItem>
                    <MenuItem value={"Promos"}>Promos</MenuItem>
                    <MenuItem value={"Postres"}>Postres</MenuItem>
                    <MenuItem value={"McCombos Grandes"}>McCombos Grandes</MenuItem>
                    <MenuItem value={"McCombos Medianos"}>McCombos Medianos</MenuItem>
                    <MenuItem value={"Combo del dia"}>Combo del dia</MenuItem>
                    <MenuItem value={"Cajita"}>Cajita</MenuItem>
                    <MenuItem value={"Hamburguesas Solas"}>Hamburguesas Solas</MenuItem>
                    <MenuItem value={"Milanesas"}>Milanesas</MenuItem>
                    <MenuItem value={"Ensaladas"}>Ensaladas</MenuItem>
                    <MenuItem value={"Pollos"}>Pollos</MenuItem>
                    <MenuItem value={"Carnes"}>Carnes</MenuItem>
                    <MenuItem value={"Celiacos"}>Celiacos</MenuItem>
                    <MenuItem value={"Bebidas"}>Bebidas</MenuItem>
                    <MenuItem value={"Cafeteria"}>Cafeteria</MenuItem>
                    <MenuItem value={"Vegetariana"}>Vegetariana</MenuItem>
                    <MenuItem value={"Vegana"}>Vegana</MenuItem>
                    <MenuItem value={"Mexicana"}>Mexicana</MenuItem>
                    <MenuItem value={"Japonesa"}>Japonesa</MenuItem>
                    <MenuItem value={"Licuados y Jugos"}>Licuados y Jugos</MenuItem>
                    <MenuItem value={"Menu del dia"}>Menu del dia</MenuItem>
                    <MenuItem value={"Picadas"}>Picadas</MenuItem>
                    <MenuItem value={"Parrillas"}>Parrillas</MenuItem>
                    <MenuItem value={"Tartas"}>Tartas</MenuItem>
                    <MenuItem value={"Bombones"}>Bombones</MenuItem>
                    <MenuItem value={"Gomitas"}>Gomitas</MenuItem>
                    <MenuItem value={"Caramelos"}>Caramelos</MenuItem>
                    <MenuItem value={"Masticables"}>Masticables</MenuItem>
                    <MenuItem value={"Pescados y Mariscos"}>Pescados y Mariscos</MenuItem>
                    <MenuItem value={"Aromatizante"}>Aromatizante</MenuItem>
                    <MenuItem value={"Difusores"}>Difusores</MenuItem>
                    <MenuItem value={"Esencias"}>Esencias</MenuItem>
                    <MenuItem value={"Perfumes"}>Perfumes</MenuItem>
                    <MenuItem value={"Cuidado Personal"}>Cuidado Personal</MenuItem>
                    <MenuItem value={"Baño"}>Baño</MenuItem>
                    <MenuItem value={"Maquillaje"}>Maquillaje</MenuItem>
                    <MenuItem value={"Hogar"}>Hogar</MenuItem>
                    <MenuItem value={"Combos"}>Combos</MenuItem>
                    <MenuItem value={"Panificados"}>Panificados</MenuItem>
                    <MenuItem value={"Facturas"}>Facturas</MenuItem>
                    <MenuItem value={"Tortas"}>Tortas</MenuItem>
                    <MenuItem value={"Dulces"}>Dulces</MenuItem>
                    <MenuItem value={"Bebidas sin alcohol"}>Bebidas sin alcohol</MenuItem>
                    <MenuItem value={"Bebidas con alcohol"}>Bebidas con alcohol</MenuItem>
                    <MenuItem value={"Cervezas"}>Cervezas</MenuItem>
                    <MenuItem value={"Vinos"}>Vinos</MenuItem>
                    <MenuItem value={"Espumantes"}>Espumantes</MenuItem>
                    <MenuItem value={"Aperitivos"}>Aperitivos</MenuItem>
                    <MenuItem value={"Salados"}>Salados</MenuItem>
                    <MenuItem value={"Almacen"}>Almacen</MenuItem>
                    <MenuItem value={"Ferreteria"}>Ferreteria</MenuItem>
                    <MenuItem value={"Pintureria"}>Pintureria</MenuItem>
                    <MenuItem value={"Construccion"}>Construccion</MenuItem>
                    <MenuItem value={"Milanesas M"}>Milanesas M"</MenuItem>
                    <MenuItem value={"Milanesas L"}>Milanesas L"</MenuItem>
                    <MenuItem value={"Milanesas XL"}>Milanesas XL"</MenuItem>
                    <MenuItem value={"Burritos"}>Burritos</MenuItem>
                    <MenuItem value={"Pizzetas"}>Pizzetas</MenuItem>
                    <MenuItem value={"Tradicionales"}>Tradicionales</MenuItem>
                    <MenuItem value={"Simples"}>Simples</MenuItem>
                    <MenuItem value={"Fiambres"}>Fiambres</MenuItem>

                  </SelectValidator>
                </FormControl>
              </Col>
              <Col xs={6} className="mb-2 checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={destacado}
                      onChange={e => setDestacado(!destacado)}
                      value="destacad0"
                    />
                  }
                  label="Es destacado?"
                />
              </Col>

              {tipo !== "Helado" ? (
                <Col xs={6} className="mb-2 checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bebida}
                        onChange={e => setBebida(!bebida)}
                        value="bebida"
                      />
                    }
                    label="Incluye Bebida?"
                  />
                </Col>
              ) : (
                <></>
              )}
              {tipo === "Helado" ? (
                <React.Fragment>
                  <Col xs={6} className="mb-2">
                    <TextValidator
                      label="Cantidad minima de sabores"
                      value={min}
                      onChange={e => setMin(e.target.value)}
                      validators={["required", "isNumber", "minNumber:0"]}
                      variant="filled"
                      className="textInput"
                      errorMessages={[
                        "Este campo es requerido",
                        "Tiene que ser un valor numerico",
                        "El valor minimo tiene que ser 0"
                      ]}
                    />
                  </Col>
                  <Col xs={6} className="mb-2">
                    <TextValidator
                      label="Cantidad maxima de sabores"
                      value={max}
                      onChange={e => setMax(e.target.value)}
                      validators={["required", "isNumber", "minNumber:0"]}
                      variant="filled"
                      className="textInput"
                      errorMessages={[
                        "Este campo es requerido",
                        "Tiene que ser un valor numerico",
                        "El valor minimo tiene que ser 0"
                      ]}
                    />
                  </Col>
                </React.Fragment>
              ) : (
                <></>
              )}
              <Col xs={12} className="mb-2">
                {renderGlobales()}
                <Button onClick={() => agregarGlobal()} color="primary">
                  Agregar ingrediente global
                </Button>
              </Col>
              <Col xs={6} className="mb-2">
                <ImageUploader setImagen={setImagen} imagen={imagen} />
              </Col>
              <Col xs={6} className="mb-2">
                {imagen && <img src={process.env.REACT_APP_API_URL + '/' + imagen} style={{ width: "300px", height: "300px" }} />}
              </Col>
              <Col xs={12} className="mb-2">
                {renderIngredientes()}
                <Button onClick={() => agregarIngrediente()} color="primary">
                  + Agregar Ingrediente
                </Button>
              </Col>
              <Col xs={12}>
                <div className="form-card-header">
                  <div className="buttons-header">
                    {pendiente && <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      className="ml-2"
                      onClick={() => {
                        aprobarModificacion();
                      }}
                    >
                      Aprobar modificacion
                    </Button>}
                    <Button
                      size="large"
                      variant="contained"
                      className=""
                      onClick={() => props.history.goBack()}
                    >
                      Volver
                    </Button>
                    {loader ? (
                      <Loader />
                    ) : (
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="ml-2"
                      >
                        Guardar
                      </Button>
                    )}

                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      className="ml-2"
                      onClick={() => {
                        eliminarCache();
                      }}
                    >
                      Eliminar cache
                    </Button>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      className="ml-2"
                      onClick={() => {
                        eliminarProducto();
                      }}
                    >
                      Eliminar
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ValidatorForm>
    </Container >
  );
};

export default withRouter(EditarProducto);
