import React from 'react';
import { get, post } from '../../api/axios';
import "./style.css";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import { FormControl, MenuItem, Select, Grid } from "@material-ui/core";
import TablaPuntajes from './TablaPuntajes';
import Loader from '../../Loader';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    width: "80%",
    overflowX: "auto"
  },
  table: {
    width: "100%"
  }
});

const PuntajesCadetes = () => {
  const classes = useStyles();

  const [ciudadId, setCiudadId] = React.useState(null);
  const [ciudades, setCiudades] = React.useState(null);
  const [fechaInicio, setFechaInicio] = React.useState(moment());
  const [fechaFin, setFechaFin] = React.useState(moment());
  const [puntajes, setPuntajes] = React.useState(null);

  React.useEffect(() => {
    get('/ciudades').then(response => {
      setCiudades(response.data);
    });
  }, []);

  const fetchPuntajes = () => {
    post('/cadetes/puntajes', {
      ciudad_id: ciudadId,
      desde: moment(fechaInicio).format("YYYY-MM-DD"),
      hasta: moment(fechaFin).format("YYYY-MM-DD")
    }).then(response => {
      setPuntajes(response.data);
    })
  }

  return (
    <div className="formWidth">
      <Grid container justify='center'>
        {
          ciudades ? 
          <form className="formWidth" noValidate className="datePicker">
            <Grid item xs={8}>
              <TextField
                id="fecha"
                label="Desde"
                type="date"
                value={fechaInicio.format("YYYY-MM-DD")}
                InputLabelProps={{
                  shrink: true
                }}
                className="datePicker"
                onChange={e => setFechaInicio(moment(e.target.value))}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="fecha"
                label="Hasta"
                type="date"
                value={fechaFin.format("YYYY-MM-DD")}
                InputLabelProps={{
                  shrink: true
                }}
                className="datePicker"
                onChange={e => setFechaFin(moment(e.target.value))}
              />
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth margin="dense" variant="filled" className="float-right">
                <Select
                  label="Ciudad"
                  value={ciudadId}
                  onChange={e => setCiudadId(e.target.value)}
                  variant="filled"
                >
                  {ciudades.map((ciudad, key) =>
                    <MenuItem value={ciudad.id} key={key}>
                      {ciudad.nombre}
                    </MenuItem>
                  )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Button onClick={fetchPuntajes} variant="contained" >
              Calcular Puntaje
            </Button>
          </form>
          : <Loader />
        }
        {
          puntajes && ciudadId &&
          <Grid item xs={12}>
            <TablaPuntajes puntajes={puntajes} />
          </Grid>
        }
      </Grid>
    </div >
  )

}

export default PuntajesCadetes;
