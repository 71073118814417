import React from "react";
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import CadeteIcon from '@material-ui/icons/DirectionsBike';

const EncabezadoItem = ({ pedido }) => {
    return (
        <span className="texto-encabezado">
            {`  #${pedido.id}`}
            {pedido.confirmado === 1 ? <CadeteIcon /> : <></>}
        </span>
    )
};

export default EncabezadoItem;
