import React, { useEffect } from "react";
import { Grid, Card } from "@material-ui/core";
import "./style.css";
import { get, post } from "../../api/axios";
import Item from './Item';

import moment from 'moment';
import echo from "../../echo";

const channel = echo.channel(process.env.REACT_APP_LARAVEL_ECHO_CHANNEL);


const PanelPedidos = () => {
    const [cadetesGroup, setCadetesGroup] = React.useState([]);

    useEffect(() => {
        
        channel.listen(process.env.REACT_APP_ECHO_DASHBOARD_EVENTS, (data) => {
            console.log("Dashboard general", data);
            pedirPedidos();
        });

        pedirPedidos();
    }, []);

    console.log('estoy aca?');

    const pedirPedidos = () => {
        let date = moment().format("YYYY-MM-DD");
        get("/cadetes/pedidos?fecha=" + date).then(response => {
            setCadetesGroup(Object.entries(response.data));
        });
    };

    const avanzar = (pedido) => {
        switch (pedido.estado) {
            case "Recibido": {
                let endpoint = "/pedidos/" + pedido.id + "/estado";
                post(endpoint, { estado: "Búsqueda" }).then(response => {
                    pedirPedidos();
                });
                break;
            }
            case "Búsqueda": {
                let endpoint = "/pedidos/" + pedido.id + "/estado";
                post(endpoint, { estado: "En Camino" }).then(response => {
                    pedirPedidos();
                });
                break;
            }
            case "En Camino": {
                let endpoint = "/pedidos/" + pedido.id + "/estado";
                post(endpoint, { estado: "Afuera" }).then(response => {
                    pedirPedidos();
                });
                break;
            }
            case "Afuera": {
                let endpoint = "/pedidos/" + pedido.id + "/estado";
                post(endpoint, { estado: "Finalizado" }).then(response => {
                    pedirPedidos();
                });
                break;
            }
            default: {
                break;
            }
        }
    }
    const calcularCompletados = (pedidos) => {
        let count = 0;
        pedidos.map(pedido => {
            if (pedido.estado === 'Finalizado') {
                count++;
            }
        });

        return count;
    }

    const getCiudad = (cadetes) => {
        switch (cadetes[0]) {
            case "1": return "Bahia Blanca";
            case "2": return "Monte Hermoso";
            case "3": return "Tandil";
            default: return "Desconocido";
        }
    }

    const renderColumnaCadetes = (cadetes) => {
        return cadetes.map((cadete, key) => {
            return cadete.pedidos ?
                <Grid container xs={6} key={key}>
                    <div className='cadeteLabel'>
                        <p>{cadete.nombre + " " + cadete.apellido}: {calcularCompletados(cadete.pedidos)}</p>
                    </div>
                    <Card className='containerPanel'>
                        <div className="containerCadetes">
                            <div className='panelCadete'>
                                {cadete.pedidos.map((pedido, key) => {
                                    return (pedido.estado !== "Finalizado" && pedido.estado !== "Cancelado") && <Item pedido={pedido} key={key} avanzar={avanzar} />
                                })}
                            </div>
                        </div>
                    </Card >
                </Grid> : <></>
        })

    }

    return (
        <Grid container xs={12}>{
            cadetesGroup.map((cadetes, key) => {
                return <Grid container xs={12} key={key} className='mb-5'>
                    <Grid item xs={12}>
                        <div className="ciudadLabel"><p>Ciudad: {getCiudad(cadetes)}</p></div>
                    </Grid>
                    {renderColumnaCadetes(cadetes[1].slice(0, cadetes.length / 2))}
                    {renderColumnaCadetes(cadetes[1].slice(cadetes.length / 2))}
                </Grid>
            })}

        </Grid >
    );
};

export default PanelPedidos;
