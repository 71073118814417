import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { get, post } from "../../api/axios";
import { Row, Col, Button, Container } from "react-bootstrap";
import { ValidatorForm } from "react-material-ui-form-validator";
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { openAlert } from "simple-react-alert";

const CadetesDisponibles = props => {
    const [cadetes, setCadetes] = React.useState([]);

    useEffect(() => {
        get("/cadetes").then(response => {
            setCadetes(response.data);
        });
    }, []);

    const toggleDisponible = cadete => {
        let cadetesTemp = cadetes.map(cad_ciu_group => {
            if (cad_ciu_group.ciudad.id === cadete.user.ciudad_id) {
                let newGroup = {
                    ciudad: cad_ciu_group.ciudad,
                    cadetes: cad_ciu_group.cadetes.map(cad => {
                        if (cad.id === cadete.id) {
                            return {
                                ...cad,
                                disponible: !cad.disponible
                            };
                        }
                        return cad;
                    })
                };
                return newGroup;
            }
            return cad_ciu_group;
        });
        setCadetes(cadetesTemp);
    };

    const handleSubmit = () => {
        let cadetesFlat = [];
        cadetes.forEach((element) =>{
            cadetesFlat = [...cadetesFlat,...element.cadetes];
        });
        let body = {
            cadetes: cadetesFlat
        };
        post("/cadetes/disponibles", body).then(() => {
            openAlert({ message: "Modificado con exito", type: "success" });
        });
    };
    const renderCadetes = cadetes => {
        let index = -1;
        return cadetes.map((cadete, key) => {
            let ind = ++index;
            return (
                <Row xs={{ span: 8, offset: 2 }} key={key}>
                    <Col
                        xs={{ span: 6, offset: 1 }}
                        className="checkbox checkboxCadete"
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cadetes[ind].disponible}
                                    onChange={e => toggleDisponible(cadete)}
                                    value="Disponible"
                                />
                            }
                            label={`Esta disponible ${cadete.nombre} ${cadete.apellido}?`}
                        />
                    </Col>
                </Row>
            );
        });
    };
    return (
        <Container className="container-tournament-form">
            <ValidatorForm onSubmit={handleSubmit}>
                <Row>
                    {cadetes &&
                        cadetes.map((ciudad, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <Typography>
                                        {ciudad.ciudad.nombre}
                                    </Typography>
                                    <Col xs={12} className="mb-2">
                                        {renderCadetes(ciudad.cadetes)}
                                    </Col>
                                </React.Fragment>
                            );
                        })}
                    <Col xs={{ span: 8, offset: 1 }}>
                        <div className="form-card-header">
                            <div className="buttons-header">
                                <Button
                                    size="large"
                                    variant="contained"
                                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-sizeLarge"
                                    onClick={() => props.history.goBack()}
                                >
                                    Volver
                                </Button>
                                <Button
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                    className="ml-2 MuiButtonBase-root MuiButton-root ml-2 MuiButton-contained MuiButton-containedPrimary MuiButton-sizeLarge"
                                >
                                    Guardar
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </ValidatorForm>
        </Container>
    );
};

export default withRouter(CadetesDisponibles);
