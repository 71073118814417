import React from "react";
import { post } from "../../api/axios";
import "./style.css";
import moment from 'moment';
import GraficosPedidos from "./GraficosPedidos";
import GraficosUsuarios from "./GraficosUsuarios";
import { TextField , Button , CircularProgress } from "@material-ui/core";
import { Row } from "react-bootstrap";

const Estadisticas = ( {tipo} ) => {

    const [fecha_inicio, setFechaInicio] = React.useState((moment().subtract(7, "days")).format("YYYY-MM-DD"));
    const [fecha_fin, setFechaFin] = React.useState((moment().format("YYYY-MM-DD")));
    const [estadisticas, setEstadisticas] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);
    
    const [showEstadisticasPedidos,setEstadisticasPedidos] = React.useState(false);
    const [showEstadisticasUsuarios,setEstadisticasUsuarios] = React.useState(false);

    React.useEffect(() => {
        obtenerEstadisticas(fecha_inicio,fecha_fin,tipo);
    }, []);

    const obtenerEstadisticas = (desde,hasta,tipo)  => {
        setLoading(true);

        post("/estadisticas/"+ tipo , { desde,hasta } )
            .then((response) => {
                setEstadisticas(response.data);

                console.log(response.data);
                if(tipo === 'pedidos')
                    setEstadisticasPedidos(true);
                else
                    setEstadisticasUsuarios(true);

                setLoading(false);
            });
    };

    return (
        <div className="container">
            <div className="formContainer">
                <Row xs={4} className="formElement">
                    <TextField
                        id="date"
                        label="Fecha Inicio"
                        type="date"
                        defaultValue={(moment().subtract(7, "days")).format("YYYY-MM-DD")}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={e => { setFechaInicio(e.target.value) }}
                    />
                </Row>
                <Row xs={4} className="formElement">
                    <TextField
                        id="date"
                        label="Fecha Fin"
                        type="date"
                        defaultValue={moment().format("YYYY-MM-DD")}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={e => { setFechaFin(e.target.value) }}
                    />
                </Row>
                <Row xs={4} className="formElement">
                    <Button variant="contained" onClick={() => obtenerEstadisticas(fecha_inicio,fecha_fin,tipo)} className={"generarBtn"}>
                        Generar
                    </Button>
                </Row>
            </div>

            {
                isLoading &&
                <div className="text-center">   
                    <CircularProgress/>
                </div>
            }

            {
                !isLoading && showEstadisticasPedidos && 
                    <GraficosPedidos data={estadisticas}/>
            }

{
                !isLoading && showEstadisticasUsuarios && 
                    <GraficosUsuarios data={estadisticas}/>
            }

        </div>
    );
};

export default Estadisticas;
