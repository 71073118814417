import React from "react";
import { Modal, Input, Button } from "@material-ui/core";
import { post } from "../../api/axios";
import { openAlert } from "simple-react-alert";

const ModalUsuarios = props => {
    const [telefono, setTelefono] = React.useState();

    const verificar = () => {
        post("/users/" + props.user.id + "/verificar", {
            telefono: telefono
        }).then(() => {
            openAlert({ message: "Verificado con exito", type: "success" });
            props.reloadUsers();
            props.setShowModal(!props.open);
            setTelefono("");
        });
    };

    const close = () => {
        setTelefono("");
        props.setShowModal(!props.open);
    };
    return (
        <Modal
            open={props.open}
            onClose={() => close()}
            className="modalContainer"
        >
            <div className="modalBody">
                <p>Ingresa el numero de telefono:</p>
                <Input
                    type="number"
                    value={telefono}
                    onChange={e => setTelefono(e.target.value)}
                ></Input>
                <Button
                    variant="outlined"
                    className="btnVerificar"
                    onClick={() => verificar()}
                >
                    Verificar
                </Button>
            </div>
        </Modal>
    );
};

export default ModalUsuarios;
