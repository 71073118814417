import React, { useState, useEffect } from 'react'
import PendientesList from './PendientesList'
import { Button, Grid } from '@material-ui/core';
import { get, post } from '../../api/axios';
import { openAlert } from 'simple-react-alert';
const MainPendientes = () => {

    const [productos, setProductos] = useState([])

    useEffect(() => {
        get("/productos/pendientes").then(
            response => {
                let negocios = response.data;
                negocios.sort((a, b) => a.negocio.nombre.localeCompare(b.negocio.nombre))
                setProductos(negocios);
            }
        );
    }, [])

    const aprobarPendientes = () => {
        post("/productos/pendientes/aprobar-todos").then(
            response => {
                openAlert({ message: "Aprobados con exito", type: "success" })
                get("/productos/pendientes").then(
                    response => {
                        let negocios = response.data;
                        negocios.sort((a, b) => a.negocio.nombre.localeCompare(b.negocio.nombre))
                        setProductos(negocios);
                    }
                );
            }
        );
    }
    return <Grid container justify="center">
        <Grid item>
            <Button
                size="large"
                style={{ marginTop: "2rem" }}
                variant="contained"
                className="ml-2" onClick={() => aprobarPendientes()}>Aprobar todos</Button>
        </Grid>
        <Grid item md={8}>
            <PendientesList productos={productos} />
        </Grid>
    </Grid>
}

export default MainPendientes;