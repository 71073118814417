import React from 'react'
import ItemPedidoConfirmado from './ItemPedidoConfirmado';
import ItemAfuera from './ItemAfuera';
import ItemEnEntrega from './ItemEnEntrega';
import moment from 'moment';
import './style.css'
const ItemProgramado = ({ setCadete, cadetes, aConfirmado, aCompletados, modificarPedido, cancelarPedido, aEntrega, aAfuera, setTimestamp, ...props }) => {

    const horaActual = moment().hour()
    return (
        <>
            <p>Programados: {props.numProgramados}</p>
            {props.programados.map((pedido, key) => {
                let horaPedido = moment(pedido.hora, "HH:mm");
                //                if (horaPedido.hour() - horaActual <= 1) {
                switch (pedido.estado) {
                    case ('Recibido'): {
                        return (
                            <ItemPedidoConfirmado
                                pedido={pedido}
                                key={key}
                                className={'itemPedido'}
                                setCadete={setCadete}
                                cadetes={cadetes[pedido.ciudad_id]}
                                cadete={pedido.cadete}
                                avanzar={aConfirmado}
                                modificarPedido={modificarPedido}
                                cancelarPedido={cancelarPedido}
                                setTimestamp={setTimestamp}
                            />
                        )
                        break;
                    }
                    case ('Búsqueda'): {
                        return (
                            <ItemPedidoConfirmado
                                className={'itemConfirmado'}
                                avanzar={aEntrega}
                                pedido={pedido}
                                key={key}
                                setCadete={setCadete}
                                cadetes={cadetes}
                                cadete={pedido.cadete}
                                modificarPedido={modificarPedido}
                                cancelarPedido={cancelarPedido}
                                setTimestamp={setTimestamp}
                            />
                        );
                        break;

                    }
                    case ('En Camino'): {
                        return (
                            <ItemEnEntrega
                                pedido={pedido}
                                key={key}
                                avanzar={aAfuera}
                            />
                        );
                        break;

                    }
                    case ('Afuera'): {
                        return (
                            <ItemAfuera
                                pedido={pedido}
                                key={key}
                                avanzar={aCompletados}
                            />
                        );
                        break;

                    }
                    case ('Finalizado' || 'Cancelado'): {
                        return <></>;
                        break;

                    }
                }
            })}
        </>
    )
}

export default ItemProgramado;
