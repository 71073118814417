import React from "react";
import "./style.css";
import { List, TextField, Checkbox } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ViewIcon from "@material-ui/icons/Visibility";
import { withRouter } from "react-router";
const PendientesList = props => {
    return (
        <List
            component="nav"
            aria-label="main mailbox folders"
            style={{ maxHeight: "400px" }}
        >
            {props.productos.map((wrapper, key) => {
                return (
                    <div key={key}>
                        <p className="mb-2">▬ Negocio: {wrapper.negocio.nombre}</p>
                        {wrapper.productos.map((producto, key) => {
                            return (
                                <ListItem key={key}>
                                    <ListItemText primary={producto.titulo} />
                                    <ListItemIcon>
                                        <ViewIcon
                                            className="pointer"
                                            onClick={() =>
                                                props.history.push(
                                                    `/negocios/editar/${wrapper.negocio.id}/producto/${producto.id}`
                                                )
                                            }
                                        />
                                    </ListItemIcon>
                                </ListItem>
                            );
                        })}
                    </div>
                );
            })}
        </List>
    );
};

export default withRouter(PendientesList);
