import React from "react";
import { withRouter } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { SelectValidator, ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { post, del, get, update } from "../../api/axios";
import { openAlert } from "simple-react-alert";
import { FormControl, MenuItem, FormControlLabel, Checkbox } from "@material-ui/core";

const AgregarCadete = props => {
    const [nombre, setNombre] = React.useState("");
    const [apellido, setApellido] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [externo, setExterno] = React.useState(false);
    const [ciudades, setCiudades] = React.useState([]);
    const [ciudadId, setCiudadId] = React.useState(null);

    React.useEffect(() => {
        get('/ciudades').then(res => {
            setCiudades(res.data);
        })
        if (props.editar) {
            get("/cadetes/" + props.match.params.id).then(response => {
                setNombre(response.data.nombre);
                setApellido(response.data.apellido);
                setTelefono(response.data.telefono);
                setEmail(response.data.user.email);
                setCiudadId(response.data.user.ciudad_id);
            });
        }
    }, []);

    const eliminarCadete = () => {
        del("/cadetes/" + props.match.params.id).then(response => {
            openAlert({ message: "Guardado exitoso", type: "success" });
            props.history.goBack();
        });
    };
    const handleSubmit = () => {
        let body = {
            nombre: nombre,
            apellido: apellido,
            telefono: telefono,
            externo: externo,
            email: email,
            ciudad_id: ciudadId
        };
        if (password.length > 0) {
            body = { ...body, password: password };
        }

        if (props.editar) {
            update("/cadetes/" + props.match.params.id, body).then(response => {
                openAlert({ message: "Guardado exitoso", type: "success" });
            });
        } else {
            post("/cadetes", body).then(response => {
                openAlert({ message: "Guardado exitoso", type: "success" });
            });
        }
    };
    return (
        <>
            <Container className="container-tournament-form">
                <ValidatorForm onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }}>
                            <Row>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Nombre"
                                        value={nombre}
                                        onChange={e =>
                                            setNombre(e.target.value)
                                        }
                                        validators={[
                                            "required",
                                            "minStringLength:3"
                                        ]}
                                        variant="filled"
                                        className="textInput"
                                        errorMessages={[
                                            "Este campo es requerido",
                                            "Por lo menos ingresa 3 caracteres"
                                        ]}
                                    />
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Apellido"
                                        value={apellido}
                                        onChange={e =>
                                            setApellido(e.target.value)
                                        }
                                        validators={[
                                            "required",
                                            "minStringLength:3"
                                        ]}
                                        variant="filled"
                                        className="textInput"
                                        errorMessages={[
                                            "Este campo es requerido",
                                            "Por lo menos ingresa 3 caracteres"
                                        ]}
                                    />
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Telefono"
                                        value={telefono}
                                        onChange={e =>
                                            setTelefono(e.target.value)
                                        }
                                        validators={[
                                            "required",
                                            "minStringLength:3"
                                        ]}
                                        variant="filled"
                                        type="number"
                                        className="textInput"
                                        errorMessages={[
                                            "Este campo es requerido",
                                            "Por lo menos ingresa 3 caracteres"
                                        ]}
                                    />
                                </Col>
                                <Col xs={6} className="mb-">
                                    <ValidatorForm onSubmit={() => { }}>
                                        <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                                            <SelectValidator
                                                label="Ciudad"
                                                value={ciudadId}
                                                onChange={e => setCiudadId(e.target.value)}
                                                validators={["required"]}
                                                variant="filled"
                                                errorMessages={["Este campo es requerido"]}
                                            >
                                                {ciudades.map((ciudad, key) => {
                                                    return (
                                                        <MenuItem value={ciudad.id} key={key}>
                                                            {ciudad.nombre}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </SelectValidator>
                                        </FormControl>
                                    </ValidatorForm>
                                </Col>

                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        validators={["required", "isEmail"]}
                                        variant="filled"
                                        className="textInput"
                                        errorMessages={[
                                            "Este campo es requerido",
                                            "Debe ser un email"
                                        ]}
                                    />
                                </Col>
                                <Col xs={6} className="mb-2">
                                    <TextValidator
                                        label="Contraseña"
                                        value={password}
                                        onChange={e =>
                                            setPassword(e.target.value)
                                        }
                                        variant="filled"
                                        className="textInput"
                                    />
                                </Col>
                                <Col xs={{ span: 6, offset: 1 }} className="checkbox checkboxCadete">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={externo}
                                                onChange={e =>
                                                    setExterno(!externo)
                                                }
                                                value="Es externo"
                                            />
                                        }
                                        label="Es externo"
                                    />
                                </Col>
                            </Row>
                            <Col xs={12}>
                                <div className="form-card-header">
                                    <div className="buttons-header">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            className=""
                                            onClick={() =>
                                                props.history.goBack()
                                            }
                                        >
                                            Volver
                                        </Button>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            className="ml-2"
                                        >
                                            Guardar
                                        </Button>
                                        {props.editar && (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                className="ml-2"
                                                onClick={() => eliminarCadete()}
                                            >
                                                Eliminar
                                            </Button>
                                        )}
                                    </div>
                                </div>{" "}
                            </Col>
                        </Col>
                    </Row>
                </ValidatorForm>
            </Container>
        </>
    );
};

export default withRouter(AgregarCadete);
