export const isAllowed = (userInfo, path) => {
    if (userInfo === null) return false;
    if (userInfo.roles.find(rol => rol.name === 'admin')) {
        return true;
    }
    else if (userInfo.roles.find(rol => rol.name === 'negocio')) {
        if (path.includes('/pedidos') || path.includes('/panel')
            || path.includes('/cadetes') || path.includes('/historial/adminnegocio') || path.includes('/envios/adminnegocio'))
            return true;
        else return false;
    }
    else if (path.includes('/pedidos') || path.includes('/panel') || path.includes('/negocios')
        || path.includes('/cadetes')
        || path.includes('/usuarios')
        || path.includes('/notificaciones')
        || path.includes('/negocios/usuarios')) {
        return true;
    }

    return false;
}