import React from "react";
const { createContext, useReducer } = React;

const initialState = { dirty: 0, pull: 0 };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "MODIFYING": {
      console.log('modifying', action.dirty);
      return {
        ...state,
        dirty: action.dirty
      };
    }
    case "PULL": {
      console.log('pull', action.pull, state);
      return {
        ...state,
        pull: action.pull
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const StoreContext = createContext({
  state: initialState,
  dispatch: () => { },
});


export const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const store = { state, dispatch };

  return (
    <StoreContext.Provider value={store}>
      {props.children}
    </StoreContext.Provider>
  );
};

export default { StoreContext, StoreProvider };
