import React from "react";
import "./style.css";
import moment from 'moment';
import { Chart } from "react-google-charts";

const GraficosPedidos = ( {data} ) => {

    const [estadisticas, setEstadisticas] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);

    React.useEffect(() => {
        generarEstadisticas(data);
    }, []);

    const generarEstadisticas = (estadisticas_data) => {
        
        let pedidosFinalizados = [
            ['Días','Finalizados', 'Cancelados']
        ];

        estadisticas_data.map((data) => {
            let cantidad = data.cantidad;            
            let itemData = [data.fecha,cantidad.finalizados,cantidad.cancelados];
            pedidosFinalizados.push(itemData);
        });

        let estadisticasGen = {};
        estadisticasGen.pedidosFinalizados = pedidosFinalizados;

        ///////////////////////////////////
        // Ingresos de Dinero
        ///////////////////////////////////

        let ingresoDinero = [
            ['Días',' $ Pesos']
        ];

        estadisticas_data.map((data) => {
            let montos = data.monto;            
            let itemData = [data.fecha,montos.subtotal];
            ingresoDinero.push(itemData);
        });

        estadisticasGen.ingresoDinero = ingresoDinero;

        ///////////////////////////////////
        // Pedidos por ciudad
        ///////////////////////////////////

        let pedidosCiudad = [
            ['Pedidos','Bahía Blanca','Monte Hermoso']
        ];

        let pedidosCiudadPie = [
            ['Bahía Blanca','Monte Hermoso']
        ];

        let cantPedidosBahia = 0;
        let cantPedidosMonte = 0;

        estadisticas_data.map((data) => {
            let ciudades = data.ciudades;            
            let itemData = [data.fecha,ciudades.bahia,ciudades.monte];

            cantPedidosBahia += ciudades.bahia;
            cantPedidosMonte += ciudades.monte;

            pedidosCiudad.push(itemData);
        });

        pedidosCiudadPie.push(['Bahia Blanca',cantPedidosBahia]);
        pedidosCiudadPie.push(['Monte Hermoso',cantPedidosMonte]);

        estadisticasGen.pedidosCiudad = pedidosCiudad;
        estadisticasGen.pedidosCiudadPie = pedidosCiudadPie;

        ///////////////////////////////////
        // Pedidos por Plataforma
        ///////////////////////////////////

        let pedidosPlataforma = [
            ['Pedidos','Android','iOS']
        ];

        let pedidosPlataformaPie = [
            ['Android','iOS']
        ];

        let cantAndroid = 0;
        let cantIOS = 0;

        estadisticas_data.map((data) => {
            let plataformas = data.plataformas;            
            let itemData = [data.fecha,plataformas.android,plataformas.ios];

            cantAndroid += plataformas.android;
            cantIOS += plataformas.ios;

            pedidosPlataforma.push(itemData);
        });

        pedidosPlataformaPie.push(['Android',cantAndroid]);
        pedidosPlataformaPie.push(['iOS',cantIOS]);

        estadisticasGen.pedidosPlataforma = pedidosPlataforma;
        estadisticasGen.pedidosPlataformaPie = pedidosPlataformaPie;

        setEstadisticas(estadisticasGen);
        setLoading(false);
    };

    return (
        <div>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="Bar"
                loader={<div>Cargando Gráfico</div>}
                data={estadisticas.pedidosFinalizados}
                options={{
                    // Material design options
                    chart: {
                    title: 'Cantidad de Pedidos',
                    subtitle: 'Finalizados vs Cancelados',
                    },
                }}
                // For tests
                rootProps={{ 'data-testid': '2' }}
            />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="Bar"
                loader={<div>Cargando Gráfico</div>}
                data={estadisticas.ingresoDinero}
                options={{
                    // Material design options
                    chart: {
                    title: 'Ganancias',
                    subtitle: 'Ingresos en Pesos',
                    },
                }}
                // For tests
                rootProps={{ 'data-testid': '2' }}
                />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="Bar"
                loader={<div>Cargando Gráfico</div>}
                data={estadisticas.pedidosCiudad}
                options={{
                    // Material design options  
                    chart: {
                    title: 'Pedidos por ciudad',
                    subtitle: 'Bahía Blanca vs Monte Hermoso',
                    },
                }}
                // For tests
                rootProps={{ 'data-testid': '2' }}
                />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Cargando Grafico de Torta</div>}
                data={estadisticas.pedidosCiudadPie}
                options={{
                title: 'Total de pedidos por ciudad',
                }}
                rootProps={{ 'data-testid': '1' }}
            />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="Bar"
                loader={<div>Cargando Gráfico</div>}
                data={estadisticas.pedidosPlataforma}
                options={{
                    // Material design options  
                    chart: {
                    title: 'Pedidos por Plataforma',
                    subtitle: 'Android vs iOS',
                    },
                }}
                // For tests
                rootProps={{ 'data-testid': '2' }}
                />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Cargando Grafico de Torta</div>}
                data={estadisticas.pedidosPlataformaPie}
                options={{
                title: 'Total de pedidos por plataforma',
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        </div>
    );
};

export default GraficosPedidos;
