import React from "react";
import './style.css';
import EncabezadoItem from "./EncabezadoItem";
import moment from "moment";

const renderPedido = props => {
    let id = props.items[0] ? props.items[0].producto_id : null;
    let counts = [];
    let count = 0;
    let indice = -1;
    let comments = [];
    props.items.map(item => {
        if (id === item.producto_id) {
            count++;
            indice++;
            comments.push({ comment: item.detalles });
        } else {
            counts.push({ count: count, index: indice, comments: comments });
            comments = [];
            count = 1;
            indice++;
            id = item.producto_id;
            comments.push({ comment: item.detalles });
        }
        return 0;
    });
    counts.push({ count: count, index: indice, comments: comments });
    const items = counts.map(count => {
        return `\nx${count.count} ${props.items[count.index].producto.titulo
            } $*${props.items[count.index].producto.precio *
            count.count}*\n  ${count.comments.map(comment => {
                return ` *--->* _${comment.comment}_\n `;
            })}`;
    });

    let itemsString = "";
    items.map(item => {
        itemsString += item + "\n";
    });

    return itemsString;
};

const Text = props => {
    switch (props.tipo) {
        case "RecogerEnviar": {
            return `*${props.direccion_origen}* - *${props.direccion_entrega
                }**\n
       \nValor aprox: ${props.pago || "Nada"}\n Producto: ${props.detalle_pedido
                } \nEnvio: ${props.precio_envio + props.total}`;
        }
        case "Personalizado": {
            return `*Direccion origen:* ${props.direccion_origen}\n*Direccion entrega:* ${props.direccion_entrega}\n*Telefono:* https://wa.me/549${props.user.telefono}\n*Pedido:* ${props.detalle_pedido}`;
        }
        case "PersonalizadoCadeteria": {
            return `${props.tarjeta ? '*Tarjeta*' : '*Efectivo*'}\n${props.take_away ? 'Take Away' : `*Origen:* ${props.direccion_entrega}`} *Destino:* ${props.direccion_origen}\n*Pedido:* ${props.detalle_pedido}\n*Telefono:* https://wa.me/549${props.user.telefono}\n*Total pedido:* ${props.total}\n*Precio envio:* ${props.precio_envio}`;
        }
        case "PersonalizadoNegocio": {
            return `${props.tarjeta ? '*Tarjeta*' : '*Efectivo*'}\n${props.take_away ? 'Take Away' : `*Direccion:* ${props.direccion_entrega}`}\n*Pedido:* ${props.detalle_pedido}\n*Telefono:* https://wa.me/549${props.user.telefono}\n*Total pedido:* ${props.total}\n*Precio envio:* ${props.precio_envio}`;
        }
        default: {
            let x = `_ID: #${props.id}_\n_Usuario: ${props.user.nombre} ${props.user.apellido}_\n*${props.negocio.nombre}* - *${props.negocio.ubicacion
                }* - *${props.direccion_entrega}* - *${props.piso_entrega
                }*\n_Entre calles: ${props.entre_calles}_\n\n*Comentarios: ${props.comentario}*\n${renderPedido(
                    props
                )}Subtotal: ${props.total}\nEnvio: ${props.precio_envio
                }\n`;

            if (props.descuento > 0) {
                x += `Descuento (${props.cupon && props.cupon.nombre}): -${props.descuento
                    }\n`
            }
            if (props.saldo_billetera > 0) {
                x += `Billetera: ${props.saldo_billetera}\n`
            }

            return x + `-----------------------------\nTotal: ${props.total +
                props.precio_envio - props.descuento - props.saldo_billetera}\n
            *${props.tarjeta ? "Tarjeta" : "Efectivo"}*
            \nTelefono: https://wa.me/549${props.user.telefono}
            \nUbicacion: https://maps.google.com/?q=${props.direccion && props.direccion.latitud},${props.direccion && props.direccion.longitud}
            `;
        }
    }
};

export function renderDetalle(pedido) {
    const horaPedido = moment(pedido.created_at);
    console.log(pedido, 'detalle')
    switch (pedido.tipo) {
        case "RecogerEnviar": {
            return (
                <div >
                    <EncabezadoItem pedido={pedido} />
                    <p style={{ width: "100%" }}>
                        Recoger y Enviar -{" "}
                        {(pedido.pago && "$" + pedido.pago) || "Sin pago"}
                    </p>
                    <p>
                        {"Pedido: " +
                            horaPedido.format("HH:mm") +
                            " Entregar: " +
                            pedido.hora}
                    </p>
                </div>
            );
        }
        case "Personalizado": {
            return (
                <div >
                    <EncabezadoItem pedido={pedido} />
                    <p style={{ width: "100%" }}>
                        <b>Personalizado</b> <br />
                        Origen: {pedido.direccion_origen} | Destino: {pedido.direccion_entrega} <br />
                        Telefono: {pedido.user.telefono} <br />
                        Hora: {horaPedido.format("HH:mm")} <br />
                        Cadete: {pedido.cadete ? (pedido.cadete.nombre + " " + pedido.cadete.apellido) : "-"}
                    </p>
                </div>
            );
        }
        case "PersonalizadoCadeteria": {
            return (
                <div >
                    <EncabezadoItem pedido={pedido} />
                    <p style={{ width: "100%" }}>
                        <b>Personalizado</b> <br />
                        Origen: {pedido.direccion_origen} <br />
                        Destino: {pedido.direccion_entrega} <br />
                        Telefono: {pedido.user.telefono} <br />
                        Hora: {horaPedido.format("HH:mm")} <br />
                        Cadete: {pedido.cadete ? (pedido.cadete.nombre + " " + pedido.cadete.apellido) : "-"}
                    </p>
                </div>
            );
        }
        case "PersonalizadoNegocio": {
            return (
                <div >
                    <EncabezadoItem pedido={pedido} />
                    <p style={{ width: "100%" }}>
                        <b>{pedido.negocio.nombre}</b> <br />
                        Destino: {pedido.direccion_entrega} <br />
                        Telefono: {pedido.user.telefono} <br />
                        Hora: {horaPedido.format("HH:mm")} <br />
                        Cadete: {pedido.cadete ? (pedido.cadete.nombre + " " + pedido.cadete.apellido) : "-"}
                    </p>
                </div>
            );
        }
        default: {
            return (
                <div >
                    <EncabezadoItem pedido={pedido} />
                    <p style={{ width: "100%" }}>
                        {
                            pedido.direccion ?
                                !pedido.direccion.valida && <><b className={"red"}>DIRECCION NO VALIDADA</b><br /></>
                                : null
                        }
                        {pedido.negocio.nombre} - {pedido.direccion_entrega}
                        <br></br>
                        {horaPedido.format("HH:mm") +
                            " Entrega: " +
                            pedido.hora +
                            "\n"}
                        <br></br>
                        {pedido.tarjeta ? <b>Tarjeta ({pedido.pagado ? "PAGO" : "NO PAGO"})</b> : <b>Efectivo</b>}{" "}
                        Cadete: {pedido.cadete ? (pedido.cadete.nombre + " " + pedido.cadete.apellido) : "-"}
                    </p>
                </div>
            );
        }
    }
}

export default Text;
