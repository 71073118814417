import React from "react";
import { Button } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Row, Col } from "react-bootstrap";
import { get, post } from "../../api/axios";
import ModalCiudades from './ModalCiudades';
import { openAlert } from "simple-react-alert";
import { StoreContext } from '../../context/Store';
import ModalPedidoPersonalizado from './ModalPedidoPersonalizado';
import ModalEliminar from './ModalEliminar';

import "./style.css";

const estadosFaster = [
    'normal',
    'atraso',
    'demora'
]
const Disponibilidad = props => {
    const { state } = React.useContext(StoreContext);
    const [showModalDisp, setShowModalDisp] = React.useState(false);
    const [showModalPersonalizado, setShowModalPersonalizado] = React.useState(false);
    const [showModalPedido, setShowModalPedido] = React.useState(false);
    const [showModalEliminar, setShowModalEliminar] = React.useState(false);
    const [showModalStatus, setShowModalStatus] = React.useState(false);
    const [ciudades, setCiudades] = React.useState([]);
    const [ciudadId, setCiudadId] = React.useState(1);
    const [disponible, setDisponible] = React.useState(true);
    const [disponiblePersonalizado, setDisponiblePersonalizado] = React.useState(true);
    const [estadoBahia, setEstadoBahia] = React.useState(null);
    const [estadoMonte, setEstadoMonte] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    React.useEffect(() => {
        state.userInfo &&
            get('/status/ciudades').then(
                statusRes => {
                    setCiudades(statusRes.data.ciudades);
                    setStatus(statusRes.data.ciudades);
                });
    }, [state]);

    const postDisponibilidad = (disponible, detalle, tipo) => {
        setShowModalDisp(false);
        post("/faster/disponible", {
            ciudad_id: ciudadId,
            disponible: disponible,
            tipo: tipo,
            detalle
        }).then(() => {
            setDisponible(false);
            tipo === 'personalizados' && setShowModalPersonalizado(false);
            openAlert({ message: "Disponibilidad modificada", type: 'success' })
        });
    };

    const postEstado = (id, estado) => {

        post('/status', { status: estado, ciudad_id: id }).then(() => {
            let statusAux = status.map(ciudad => {
                if (ciudad.id === id) {
                    return { ...ciudad, estado: estado };
                }
                return ciudad;
            })
            setStatus(statusAux);
            openAlert({ message: 'Estado de Faster modificado', type: 'success' })
        })

    }

    return (
        <Row
    
            xs={{ span: 12, offset: 2 }}
            className="justify-content-md-around rowContainer"
        >
            <Col xs={4} md={4}>
                <Button
                    size="large"
                    variant="contained"
                    className="ml-2"
                    onClick={() => setShowModalDisp(true)}
                >
                    Disponibilidad de App
                    </Button>
                <Button
                    size="large"
                    variant="contained"
                    className="ml-2"
                    onClick={() => setShowModalPersonalizado(true)}
                >
                    Disponibilidad de Personalizados
                    </Button>
                <Button
                    size="large"
                    variant="contained"
                    className="ml-2"
                    onClick={() => setShowModalPedido(true)}
                >
                    Crear pedido personalizado
                    </Button>
                <Button
                    size="large"
                    variant="contained"
                    className="ml-2"
                    onClick={() => setShowModalEliminar(true)}
                >
                    Eliminar pedido
                    </Button>

            </Col>

            {status && status.map && status.filter(x => x.id ===2 ).map(ciudad => {
                return <Col xs={4} md={4}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Estado {ciudad.nombre}</FormLabel>
                        <RadioGroup value={ciudad.estado} onChange={(event) => postEstado(ciudad.id, event.target.value)}>
                            <FormControlLabel value="normal" control={<Radio />} label="Normal" />
                            <FormControlLabel value="atraso" control={<Radio />} label="Atraso" />
                            <FormControlLabel value="demora" control={<Radio />} label="Demora" />
                        </RadioGroup>
                    </FormControl>
                </Col>

            })
            }
            {
                showModalDisp && <ModalCiudades
                    cancelar={() => { }}
                    showModal={true}
                    tipo='negocios'
                    ciudades={ciudades}
                    setCiudadId={setCiudadId}
                    ciudadId={ciudadId}
                    setDisponible={setDisponible}
                    disponible={disponible}
                    setShowModal={setShowModalDisp}
                    postDisponible={postDisponibilidad}
                />
            }
            {
                showModalPersonalizado && <ModalCiudades
                    cancelar={() => { }}
                    showModal={showModalPersonalizado}
                    tipo='personalizados'
                    ciudades={ciudades}
                    setCiudadId={setCiudadId}
                    ciudadId={ciudadId}
                    setDisponible={setDisponiblePersonalizado}
                    disponible={disponiblePersonalizado}
                    setShowModal={setShowModalPersonalizado}
                    postDisponible={postDisponibilidad}
                />
            }
            {
                showModalPedido && <ModalPedidoPersonalizado
                    showModal={showModalPedido}
                    setShowModal={setShowModalPedido}
                    recargarPedidos={props.pedirPedidos}
                />
            }
            {
                showModalEliminar && <ModalEliminar
                    showModal={showModalEliminar}
                    setShowModal={setShowModalEliminar}
                />
            }
        </Row >
    );
};

export default Disponibilidad;
