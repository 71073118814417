import React, { useState, useEffect } from 'react'
import PanelPedidos from './PanelPedidos';
import PanelCadetesNegocio from '../betaNegocios/betaCadetes/PanelCadetesNegocio';
import { isNegocio } from '../../context/methods/isNegocio';
import { StoreContext } from '../../context/Store'

const PanelContainer = () => {
    const { state } = React.useContext(StoreContext);
    const [negocio, setNegocio] = useState(0);

    console.log(negocio);

    useEffect(() => {
        state.userInfo && setNegocio(isNegocio(state.userInfo));
    }, [state])

    return negocio === 0 ? <p>cargando</p> : (negocio ? <PanelCadetesNegocio /> : <PanelPedidos />)
}

export default PanelContainer;