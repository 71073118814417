import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Sidebar from "../sidebar/Sidebar";
import { withRouter } from "react-router";
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    nav: {
        backgroundColor: "black",
        color: "#ff4364"
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    }
}));

const ButtonAppBar = props => {
    const classes = useStyles();
    const [show, setShow] = React.useState(false);

    const showSidebar = () => {
        setShow(true);
    };
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.nav}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        onClick={() => showSidebar()}
                        aria-label="menu"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        style={{ cursor: "pointer" }}
                        className={classes.title}
                        onClick={() => props.history.push("/pedidos")}
                    >
                        Faster
                    </Typography>
                    {sessionStorage.getItem("fasterToken") === null ? (
                        <Button color="inherit" onClick={() => props.history.push("/login")}>
                            Login
                        </Button>
                    ) : (
                            <Button
                                color="inherit"
                                onClick={() => {
                                    sessionStorage.clear();
                                    props.history.push("/login");
                                }}
                            >
                                Logout
                            </Button>
                        )}
                </Toolbar>
            </AppBar>
            <Sidebar show={show} setShow={setShow} />
        </div>
    );
};

export default withRouter(ButtonAppBar);
