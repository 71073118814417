import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withRouter } from "react-router";
import "./style.css";
import {
    ValidatorForm,
    TextValidator,
    SelectValidator
} from "react-material-ui-form-validator";
import { get, postImage, del } from "../../api/axios";
import ImageUploader from "../../components/imageUploader/ImageUploader";
import { update, post } from "../../api/axios";
import Loader from "../../Loader";
import { openAlert } from "simple-react-alert";
const NuevoNegocio = props => {
    const [nombre, setNombre] = React.useState("");
    const [ubicacion, setUbicacion] = React.useState("");
    const [subcategoria, setSubcategoria] = React.useState(1);
    const [telefono, setTelefono] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [disponible, setDisponible] = React.useState(false);
    const [subcategorias, setSubcategorias] = React.useState([]);
    const [imagen, setImagen] = React.useState(null);
    const [latitud, setLatitud] = React.useState("");
    const [longitud, setLongitud] = React.useState("");
    const [descripcion, setDescripcion] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    const [envioPropio, setEnvioPropio] = React.useState(0);
    const [pedidoWhatsapp, setPedidoWhatsapp] = React.useState(0);
    const [precioBase, setPrecioBase] = React.useState(0);
    const [precioSuma, setPrecioSuma] = React.useState(0);
    const [kmsBase, setKmsBase] = React.useState(0);
    const [kmsSuma, setKmsSuma] = React.useState(0);
    const [planNuevo, setPlanNuevo] = React.useState(false);
    const [ciudades, setCiudades] = React.useState([]);
    const [ciudad, setCiudad] = React.useState();
    const [horarios, setHorarios] = React.useState([]);
    const [porcentajeEfectivo, setPorcentajeEfectivo] = React.useState(20);
    const [porcentajeTarjeta, setPorcentajeTarjeta] = React.useState(20);
    const [takeAway, setTakeAway] = useState(true)
    const [storedImage, setStoredImage] = useState()
    const [dias] = React.useState([
        "DOMINGO",
        "LUNES",
        "MARTES",
        "MIERCOLES",
        "JUEVES",
        "VIERNES",
        "SABADO"
    ]);
    useEffect(() => {
        get("/ciudades").then(response => {
            setCiudades(response.data);
        });
    }, []);

    useEffect(() => {
        ValidatorForm.addValidationRule("isHour", value => {
            if (
                !isNumber(value.charAt(0)) |
                !isNumber(value.charAt(1)) |
                (value.charAt(2) !== ":") |
                !isNumber(value.charAt(3)) |
                !isNumber(value.charAt(4)) |
                (value.charAt(5) !== ":") |
                !isNumber(value.charAt(6)) |
                !isNumber(value.charAt(7))
            ) {
                return false;
            }
            return true;
        });
        return () => {
            ValidatorForm.removeValidationRule("isHour");
        };
    });

    useEffect(() => {
        if (props.edit) {
            get("/negocios/" + props.id).then(response => {
                const negocio = response.data;
                setNombre(negocio.nombre);
                setUbicacion(negocio.ubicacion);
                setDisponible(negocio.disponible === 1);
                setLatitud(negocio.latitud);
                setLongitud(negocio.longitud);
                setEmail(negocio.email);
                setKmsBase(negocio.kms_envio_base || 0);
                setKmsSuma(negocio.kms_envio_suma || 0);
                setPrecioBase(negocio.precio_envio_base || 0);
                setPrecioSuma(negocio.precio_envio_suma || 0);
                setTelefono(negocio.telefono);
                setDescripcion(negocio.descripcion);
                setSubcategoria(negocio.subcategoria.id);
                setCiudad(negocio.ciudad.id);
                setPedidoWhatsapp(negocio.pedidos_whatsapp);
                setPlanNuevo(negocio.plan_nuevo === 1 ? true : false);
                setEnvioPropio(negocio.tarifa ? true : false);
                negocio.tarifa && setKmsBase(negocio.tarifa.kms_envio_base);
                negocio.tarifa && setKmsSuma(negocio.tarifa.kms_envio_suma);
                negocio.tarifa &&
                    setPrecioBase(negocio.tarifa.precio_envio_base);
                negocio.tarifa &&
                    setPrecioSuma(negocio.tarifa.precio_envio_suma);
                setHorarios(negocio.horarios);
                setPorcentajeEfectivo(negocio.porcentaje_efectivo);
                setPorcentajeTarjeta(negocio.porcentaje_tarjeta);
                setStoredImage(negocio.imagen)
                setTakeAway(negocio.take_away === 1 ? true : false)
            });
        }
        get("/subcategorias").then(response => setSubcategorias(response.data));
    }, [props.edit, props.id]);

    const isNumber = char => {
        return !isNaN(char);
    };
    const eliminar = () => {
        del("/negocios/", props.match.params.id).then(response => {
            openAlert({ message: "Eliminado", type: "success" });
        });
    };

    const eliminarCache = () => {
        post("/negocios/cache-imagenes", {
            negocio_id: props.match.params.id
        }).then(response => {
            openAlert({ message: "Cache eliminada", type: "success" });
        });
    };

    console.log(storedImage);

    const handleSubmit = () => {
        setLoader(true);
        let body = {
            nombre,
            ubicacion,
            telefono,
            subcategoria_id: subcategoria,
            disponible: disponible ? 1 : 0,
            latitud,
            longitud,
            descripcion,
            ciudad_id: ciudad,
            horarios,
            take_away: takeAway ? 1 : 0,
            plan_nuevo: planNuevo,
            pedidos_whatsapp: pedidoWhatsapp,
            porcentaje_efectivo: porcentajeEfectivo,
            porcentaje_tarjeta: porcentajeTarjeta,
            cadeteria_propia: envioPropio,
            tarifas: envioPropio
                ? {
                      kms_envio_base: kmsBase,
                      kms_envio_suma: kmsSuma,
                      precio_envio_base: precioBase,
                      precio_envio_suma: precioSuma
                  }
                : null
        };
        var formData = new FormData();
        formData.append("imagen", imagen);

        if (email.length > 0) {
            body = { ...body, email: email };
        }
        if (props.edit) {
            update("/negocios/" + props.match.params.id, body).then(
                response => {
                    imagen &&
                        postImage(
                            "/negocios/" + props.match.params.id + "/imagen",
                            formData
                        );
                    openAlert({ message: "Guardado exitoso", type: "success" });
                    setLoader(false);
                }
            );
        } else {
            post("/negocios", body).then(response => {
                if (response.data) {
                    imagen && postImage(
                        "/negocios/" + response.data.id + "/imagen",
                        formData
                    ).then(() => {
                        openAlert({
                            message: "Guardado exitoso",
                            type: "success"
                        });
                        setLoader(false);
                    });
                }
            });
        }
    };

    const renderHorarios = () => {
        let index = -1;
        return horarios.map((horario, key) => {
            let ind = ++index;
            let indexTurno = -1;
            return (
                <>
                    <p>{dias[ind]}</p>
                    <Row xs={{ span: 10, offset: 2 }} key={key}>
                        {horario.map(turno => {
                            ++indexTurno;
                            return (
                                <Row>
                                    <Col xs={3}>
                                        <TextValidator
                                            label="Abre"
                                            value={turno.abre}
                                            onChange={e => {
                                                setHorario(
                                                    e.target.value,
                                                    ind,
                                                    indexTurno,
                                                    "abre"
                                                );
                                            }}
                                            variant="filled"
                                            placeholder="hh:mm"
                                            validators={["isHour", "required"]}
                                            errorMessages={[
                                                "El formato hora debe ser hh:mm:ss",
                                                "Este campo es requerido"
                                            ]}
                                            className="textInput"
                                        />
                                    </Col>
                                    <Col xs={3}>
                                        <TextValidator
                                            label="Cierra"
                                            value={turno.cierra}
                                            onChange={e => {
                                                setHorario(
                                                    e.target.value,
                                                    ind,
                                                    indexTurno,
                                                    "cierra"
                                                );
                                            }}
                                            variant="filled"
                                            placeholder="hh:mm"
                                            validators={["isHour", "required"]}
                                            errorMessages={[
                                                "El formato hora debe ser hh:mm:ss",
                                                "Este campo es requerido"
                                            ]}
                                            className="textInput"
                                        />
                                    </Col>
                                    <Col xs={2}>
                                        <Button
                                            onClick={() =>
                                                eliminarHorario(ind, indexTurno)
                                            }
                                            className="MuiButton-containedPrimary"
                                        >
                                            Eliminar
                                        </Button>
                                    </Col>
                                </Row>
                            );
                        })}

                        <Col xs={2}>
                            <Button
                                onClick={() => agregarHorario(ind)}
                                color="primary"
                            >
                                + Agregar Horario
                            </Button>
                        </Col>
                    </Row>
                </>
            );
        });
    };

    const setHorario = (value, index, indexTurno, target) => {
        let horariosTemp = [...horarios];
        horariosTemp[index][indexTurno][target] = value;
        setHorarios(horariosTemp);
    };
    const agregarHorario = index => {
        let horariosTemp = [...horarios];
        horariosTemp[index].push({
            abre: "",
            cierra: ""
        });
        setHorarios(horariosTemp);
    };

    const eliminarHorario = (index, indexTurno) => {
        let horariosTemp = [...horarios];
        horariosTemp[index].splice(indexTurno, 1);
        setHorarios(horariosTemp);
    };

    const renderEnvio = () => {
        return (
            <>
                <Col xs={6} className="mb-2">
                    <TextValidator
                        label="Kms base"
                        value={kmsBase}
                        onChange={e => setKmsBase(e.target.value)}
                        validators={["required"]}
                        variant="filled"
                        className="textInput"
                        errorMessages={["Este campo es requerido"]}
                    />
                </Col>
                <Col xs={6} className="mb-2">
                    <TextValidator
                        label="Kms Suma"
                        value={kmsSuma}
                        onChange={e => setKmsSuma(e.target.value)}
                        validators={["required"]}
                        variant="filled"
                        className="textInput"
                        errorMessages={["Este campo es requerido"]}
                    />
                </Col>
                <Col xs={6} className="mb-2">
                    <TextValidator
                        label="Precio base"
                        value={precioBase}
                        onChange={e => setPrecioBase(e.target.value)}
                        validators={["required"]}
                        variant="filled"
                        className="textInput"
                        errorMessages={["Este campo es requerido"]}
                    />
                </Col>{" "}
                <Col xs={6} className="mb-2">
                    <TextValidator
                        label="Precio suma"
                        value={precioSuma}
                        onChange={e => setPrecioSuma(e.target.value)}
                        validators={["required"]}
                        variant="filled"
                        className="textInput"
                        errorMessages={["Este campo es requerido"]}
                    />
                </Col>
            </>
        );
    };
    return (
        <Container className="container-tournament-form">
            <ValidatorForm onSubmit={handleSubmit}>
                <Row>
                    <Col xs={{ span: 8, offset: 2 }}>
                        <Row>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Nombre del negocio"
                                    value={nombre}
                                    onChange={e => setNombre(e.target.value)}
                                    validators={[
                                        "required",
                                        "minStringLength:3"
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={[
                                        "Este campo es requerido",
                                        "Por lo menos ingresa 3 caracteres"
                                    ]}
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Ubicacion"
                                    value={ubicacion}
                                    onChange={e => setUbicacion(e.target.value)}
                                    validators={["required"]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={["Este campo es requerido"]}
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Porcentaje Efectivo"
                                    value={porcentajeEfectivo}
                                    onChange={e =>
                                        setPorcentajeEfectivo(e.target.value)
                                    }
                                    validators={["required"]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={["Este campo es requerido"]}
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Porcentaje Tarjeta"
                                    value={porcentajeTarjeta}
                                    onChange={e =>
                                        setPorcentajeTarjeta(e.target.value)
                                    }
                                    validators={["required"]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={["Este campo es requerido"]}
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Latitud"
                                    value={latitud}
                                    onChange={e => setLatitud(e.target.value)}
                                    validators={["required"]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={["Este campo es requerido"]}
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Longitud"
                                    value={longitud}
                                    onChange={e => setLongitud(e.target.value)}
                                    validators={["required"]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={["Este campo es requerido"]}
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="filled"
                                    className="tipoInput"
                                >
                                    <SelectValidator
                                        label="Subcategoria del negocio"
                                        value={subcategoria}
                                        onChange={e =>
                                            setSubcategoria(e.target.value)
                                        }
                                        validators={["required"]}
                                        variant="filled"
                                        errorMessages={[
                                            "Este campo es requerido"
                                        ]}
                                    >
                                        {subcategorias.map(
                                            (subcategoria, key) => {
                                                return (
                                                    <MenuItem
                                                        value={subcategoria.id}
                                                        key={key}
                                                    >
                                                        {subcategoria.nombre}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </SelectValidator>
                                </FormControl>
                            </Col>
                            <Col xs={6} className="mb-2">
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="filled"
                                    className="tipoInput"
                                >
                                    <SelectValidator
                                        label="Ciudad del negocio"
                                        value={ciudad}
                                        onChange={e =>
                                            setCiudad(e.target.value)
                                        }
                                        validators={["required"]}
                                        variant="filled"
                                        errorMessages={[
                                            "Este campo es requerido"
                                        ]}
                                    >
                                        {ciudades.map((ciudad, key) => {
                                            return (
                                                <MenuItem
                                                    value={ciudad.id}
                                                    key={key}
                                                >
                                                    {ciudad.nombre}
                                                </MenuItem>
                                            );
                                        })}
                                    </SelectValidator>
                                </FormControl>
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="telefono"
                                    value={telefono}
                                    onChange={e => setTelefono(e.target.value)}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="email de contacto (opcional)"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    validators={["isEmail"]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={[
                                        "Debes ingresar un email valido"
                                    ]}
                                />
                            </Col>
                            <Col xs={6} md={4} className="mb-2 checkbox">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={disponible}
                                            onChange={e =>
                                                setDisponible(!disponible)
                                            }
                                            value="Disponible"
                                        />
                                    }
                                    label="Esta disponible al publico?"
                                />
                            </Col>
                            <Col xs={6}  md={4} className="mb-2 checkbox">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={planNuevo ? false : true}
                                            onChange={e =>
                                                setPlanNuevo(!planNuevo)
                                            }
                                            value="Disponible"
                                        />
                                    }
                                    label="Envio Faster?"
                                />
                            </Col>
                            <Col xs={6}  md={4} className="mb-2 checkbox">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={takeAway}
                                            onChange={e =>
                                                setTakeAway(!takeAway)
                                            }
                                            value="takeAway"
                                        />
                                    }
                                    label="Acepta Take Away?"
                                />
                            </Col>
                            <Col xs={6} md={4} className="mb-2 checkbox">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={pedidoWhatsapp}
                                            onChange={e =>
                                                setPedidoWhatsapp(
                                                    !pedidoWhatsapp
                                                )
                                            }
                                            value="Disponible"
                                        />
                                    }
                                    label="Pedido por whatsapp?"
                                />
                            </Col>
                            <Col xs={6} md={4} className="mb-2 checkbox">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={envioPropio}
                                            onChange={e =>
                                                setEnvioPropio(!envioPropio)
                                            }
                                            value="Disponible"
                                        />
                                    }
                                    label="Envio propio?"
                                />
                            </Col>
                            {!!envioPropio && renderEnvio()}
                            <Col xs={12} className="mb-2">
                                <TextValidator
                                    label="Descripcion"
                                    value={descripcion}
                                    onChange={e =>
                                        setDescripcion(e.target.value)
                                    }
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={12} className="mb-2 mt-4">
                                    <Row>
                                        <Col xs={6}>
                                <ImageUploader
                                    setImagen={setImagen}
                                    imagen={imagen}
                                />
                                </Col>
                                <Col xs={6}>
                                {imagen || storedImage && <img className="ml-4" height={170} width={350} src={imagen ? URL.createObjectURL(imagen) : process.env.REACT_APP_API_URL + storedImage}/>}
                                </Col>
                                </Row>
                            </Col>
                            <Col xs={12}>
                                <p>HORARIOS</p>
                            </Col>
                            <Col xs={12} className="mb-2">
                                {renderHorarios()}
                            </Col>
                            <Col xs={12}>
                                <div className="form-card-header">
                                    <div className="buttons-header">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            onClick={() =>
                                                props.history.goBack()
                                            }
                                        >
                                            Volver
                                        </Button>
                                        {loader ? (
                                            <Loader />
                                        ) : (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                                className="ml-2"
                                            >
                                                Guardar
                                            </Button>
                                        )}
                                    </div>
                                </div>{" "}
                            </Col>
                            <Col xs={6}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={() => eliminar()}
                                    className="ml-2"
                                >
                                    Eliminar negocio
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={() => eliminarCache()}
                                    className="ml-2"
                                >
                                    Eliminar cache del negocio
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ValidatorForm>
        </Container>
    );
};

export default withRouter(NuevoNegocio);
