import React, { useContext } from "react";
import { get } from "../../../api/axios";
import TablaPedidos from "../../../components/tablaPedidos/TablaPedidos";
import { StoreContext as UserContext } from '../../../context/Store';
import "./style.css";
import moment from 'moment';
import { TextField, Grid, InputAdornment, IconButton, Input, InputLabel, Button } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { openAlert } from "simple-react-alert";

const HistorialNegocio = () => {
    const userContext = useContext(UserContext)
    const [pedidos, setPedidos] = React.useState([]);
    const [finalizados, setFinalizados] = React.useState(0);
    const [totProductosFin, setTotProductosFin] = React.useState(0);
    const [totEnviosFin, setTotEnviosFin] = React.useState(0);
    const [totalFin, setTotalFin] = React.useState(0);
    const [cancelados, setCancelados] = React.useState(0);
    const [totDescuentos, setTotDescuentos] = React.useState(0);
    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        if (moment().hour() < 4 && moment().hour() > 0) {
            pedirPedidos(moment().subtract(1, 'days').format("YYYY-MM-DD"));
        }
        else {
            pedirPedidos(moment().format("YYYY-MM-DD"));
        }
    }, []);


    const searchPedido = () => {
        get('/pedidos/' + search).then(response => {
            if (userContext.state.userInfo.negocios.find(negocio => negocio.id == response.data.negocio_id)) {
                setPedidos([response.data])
            }
            else {
                openAlert({
                    message: "Este pedido no pertenece a tu negocio",
                    type: "info",
                    duration: 10000
                })
            }
        })
    }
    const pedirPedidos = date => {
        let totalProductos = 0;
        let totalEnvios = 0;
        let totalDescuentos = 0;
        let total = 0;
        let finalizados = [];
        let cancelados = [];
        let otros = [];

        get("/admin-negocios/pedidos?fecha=" + date).then(response => {
            response.data.filter(pedido => {
                switch (pedido.estado) {
                    case "Finalizado": {
                        totalProductos += pedido.total;
                        totalEnvios += pedido.precio_envio;
                        totalDescuentos += pedido.descuento;
                        total +=
                            pedido.total +
                            pedido.precio_envio -
                            pedido.descuento;
                        finalizados.push(pedido);
                        break;
                    }
                    case "Cancelado": {
                        cancelados.push(pedido);
                        break;
                    }
                    default: {
                        otros.push(pedido);
                    }
                }
            });
            setCancelados(cancelados.length);
            setFinalizados(finalizados.length);
            setTotEnviosFin(totalEnvios);
            setTotProductosFin(totalProductos);
            setTotDescuentos(totalDescuentos);
            setTotalFin(total);
            let pedidos = [...finalizados, ...otros, ...cancelados];
            setPedidos(pedidos);
        });
    };
    return (
        <Grid container justify='center' >
            <Grid item xs={6}>
                <form noValidate className="datePicker">
                    <TextField
                        id="date"
                        label="Selecciona el dia"
                        type="date"
                        defaultValue={moment().format("YYYY-MM-DD")}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={e => {
                            pedirPedidos(e.target.value);
                        }}
                    />
                </form>
            </Grid>
            <Grid item xs={6} style={{ marginTop: '69px' }}>
                <InputLabel>Buscar por ID</InputLabel>
                <Input label='buscar por id' onChange={(e) => setSearch(e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="buscar pedido"
                                onClick={searchPedido}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    } />
            </Grid>
            <Grid item xs={12}>
                <div className="estadisticas">
                    <p>Total pedidos finalizados: {finalizados} </p>
                    <p>Total ingreso productos: {totProductosFin} </p>
                    <p>Total ingreso envios: {totEnviosFin} </p>
                    <p>Total de descuentos: {totDescuentos} </p>
                    <p>Total ingresos: {totalFin}</p>
                </div>
            </Grid>
            <TablaPedidos pedidos={pedidos} />
        </Grid>
    );
};

export default HistorialNegocio;
