import React, { useState } from "react";
import { Modal, Input, Select, Grid, Typography, TextField } from "@material-ui/core";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { FormControl, MenuItem } from "@material-ui/core";
import { CustomButton } from '../../components/button/CustomButton';
import { get, post } from '../../api/axios';
import './style.css';
import renderDetalles from './utils/renderDetalles';
import { openAlert } from "simple-react-alert";


const ModalEliminar = props => {
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pedido, setPedido] = useState({});

    const fetchPedido = () => {
        setLoading(true);
        get(`/pedidos/${id}`).then(response => {
            setPedido(response.data);
            setLoading(false);
        })

    }

    const eliminarPedido = () => {
        setLoading(true);
        post(`/pedidos/${id}/eliminar`).then(response => {
            setLoading(false);
            props.setShowModal(false);
            openAlert({ message: "Eliminado exitoso", type: "success" });

        })
    }
    return (
        <Modal
            open={props.showModal}
            onClose={() => props.setShowModal(false)}
            className="modalContainer"
        >
            <div className="modalCiudades" style={{ minHeight: '31rem', width: '44rem' }}
            >
                <Grid container justify='space-between' alignItems='center' direction='column'>
                    <Grid item lg xs>
                        <Typography >
                            Ingresa id del pedido
                    </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: '2rem' }}>
                        <Input
                            type="string"
                            value={id}
                            placeholder='ingresa un id'
                            onChange={e => setId(e.target.value)}
                        />
                    </Grid>
                    {!loading && pedido.id && renderDetalles(pedido)}
                    <CustomButton
                        onClick={pedido.id ? eliminarPedido : fetchPedido}
                        label={pedido.id ? 'Eliminar' : 'Verificar pedido'}
                        isLoading={loading}

                    >
                    </CustomButton>
                </Grid>
            </div>
        </Modal >
    );
};

export default ModalEliminar;
