import React, { useEffect } from "react";
import { Button, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { openAlert } from "simple-react-alert";
import { get } from "../../api/axios";
import EditIcon from "@material-ui/icons/Edit";

const CuponesList = props => {
  const [cupones, setCupones] = React.useState([]);

  useEffect(() => {
    get("/cupones").then(response => {
      setCupones(response.data);
    });
  }, []);

  const renderCupones = () => {
    return (
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{ maxHeight: "400px", overflow: "auto" }}
      >
        {cupones.map((cupon, key) => {
          return (
            <ListItem
              button
              onClick={() => props.history.push("/cupones/editar/" + cupon.id)}
              key={key}
            >
              <ListItemText primary={cupon.nombre} />
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    )
  }

  return (
    <Row xs={{ span: 12, offset: 2 }} className="justify-content-md-center rowContainer">
      <Col xs={8}>
        <Button
          size="large"
          variant="contained"
          className="ml-2"
          onClick={() => props.history.push("/cupones/nuevo")}
        >
          Agregar nuevo cupon
        </Button>
        {renderCupones()}
        {/* <ProductosList productos={productos} precios={precios} setPrecios={setPrecios} updatePrecio={updatePrecio}/> */}
      </Col>
    </Row>
  );
};

export default withRouter(CuponesList);
