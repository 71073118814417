import React from 'react'

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BusinessIcon from "@material-ui/icons/Store";
import BikeIcon from "@material-ui/icons/Motorcycle";
import UserIcon from "@material-ui/icons/People";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HistorialIcon from "@material-ui/icons/History";
import BannerIcon from '@material-ui/icons/Tv'
import CouponIcon from "@material-ui/icons/CardGiftcard";
import CiudadesIcon from '@material-ui/icons/LocationCity'
import EstadisticasIcon from "@material-ui/icons/Assessment";
import OffIcon from '@material-ui/icons/LocalOffer'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NotificacionesIcon from "@material-ui/icons/Notifications";
import Collapse from '@material-ui/core/Collapse';
import { withRouter } from "react-router-dom";
import './sidebar-style.css';
const NegocioList = ({ toggleDrawer, setOpenAdministrar, setOpenUsuarios, openAdministrar, openUsuarios, ...props }) => (
    <div
        style={{ width: 250 }}
        role="presentation"
    >
        <List>
            <ListItem button onClick={() => { toggleDrawer(); props.history.push("/pedidos") }}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Pedidos"} />
            </ListItem>
            <ListItem button onClick={() => { toggleDrawer(); props.history.push("/panel/adminnegocio") }}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Panel Cadetes"} />
            </ListItem>
            <ListItem
                button
                onClick={() => setOpenAdministrar(!openAdministrar)}
            >
                <ListItemIcon>
                    <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary={"Administraciones"} />
                {openAdministrar ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openAdministrar} timeout="auto" className='colapsable' unmountOnExit>
                <ListItem button onClick={() => { toggleDrawer(); props.history.push("/cadetes") }}>
                    <ListItemIcon>
                        <BikeIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Cadetes"} />
                </ListItem>
                <ListItem button onClick={() => { toggleDrawer(); props.history.push("/historial/adminnegocio") }}>
                    <ListItemIcon>
                        <BikeIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Historial de pedidos"} />
                </ListItem>
                <ListItem button onClick={() => { toggleDrawer(); props.history.push("/envios/adminnegocio") }}>
                    <ListItemIcon>
                        <BikeIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Precios de envio"} />
                </ListItem>
            </Collapse>
        </List>
    </div>
);

export default withRouter(NegocioList);