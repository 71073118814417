import React from "react";
import "./style.css";
import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { renderDetalle } from "../pedidos/utils";
import ItemWrapper from "../pedidos/card/ItemWrapper";
import EncabezadoItem from '../pedidos/EncabezadoItem';
import ForwardIcon from "@material-ui/icons/Forward";
import ModalPedidoNegocio from '../pedidos/ModalPedidoNegocio';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';

import moment from 'moment';
import './style.css'
const useStyles = makeStyles(theme => ({
    paper: {
        marginLeft: "34%",
        marginTop: "10%",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4)
    }
}));
const Item = props => {
    const classes = useStyles();

    const [showModal, setShowModal] = React.useState(false);

    let computedClassName =
        props.pedido.estado === "En Camino" ? "EnCamino" : props.pedido.estado;

    return (
        <div className={computedClassName} style={{ height: "auto", fontSize: "large" }}>
            {props.pedido.user.plataforma === 'android' ?
                <AndroidIcon onClick={() => setShowModal(!showModal)} />
                :
                <AppleIcon onClick={() => setShowModal(!showModal)} />}
            {`  #${props.pedido.id} | ${moment(props.pedido.updated_at).format("HH:mm")}`}
            {props.avanzar && <Button size="small" onClick={() => props.avanzar(props.pedido)}>
                <ForwardIcon />
            </Button>}
            <ModalPedidoNegocio
                pedido={props.pedido}
                showModal={showModal}
                setShowModal={setShowModal}
                classes={classes}
            />
        </div>
    );
};

export default Item;
