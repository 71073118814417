import React from "react";
import "./style.css";
import ModalPedido from "./ModalPedido";
import { makeStyles } from "@material-ui/core";
import { renderDetalle } from "./utils";
import ItemWrapper from "./card/ItemWrapper";

const useStyles = makeStyles(theme => ({
    paper: {
        marginLeft: "34%",
        marginTop: "10%",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4)
    }
}));
const ItemCompletado = props => {
    const classes = useStyles();

    const [showModal, setShowModal] = React.useState(false);

    let computedClassName =
        props.pedido.estado === "Cancelado" ? "itemCancelado" : "itemCompletado";
    return (
        <ItemWrapper className={computedClassName} pedido={props.pedido}>
            {renderDetalle(props.pedido, setShowModal, showModal)}
        </ItemWrapper>
    );
};

export default ItemCompletado;
