import React, { useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withRouter } from "react-router";
import "./style.css";
import {
    ValidatorForm,
    TextValidator,
    SelectValidator
} from "react-material-ui-form-validator";
import { get, postImage, del } from "../../api/axios";
import ImageUploader from "../../components/imageUploader/ImageUploader";
import { update, post } from "../../api/axios";
import Loader from "../../Loader";
import { openAlert } from "simple-react-alert";
const NuevaCadeteria = props => {
    const [nombre, setNombre] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [emailCadeteria, setEmailCadeteria] = React.useState("");
    const [disponible, setDisponible] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [ciudades, setCiudades] = React.useState([]);
    const [ciudad, setCiudad] = React.useState(0);

    useEffect(() => {
        get("/ciudades").then(response => {
            setCiudades(response.data);
        });
    }, []);

    useEffect(() => {
        ValidatorForm.addValidationRule("isHour", value => {
            if (
                !isNumber(value.charAt(0)) |
                !isNumber(value.charAt(1)) |
                (value.charAt(2) !== ":") |
                !isNumber(value.charAt(3)) |
                !isNumber(value.charAt(4)) |
                (value.charAt(5) !== ":") |
                !isNumber(value.charAt(6)) |
                !isNumber(value.charAt(7))
            ) {
                return false;
            }
            return true;
        });
        return () => {
            ValidatorForm.removeValidationRule("isHour");
        };
    });

    useEffect(() => {
        if (props.edit) {
            get("/cadeterias/" + props.id).then(response => {
                const cadeteria = response.data;
                setNombre(cadeteria.nombre);
                setDisponible(cadeteria.disponible === 1);
                setEmail(cadeteria.user.email);
                setEmailCadeteria(cadeteria.email_cadete)
                setTelefono(cadeteria.telefono);
                setCiudad(cadeteria.ciudad_id);
            });

        }
    }, [props.edit, props.id]);

    useEffect(() => {
        if (ciudad) {
            setCiudad(ciudades.find(x => x.id === ciudad))
        }
    }, [ciudades])

    const isNumber = char => {
        return !isNaN(char);
    };
    const eliminar = () => {
        del("/cadeterias/", props.match.params.id).then(response => {
            openAlert({ message: "Eliminado", type: "success" });
        });
    };

    const handleSubmit = () => {
        setLoader(true);
        let body = {
            nombre,
            telefono,
            password: password,
            disponible: disponible ? 1 : 0,
            ciudad_id: ciudad,
            email_cadete: emailCadeteria
        };
        var formData = new FormData();
        if (email.length > 0) {
            body = { ...body, email: email };
        }
        if (props.edit) {
            update("/cadeterias/" + props.match.params.id, body).then(
                response => {
                    openAlert({ message: "Guardado exitoso", type: "success" });
                    setLoader(false);
                }
            ).catch(() => {
                openAlert({ message: "Ocurrio un error", type: "error" });
                setLoader(false);
            });
        } else {
            post("/cadeterias", body).then(response => {
                if (response.data) {
                    openAlert({
                        message: "Guardado exitoso",
                        type: "success"
                    });
                    setLoader(false);
                };
            }).catch(() => {
                openAlert({ message: "Ocurrio un error", type: "error" });
                setLoader(false);
            });
        };
    };

    return (
        <Container className="container-tournament-form">
            <ValidatorForm onSubmit={handleSubmit}>
                <Row>
                    <Col xs={{ span: 8, offset: 2 }}>
                        <Row>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Nombre"
                                    value={nombre}
                                    onChange={e => setNombre(e.target.value)}
                                    validators={[
                                        "required",
                                        "minStringLength:3"
                                    ]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={[
                                        "Este campo es requerido",
                                        "Por lo menos ingresa 3 caracteres"
                                    ]}
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    validators={["required"]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={["Este campo es requerido"]}
                                />
                            </Col>

                            <Col xs={6} className="mb-2">
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="filled"
                                    className="tipoInput"
                                >
                                    <SelectValidator
                                        label="Ciudad del negocio"
                                        value={ciudad}
                                        onChange={e =>
                                            setCiudad(e.target.value)
                                        }
                                        validators={["required"]}
                                        variant="filled"
                                        errorMessages={[
                                            "Este campo es requerido"
                                        ]}
                                    >
                                        {ciudades.map((ciudad, key) => {
                                            return (
                                                <MenuItem
                                                    value={ciudad.id}
                                                    key={key}
                                                >
                                                    {ciudad.nombre}
                                                </MenuItem>
                                            );
                                        })}
                                    </SelectValidator>
                                </FormControl>
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="telefono"
                                    value={telefono}
                                    onChange={e => setTelefono(e.target.value)}
                                    variant="filled"
                                    className="textInput"
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="email para dashboard"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    validators={["isEmail", "required"]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={[
                                        "Parece que eso no es un email",
                                        "Campo requerido"
                                    ]}
                                />
                            </Col>
                            <Col xs={6} className="mb-2">
                                <TextValidator
                                    label="email para app"
                                    value={emailCadeteria}
                                    onChange={e => setEmailCadeteria(e.target.value)}
                                    validators={["isEmail", "required"]}
                                    variant="filled"
                                    className="textInput"
                                    errorMessages={[
                                        "Parece que eso no es un email",
                                        "campo requerido"
                                    ]}
                                />
                            </Col>
                            <Col xs={6} className="mb-2 checkbox">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={disponible}
                                            onChange={e =>
                                                setDisponible(!disponible)
                                            }
                                            value="Disponible"
                                        />
                                    }
                                    label="Esta disponible al publico?"
                                />
                            </Col>
                            <Col xs={12}>
                                <div className="form-card-header">
                                    <div className="buttons-header">
                                        <Button
                                            size="large"
                                            variant="contained"
                                            onClick={() =>
                                                props.history.goBack()
                                            }
                                        >
                                            Volver
                                        </Button>
                                        {loader ? (
                                            <Loader />
                                        ) : (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                                className="ml-2"
                                            >
                                                Guardar
                                            </Button>
                                        )}
                                    </div>
                                </div>{" "}
                            </Col>
                            <Col xs={6}>
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={() => eliminar()}
                                    className="ml-2"
                                >
                                    Eliminar cadeteria
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ValidatorForm>
        </Container>
    );
};

export default withRouter(NuevaCadeteria);
