import React from "react";
import { Button, Typography } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { get } from "../../api/axios";
import { List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import "./style.css";
const EditarNegocio = props => {
    const [cadetes, setCadetes] = React.useState([]);
    React.useEffect(() => {
        get("/cadetes").then(response => {
            setCadetes(response.data)
        });
    }, []);

    return (
        <>
            <Row
                xs={{ span: 12, offset: 2 }}
                className="justify-content-md-center rowContainer"
            >
                <Col xs={8}>
                    <Button
                        size="large"
                        variant="contained"
                        className="ml-2"
                        onClick={() =>
                            props.history.push(props.match.url + "/nuevo")
                        }
                    >
                        Agregar Cadete
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        className="ml-2"
                        onClick={() =>
                            props.history.push(props.match.url + "/disponibles")
                        }
                    >
                        Cadetes disponibles
                    </Button>
                </Col>
                {cadetes && cadetes.map && cadetes.map(ciudad => {
                    return <Col xs={8}>
                        <Typography style={{ fontSize: '23px', fontWeight: 'bold' }}>{ciudad.ciudad.nombre}</Typography>
                        <List
                            component="nav"
                            aria-label="main mailbox folders"
                            style={{ maxHeight: "400px", overflow: "auto" }}
                        >
                            {ciudad.cadetes.map((cadete, key) => {
                                return (
                                    <ListItem
                                        button
                                        onClick={() => {
                                            props.history.push({
                                                pathname:
                                                    props.match.url +
                                                    "/editar/" +
                                                    cadete.id
                                            });
                                        }}
                                        key={key}
                                    >
                                        <ListItemText primary={cadete.nombre + ' ' + cadete.apellido} />
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                    </ListItem>

                                );
                            })}
                        </List>
                    </Col>
                })}
            </Row>
        </>
    );
};

export default withRouter(EditarNegocio);
