import React from "react";
import Table from "@material-ui/core/Table";
import { makeStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import "./style.css";
import { get, post } from "../../api/axios";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import { openAlert } from "simple-react-alert";
import ModalUsuarios from "./ModalUsuarios";
import ModalCupones from "./ModalCupones";
import Loader from '../../Loader';

const useStyles = makeStyles(theme => ({
    paper: {
        marginLeft: "34%",
        marginTop: "10%",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4)
    }
}));
const TablaUsuarios = () => {
    const classes = useStyles();
    const [users, setUsers] = React.useState([]);
    const [filteredUser, setFilteredUser] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [user, setUser] = React.useState();
    const [openCupon, setOpenCupon] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);
    const [text, setText] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };


    React.useEffect(() => {
        reloadUsers();
    }, []);

    const reloadUsers = () => {
        get(`/users/list?page=${page}&size=150`).then(response => {
            console.log(response.data)
            setUsers(response.data);
            setFilteredUser(response.data);
        });
    };

    const desvalidar = (user) => {
        console.log(user);
        post(`/users/${user.id}/suspender`).then(response => {
            openAlert({ message: "Guardado exitoso", type: "success" })
        }).catch(openAlert({ message: "Ocurrio un error, corra por su vida", type: "error" }));
    }

    const filter = () => {
        setLoader(true);
        get(`/users/search?text=${text}`).then(response => {
            setLoader(false);
            setUsers(response.data);
            setFilteredUser(response.data);
        });
    };

    const abrirModal = (user, edit) => {
        setUser(user);
        setEdit(edit);
        setOpen(true);
    };

    const abrirModalCupon = user => {
        setUser(user);
        setOpenCupon(true);
    };

    return (
        <>
            <Paper className="container">
                <TextField
                    value={text}
                    onChange={e => setText(e.target.value)}
                    placeholder={"Filtra por id o nombre"}
                    className="filter"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon style={{ cursor: 'pointer' }} onClick={() => filter()} />
                            </InputAdornment>
                        )
                    }}
                />
                {loader && <Loader />}
                <Table className="tablaUsuarios">
                    <TableHead className="header">
                        <TableRow>
                            <TableCell className="header" align="center">
                                Nombre de usuario
                            </TableCell>
                            <TableCell className="header" align="center">
                                Id de usuario
                            </TableCell>
                            <TableCell className="header" align="center">
                                Telefono
                            </TableCell>
                            <TableCell className="header" align="center">
                                Verificado
                            </TableCell>
                            <TableCell className="header" align="center">
                                Asignar cupones
                            </TableCell>
                            <TableCell className="header" align="center">
                                Desvalidar
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUser.length > 0 && filteredUser
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map(user => (
                                <TableRow key={user.id}>
                                    <TableCell
                                        align="center"
                                        component="th"
                                        scope="row"
                                    >
                                        {user.nombre + " " + user.apellido}
                                    </TableCell>
                                    <TableCell align="center">
                                        {user.id}
                                    </TableCell>
                                    <TableCell align="center">
                                        {user.telefono || "No tiene"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {user.verificado ? (
                                            <Button
                                                variant="outlined"
                                                className="btnCambiar"
                                                onClick={() =>
                                                    abrirModal(user, true)
                                                }
                                            >
                                                Cambiar numero
                                            </Button>
                                        ) : (
                                                <Button
                                                    variant="outlined"
                                                    className="btnVerificar"
                                                    onClick={() =>
                                                        abrirModal(user, false)
                                                    }
                                                >
                                                    Verificar
                                                </Button>
                                            )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button
                                            variant="outlined"
                                            className="btnCupon"
                                            onClick={() =>
                                                abrirModalCupon(user)
                                            }
                                        >
                                            Asignar Cupon
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button
                                            variant="outlined"
                                            className="btnCupon"
                                            onClick={() => desvalidar(user)}
                                        >
                                            Desvalidar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[30]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <ModalUsuarios
                classes={classes}
                open={open}
                setShowModal={setOpen}
                reloadUsers={reloadUsers}
                user={user}
                edit={edit}
            />
            <ModalCupones
                open={openCupon}
                setShowModal={setOpenCupon}
                user={user}
            />
        </>
    );
};

export default TablaUsuarios;
