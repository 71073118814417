import React, { useState, useEffect } from 'react'
import EditarNegocio from './Opciones';
import NegocioOpciones from '../betaNegocios/betaAdministrarCadetes/NegocioOpciones';
import { isNegocio } from '../../context/methods/isNegocio';
import { StoreContext } from '../../context/Store'
import { isCadeteria } from '../../context/methods/isCadeteria';
import CadeteriaOpciones from '../betaCadeterias/betaAdministrarCadetes/CadeteriaOpciones';

const CadetesContainer = () => {
    const { state } = React.useContext(StoreContext);
    const [negocio, setNegocio] = useState(0);
    const [cadeteria, setCadeteria] = useState(0);

    useEffect(() => {
        state.userInfo && setNegocio(isNegocio(state.userInfo));
        state.userInfo && setCadeteria(isCadeteria(state.userInfo));

    }, [state])

    return negocio === 0 ? <p>cargando</p> : (negocio ? <NegocioOpciones /> : (cadeteria ? <CadeteriaOpciones /> : <EditarNegocio />))
}

export default CadetesContainer;