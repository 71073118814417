import { constants } from "../keys";

export function saveLoginInfo(token, userInfo, dispatch) {
  sessionStorage.setItem(constants.userInfo, JSON.stringify(userInfo));
  const action = {
    type: "LOGIN",
    token: token,
    userInfo: userInfo
  };
  dispatch(action);
}
