import React, { useContext, useEffect } from "react";
import { Modal, Input, Button } from "@material-ui/core";
import { StoreContext } from './pedidosContext/Store'
import { openAlert } from "simple-react-alert";

const ModalCancelar = props => {
    const [razon, setRazon] = React.useState("");

    const { state, dispatch } = useContext(StoreContext);

    useEffect(() => {
        dispatch({
            type: 'MODIFYING',
            dirty: 1
        });

        return () => dispatch({
            type: 'MODIFYING',
            dirty: 0
        });
    }, [])

    const cancelar = () => {
        if (razon !== "") {
            props.cancelar(razon);
        }
        else {
            openAlert({ message: "debes ingresar una razon", type: 'warning' })
        }
    };
    const close = () => {
        props.setShowModal(!props.showModal);
    };
    return (
        <Modal
            open={props.showModal}
            onClose={() => close()}
            className="modalContainer"
        >
            <div className="modalBody">
                <p>Ingresa la razon:</p>
                <Input
                    type="string"
                    value={razon}
                    onChange={e => setRazon(e.target.value)}
                ></Input>
                <Button
                    variant="outlined"
                    className="btnVerificar"
                    onClick={() => cancelar()}
                >
                    Aceptar
                </Button>
            </div>
        </Modal>
    );
};

export default ModalCancelar;
