import React from "react";
import "./style.css";
import moment from 'moment';
import { Chart } from "react-google-charts";

const GraficosUsuarios = ( {data} ) => {

    const [estadisticas, setEstadisticas] = React.useState([]);
    const [totalUsuarios,setTotalUsuarios] = React.useState(0);
    const [totalVerificados,setTotalVerificados] = React.useState(0);

    const [isLoading, setLoading] = React.useState(true);

    React.useEffect(() => {
        generarEstadisticas(data);
    }, []);

    const generarEstadisticas = (estadisticas_data) => {

        let estadisticasGen = {};

        ///////////////////////////////////
        // Nuevos usuarios verificados x dia
        ///////////////////////////////////

        let usuariosVerificados = [
            ['Usuarios','Total', 'Verificados', "Hicieron Pedido"]
        ];

        let usuariosVerificadosPie = [
            ['Nuevos','Verificados']
        ];

        let usuariosVerificadosConPedidosPie = [
            ['Verficados','Verificados con Pedidos']
        ];

        let cantidadUsuariosConPedidos = 0;
        let cantidadUsuariosVerificados = 0;
        let cantidadUsuariosNuevos = 0;

        estadisticas_data.map((data) => {
            let cantidad = data.cantidad;            

            let itemData = [data.fecha,cantidad.usuarios,cantidad.verificados,cantidad.usuarios_con_pedidos];

            cantidadUsuariosConPedidos += cantidad.usuarios_con_pedidos;
            cantidadUsuariosVerificados += cantidad.verificados;
            cantidadUsuariosNuevos += cantidad.usuarios;

            usuariosVerificados.push(itemData);
        });

        setTotalUsuarios(cantidadUsuariosNuevos);
        setTotalVerificados(cantidadUsuariosVerificados);

        usuariosVerificadosPie.push(['Nuevos',cantidadUsuariosNuevos]);
        usuariosVerificadosPie.push(['Verificados',cantidadUsuariosVerificados]);

        usuariosVerificadosConPedidosPie.push(['Verificados',cantidadUsuariosVerificados]);
        usuariosVerificadosConPedidosPie.push(['Verificados con Pedidos',cantidadUsuariosConPedidos]);

        estadisticasGen.usuariosVerificadosPie = usuariosVerificadosPie;
        estadisticasGen.usuariosVerificadosConPedidosPie = usuariosVerificadosConPedidosPie;
        estadisticasGen.usuariosVerificados = usuariosVerificados;

        ///////////////////////////////////
        //  Usuarios por ciudad
        ///////////////////////////////////

        let usuariosCiudad = [
            ['Usuarios','Bahía Blanca','Monte Hermoso']
        ];

        let usuariosCiudadPie = [
            ['Bahía Blanca','Monte Hermoso']
        ];

        let cantUsuariosBahia = 0;
        let cantUsuariosMonte = 0;

        estadisticas_data.map((data) => {
            let ciudades = data.ciudades;            
            let itemData = [data.fecha,ciudades.bahia,ciudades.monte];

            cantUsuariosBahia += ciudades.bahia;
            cantUsuariosMonte += ciudades.monte;

            usuariosCiudad.push(itemData);
        });

        usuariosCiudadPie.push(['Bahia Blanca',cantUsuariosBahia]);
        usuariosCiudadPie.push(['Monte Hermoso',cantUsuariosMonte]);

        estadisticasGen.usuariosCiudadPie = usuariosCiudadPie;
        estadisticasGen.usuariosCiudad = usuariosCiudad;

        ///////////////////////////////////
        // Usuarios por Plataforma
        ///////////////////////////////////

        let usuariosPlataforma = [
            ['Usuarios','Android','iOS']
        ];

        let usuariosPlataformaPie = [
            ['Android','iOS']
        ];

        let cantAndroid = 0;
        let cantIOS = 0;

        estadisticas_data.map((data) => {
            let plataformas = data.plataformas;            
            let itemData = [data.fecha,plataformas.android,plataformas.ios];

            cantAndroid += plataformas.android;
            cantIOS += plataformas.ios;

            usuariosPlataforma.push(itemData);
        });

        usuariosPlataformaPie.push(['Android',cantAndroid]);
        usuariosPlataformaPie.push(['iOS',cantIOS]);

        estadisticasGen.usuariosPlataformaPie = usuariosPlataformaPie;
        estadisticasGen.usuariosPlataforma = usuariosPlataforma;

        setEstadisticas(estadisticasGen);
        setLoading(false);
    };

    return (
        <div>
            <h2 className="p-2">Se ganaron un total de {totalUsuarios} usuarios</h2>

            <Chart
                className="p-2"
                width={'100%'}
                height={'300px'}
                chartType="Bar"
                loader={<div>Cargando Gráfico</div>}
                data={estadisticas.usuariosVerificados}
                options={{
                    // Material design options  
                    chart: {
                    title: 'Usuarios Totales vs Verificados vs Hicieron Pedidos'
                    },
                }}
                // For tests
                rootProps={{ 'data-testid': '2' }}
                />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Cargando Grafico de Torta</div>}
                data={estadisticas.usuariosVerificadosPie}
                options={{
                title: 'Usuarios Verificados - Total Verificados = '+totalVerificados ,
                }}
                rootProps={{ 'data-testid': '1' }}
            />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Cargando Grafico de Torta</div>}
                data={estadisticas.usuariosVerificadosConPedidosPie}
                options={{
                title: 'Verificados que hicieron Pedido ',
                }}
                rootProps={{ 'data-testid': '1' }}
            />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="Bar"
                loader={<div>Cargando Gráfico</div>}
                data={estadisticas.usuariosCiudad}
                options={{
                    // Material design options  
                    chart: {
                    title: 'Nuevos usuarios por ciudad',
                    subtitle: 'Bahía Blanca vs Monte Hermoso',
                    },
                }}
                // For tests
                rootProps={{ 'data-testid': '2' }}
                />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Cargando Grafico de Torta</div>}
                data={estadisticas.usuariosCiudadPie}
                options={{
                title: 'Total de usuarios nuevos por ciudad - Total = '+totalUsuarios,
                }}
                rootProps={{ 'data-testid': '1' }}
            />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="Bar"
                loader={<div>Cargando Gráfico</div>}
                data={estadisticas.usuariosPlataforma}
                options={{
                    // Material design options  
                    chart: {
                    title: 'Nuevos usuarios por Plataforma',
                    subtitle: 'Android vs iOS',
                    },
                }}
                // For tests
                rootProps={{ 'data-testid': '2' }}
                />
            <hr className="p-2"/>
            <Chart
                width={'100%'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Cargando Grafico de Torta</div>}
                data={estadisticas.usuariosPlataformaPie}
                options={{
                title: 'Total de nuevos usuarios por plataforma - Total = '+totalUsuarios,
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        </div>
    );
};

export default GraficosUsuarios;
