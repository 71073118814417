import React from "react";
import "./style.css";
import { List, TextField, Checkbox } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
const ProductosList = props => {
    return (
        <List
            component="nav"
            aria-label="main mailbox folders"
            style={{ maxHeight: "400px"}}
        >
            {props.productos.map((tipo, key) => {
                return (
                    <div key={key}>
                        <p className="mb-2">▬ Tipo: {tipo.tipo}</p>
                        {tipo.productos.map((producto, key) => {
                            return (
                                <ListItem key={key}>
                                    <ListItemText primary={producto.titulo} />
                                    {/* <TextField
                                        className="mr-2"
                                        defaultValue={producto.precio}
                                        label="Precio"
                                        onChange={event => {
                                            // eslint-disable-next-line no-unused-expressions
                                            !event.target.value ||
                                                event.target.value == ""
                                                ? 0
                                                : parseInt(event.target.value);
                                        }}
                                        onKeyPress={ev => {
                                            if (ev.key === "Enter") {
                                                props.updatePrecio(
                                                    producto.id,
                                                    event.target.value
                                                );
                                                ev.preventDefault();
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                    /> */}
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={producto.disponible === 1}
                                            className="pointer"
                                            onClick={() =>
                                                props.setDisponible(
                                                    producto.id,
                                                    !producto.disponible
                                                )
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemIcon>
                                        <EditIcon
                                            className="pointer"
                                            onClick={() =>
                                                props.history.push(
                                                    props.match.url +
                                                    "/producto/" +
                                                    producto.id
                                                )
                                            }
                                        />
                                    </ListItemIcon>
                                </ListItem>
                            );
                        })}
                    </div>
                );
            })}
        </List>
    );
};

export default withRouter(ProductosList);
