import React, { useState, useEffect } from "react";

export const useAudio = (url, loop = false) => {
  let audioAux = new Audio(url);
  audioAux.loop = loop;

  const [audio] = useState(audioAux);
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);


  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  return [playing, toggle];
};

export const Player = ({ url, loop }) => {
  const [playing, toggle] = useAudio(url, loop);

  return (
    <div>
      <button onClick={toggle}>{playing ? "Pause" : "Play"}</button>
    </div>
  );
};

export default Player;