import React from 'react';
import moment from 'moment';
import { get, post } from '../../api/axios';
import "./style.css";
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import { FormControl, MenuItem, Select, Grid, Paper, Table, TableCell, TableBody, TableHead, TableRow } from "@material-ui/core";
import TablaPedidos from '../../components/tablaPedidos/TablaPedidos';
const useStyles = makeStyles({
    root: {
        width: "80%",
        overflowX: "auto"
    },
    table: {
        width: "100%"
    }
});

const ReporteCadetes = () => {
    const classes = useStyles();

    const [cadeteId, setCadeteId] = React.useState(null);
    const [cadetes, setCadetes] = React.useState(null);
    const [fechaInicio, setFechaInicio] = React.useState(moment());
    const [fechaFin, setFechaFin] = React.useState(moment());
    const [reporte, setReporte] = React.useState(null);
    const [pedidos, setPedidos] = React.useState([]);
    const [envios, setEnvios] = React.useState(0);
    const [aRendir, setARendir] = React.useState(0);

    React.useEffect(() => {
        get('/cadetes').then(response => {
            let cadetes = [{ id: -1, nombre: 'Reporte subtotales', apellido: ' cadetes' }]
            response.data.map(ciudad => cadetes = [...cadetes, ...ciudad.cadetes]);
            setCadetes(cadetes);
            setCadeteId(cadetes[0].id);
        });
    }, []);

    const fetchReporte = () => {
        console.log(cadeteId)
        if (cadeteId != -1) {
            post('/cadetes/reporte', {
                cadete_id: cadeteId,
                fecha_inicio: moment(fechaInicio).format("YYYY-MM-DD"),
                fecha_fin: moment(fechaFin).format("YYYY-MM-DD")
            }).then(response => {
                setReporte(response.data);
                setPedidos(mapPedidos(response.data.pedidos, response.data.cadete));
                calcularDatos(response.data.pedidos);
            })
        }
        else {
            post('/reportes/v2/cadetes/subtotales', {
                desde: moment(fechaInicio).format("YYYY-MM-DD"),
                hasta: moment(fechaFin).format("YYYY-MM-DD")
            }).then(response => {
                window.open(response.data);
            })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const rendicion = (tipo = 'negocios') => {
        post('/reportes/v2/cadete/' + tipo, {
            id: cadeteId,
            desde: fechaInicio.format('YYYY-MM-DD').concat('T00:00:00'),
            hasta: fechaFin.format('YYYY-MM-DD').concat('T23:59:59'),
        })
            .then(response => {
                window.open(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const mapPedidos = (pedidos, cadete) => {
        return pedidos.map(pe => { return { ...pe, cadete: cadete } });
    }

    const calcularDatos = (pedidos) => {
        let envios = 0;
        let aRendir = 0;
        pedidos.forEach(pedido => {
            envios += pedido.precio_envio;
            if (pedido.precio_envio < 60) {
                aRendir += 60 - pedido.precio_envio;
            }
        })

        setARendir(aRendir);
        setEnvios(envios);
    }
    return (
        <div className="formWidth">
            <Grid container justify='center'>
                {
                    cadetes && cadeteId &&
                    <form className="formWidth" noValidate className="datePicker">
                        <Grid item xs={8}>
                            <TextField
                                id="fecha"
                                label="Desde"
                                type="date"
                                value={fechaInicio.format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                className="datePicker"
                                onChange={e => setFechaInicio(moment(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="fecha"
                                label="Hasta"
                                type="date"
                                value={fechaFin.format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                className="datePicker"
                                onChange={e => setFechaFin(moment(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth margin="dense" variant="filled" className="float-right">
                                <Select
                                    label="Cadete"
                                    value={cadeteId}
                                    onChange={e => setCadeteId(e.target.value)}
                                    variant="filled"
                                >
                                    {cadetes.map((cadete, key) =>
                                        <MenuItem value={cadete.id} key={key}>
                                            {cadete.nombre + " " + cadete.apellido}
                                        </MenuItem>
                                    )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Button onClick={fetchReporte} variant="contained" >
                            Reporte
                            </Button>
                        <Button onClick={() => rendicion('negocios')} variant="contained" className="btnReporte">
                            Rendicion
                            </Button>
                        <Button onClick={() => rendicion('personalizados')} variant="contained" className="btnReporte">
                            Rendicion personalizados
                            </Button>
                    </form>
                }
                {
                    reporte &&
                    <Grid container>
                        <p><strong>Cadete: {reporte.cadete.nombre + " " + reporte.cadete.apellido}</strong></p>
                        <p>Total ingreso: {reporte.totalIngreso} </p>
                        <p>Total ingreso efectivo: {reporte.totalEfectivo} </p>
                        <p>Gastos: {reporte.gastos} </p>
                        <p>Envios completados: {reporte.enviosCompletados}</p>
                        <p>Envios NO completados: {reporte.enviosNoCompletados}</p>
                        <p>Envios con efectivo: {reporte.enviosEfectivo}</p>
                        <p>Envios con tarjeta: {reporte.enviosTarjeta}</p>
                        <p>Ingreso por envios: {envios}</p>
                        <p>Total dinero a rendir: {aRendir}</p>
                        <p>Total ganancia cadete: {envios + aRendir}</p>
                        <Paper>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Accion</TableCell>
                                        <TableCell align="center">Fecha</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reporte.logs.map((log, key) => (
                                        <TableRow key={key}>
                                            <TableCell align="center">
                                                {log.accion}
                                            </TableCell>
                                            <TableCell align="center">
                                                {moment(log.fecha).format('DD/MM/YYYY HH:mm')}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TablaPedidos pedidos={pedidos} />
                </Grid>
            </Grid>
        </div >
    )

}

export default ReporteCadetes;
