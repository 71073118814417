import React from "react";
import { Modal, FormControl, Button, MenuItem } from "@material-ui/core";
import { post, get } from "../../api/axios";
import { openAlert } from "simple-react-alert";
import {
    SelectValidator,
    ValidatorForm
} from "react-material-ui-form-validator";

const ModalCupones = props => {
    const [cupones, setCupones] = React.useState([]);
    const [cupon, setCupon] = React.useState(0);

    React.useEffect(() => {
        get("/cupones").then(response => {
            setCupones(response.data);
        });
    }, []);

    const asignar = () => {
        post("/cupones/asignar", {
            user_id: props.user.id,
            cupon_id: cupon
        }).then(() => {
            openAlert({ message: "Asignado con exito", type: "success" });
            props.setShowModal(!props.open);
            setCupon(0);
        }).catch(() => {
            openAlert({ message: "No se pudo asignar el cupon. Puede que ya haya sido asignado", type: 'info' });
            props.setShowModal(!props.open);
            setCupon(0);
        });
    };

    const close = () => {
        setCupon(0);
        props.setShowModal(!props.open);
    };
    return (
        <Modal
            open={props.open}
            onClose={() => close()}
            className="modalContainer"
        >
            <div className="modalBodyCupon">
                <ValidatorForm onSubmit={asignar}>
                    <FormControl
                        fullWidth
                        margin="dense"
                        variant="filled"
                        className="tipoInput"
                    >
                        <p>Selecciona un cupon</p>
                        <SelectValidator
                            label="Cupones disponibles"
                            value={cupon}
                            onChange={e => setCupon(e.target.value)}
                            validators={["required"]}
                            variant="filled"
                            errorMessages={["Este campo es requerido"]}
                        >
                            {cupones.map((cupon, key) => {
                                return (
                                    <MenuItem value={cupon.id} key={key}>
                                        {cupon.nombre}
                                    </MenuItem>
                                );
                            })}
                        </SelectValidator>
                    </FormControl>
                    <Button
                        variant="outlined"
                        type="submit"
                        className="btnVerificar"
                    >
                        Asignar
                    </Button>
                </ValidatorForm>
            </div>
        </Modal>
    );
};

export default ModalCupones;
