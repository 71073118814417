import React, { useEffect } from "react";
import { Button, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { openAlert } from "simple-react-alert";
import { get } from "../../api/axios";
import EditIcon from "@material-ui/icons/Edit";

const CiudadesList = props => {
  const [ciudades, setCiudades] = React.useState([]);

  useEffect(() => {
    get("/ciudades").then(response => {
      setCiudades(response.data);
    });
  }, []);

  const renderCiudades = () => {
    return (
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{ maxHeight: "400px", overflow: "auto" }}
      >
        {ciudades.map((ciudad, key) => {
          return (
            <ListItem
              button
              onClick={() => props.history.push("/ciudades/editar/" + ciudad.id)}
              key={key}
            >
              <ListItemText primary={ciudad.nombre} />
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    )
  }

  return (
    <Row xs={{ span: 12, offset: 2 }} className="justify-content-md-center rowContainer">
      <Col xs={8}>
        <Button
          size="large"
          variant="contained"
          className="ml-2"
          onClick={() => props.history.push("/ciudades/nuevo")}
        >
          Agregar nueva ciudad
        </Button>
        {renderCiudades()}
        {/* <ProductosList productos={productos} precios={precios} setPrecios={setPrecios} updatePrecio={updatePrecio}/> */}
      </Col>
    </Row>
  );
};

export default withRouter(CiudadesList);
