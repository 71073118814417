import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'

const useStyles = makeStyles(() => ({
  main: {
    position: 'absolute',
    right: '5vw',
    bottom: '70vh'
  }
}));

const NotificacionPlayer = ({ playing, toggle }) => {
  const classes = useStyles();

  return (
    <div className={classes.main} id="audio-player">
      {
        playing &&
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={toggle}
          className={classes.button}
          startIcon={<NotificationImportantIcon />}
        >
          Detener notificacion
        </Button>
      }
    </div>
  );
};

export default NotificacionPlayer;