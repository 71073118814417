const TextNegocio = props => {
    let text = props.map(tipo => {
        return `*${tipo.tipo}*\n ${tipo.productos.map(producto => {
            return `*${producto.titulo}* : $${producto.precio}\n`;
        })}\n`;
    });
    let copy = "";
    text.map(text => (copy += text));
    return copy;
};

export default TextNegocio;
