import React from "react";
import "../style.css";
import { makeStyles } from "@material-ui/core";
import ModalPedido from '../ModalPedido';

const useStyles = makeStyles(theme => ({
    paper: {
        marginLeft: "34%",
        marginTop: "10%",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4)
    }
}));

const ItemWrapper = props => {
    const [showModal, setShowModal] = React.useState(false);

    const classes = useStyles();
    return (
        <div className={props.className} style={{ height: "auto" }}>
            <div className='zonaClickeable' onClick={() => setShowModal(!showModal)}></div>
            {React.Children.map(props.children, child => { return child })}
            {showModal && <ModalPedido
                pedido={props.pedido}
                showModal={showModal}
                setShowModal={setShowModal}
                classes={classes}
            />}
        </div>
    );
};

export default ItemWrapper;
