import React, { useContext, useEffect } from "react";
import { Modal, Input, Button, Select, MenuItem, InputLabel } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { StoreContext } from './pedidosContext/Store'

const ModalModificar = props => {
    const { state, dispatch } = useContext(StoreContext);
    const [razon, setRazon] = React.useState("");
    const [monto, setMonto] = React.useState(0);
    const [tipo, setTipo] = React.useState("productos");

    useEffect(() => {
        dispatch({
            type: 'MODIFYING',
            dirty: 1
        });

        return () => dispatch({
            type: 'MODIFYING',
            dirty: 0
        });
    }, [])

    const modificar = () => {
        if (razon && razon.length > 5) {
            let body = { razon, monto, tipo };
            props.modificar(body);
            setRazon("");
            setMonto(0);
            setTipo("productos");
            close();
        }
    };
    const close = () => {
        props.setShowModal(!props.showModal);
    };
    return (
        <Modal
            open={props.showModal}
            onClose={() => close()}
            className="modalContainer"
        >
            <div className="modalBody">
                <p><b>Modificar pedido</b></p>
                <div className="width-80">
                    <div className="mb-2">
                        <p>Tipo:</p>
                        <Select
                            fullWidth
                            value={tipo}
                            onChange={e => setTipo(e.target.value)}
                        >
                            <MenuItem value={"productos"}>Productos</MenuItem>
                            <MenuItem value={"envio"}>Envio</MenuItem>
                        </Select>
                    </div>
                    <div className="mb-2">
                        <p>Razon:</p>
                        <TextField
                            fullWidth
                            type={"string"}
                            multiline
                            rows={5}
                            value={razon}
                            onChange={e => setRazon(e.target.value)}
                        />
                    </div>
                    <div className="mb-2">
                        <p>Monto nuevo:</p>
                        <TextField
                            fullWidth
                            type="number"
                            value={monto}
                            onChange={e => setMonto(e.target.value)}
                        />
                    </div>
                    <Button
                        variant="outlined"
                        className="btnVerificar"
                        onClick={() => modificar()}
                    >
                        Aceptar
                    </Button>
                    <p>
                        <b>Montos actuales</b>
                        <br />
                        Subtotal: ${props.pedido.total}
                        <br />
                        Envio: ${props.pedido.precio_envio}
                    </p>
                </div>
            </div>
        </Modal>
    );
};

export default ModalModificar;
