import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { TextField, Button } from '@material-ui/core';
import { post } from '../../../api/axios';
import moment from 'moment';


const ReporteNegocio = props => {

    const [fechaFin, setFechaFin] = React.useState(moment());
    const [fechaInicio, setFechaInicio] = React.useState(moment());

    const handleSubmit = (event) => {
        event.preventDefault();
        post('/reportes/v2/negocio', { id: props.id, desde: fechaInicio.format('YYYY-MM-DD').concat('T00:00:00'), hasta: fechaFin.format('YYYY-MM-DD').concat('T23:59:59') })
            .then(response => {
                window.open(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div className="formWidth">
            <Row xs={{ span: 10, offset: 0 }}>
                <form className="formWidth" onSubmit={(event) => handleSubmit(event)} noValidate className="">
                    <Row>
                        <Col xs={12}>
                            <TextField
                                id="fecha"
                                label="Desde"
                                type="date"
                                value={fechaInicio.format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                className="datePicker"
                                onChange={e => setFechaInicio(moment(e.target.value))}
                            />
                        </Col>
                        <Col xs={12}>
                            <TextField
                                id="fecha"
                                label="Hasta"
                                type="date"
                                value={fechaFin.format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                className="datePicker"
                                onChange={e => setFechaFin(moment(e.target.value))}
                            />
                        </Col>
                    </Row>
                    <Button type="submit" variant="contained" >
                        Reporte
                    </Button>
                </form>
            </Row>
        </div >
    )
}

export default ReporteNegocio;