import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#272526',
        },
        secondary: {
            main: '#ff4364',
        },
    },
    overrides: {
        MuiButton: {
            contained: {
                color: '#fff',
                backgroundColor: '#2c2c2c',
                marginBottom: 10,
                borderRadius: 36,
                "&:hover": {
                    backgroundColor: '#ff4364'
                }
            },
            root: {
                textTransform: 'none',

            },
        },
        MuiFilledInput: {
            formControl: {
                backgroundColor: 'none'
            },
            root: {
                backgroundColor: 'none'
            }
        },
        MuiTooltip: {
            tooltip: {
              fontSize: 16,
            }
          }
    }

});

export default theme;