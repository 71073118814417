import React from 'react';
import { CircularProgress, Button as ButtonMaterial } from '@material-ui/core';
import '../../index.css';

export const CustomButton = ({ size, onClick, label, ...props }) => {

    const computedDisabled = props.isLoading || props.disabled || false;
    return (<ButtonMaterial
        disabled={computedDisabled}
        size={size}
        variant='contained'
        style={props.style}
        onClick={onClick}>
        {label}
        {props.isLoading && <CircularProgress size={24} className='circularProgressStyle' />}

    </ButtonMaterial>)
}

CustomButton.defaultProps = {
    size: 'large',
    onClick: () => { },
}
