import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import ProductosList from "./ProductosList";
import { withRouter } from "react-router";
import "../style.css";
import { get, post } from "../../../api/axios";
import { openAlert } from "simple-react-alert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyIcon from "@material-ui/icons/FileCopy";
import TextNegocio from "./utils";
import ReporteNegocio from '../reportes/Reporte'
import ModalCopiarProductos from "./ModalCopiarProductos";

const EditarNegocio = props => {
    const [productos, setProductos] = React.useState([]);
    const [text, setText] = React.useState("");
    const [showModalCopiar, setShowModalCopiar] = React.useState(false);
    const [negocio, setNegocio] = React.useState('');

    useEffect(() => {
        fetchData();
    }, [props.match.params.id]);

    const fetchData = () => {
        get("/negocios/" + props.match.params.id).then(res => {
            setNegocio(res.data.nombre);
        });
        get("/negocios/" + props.match.params.id + "/productos").then(
            response => {
                setProductos(response.data);
                setText(TextNegocio(response.data));
            }
        );
    };

    const updatePrecio = (id, precio) => {
        post("/productos/" + id + "/precio", { precio })
            .then(response => {
                openAlert({
                    message: "Precio de producto actualizado",
                    type: "success"
                });
                fetchData();
            })
            .catch(error => {
                openAlert({
                    message: "Error al actualizar precio",
                    type: "danger"
                });
            });
    };

    const setDisponible = (id, disponible) => {
        post("/productos/" + id + "/disponible", {
            disponible
        }).then(response => {
            openAlert({ message: "Producto actualizado", type: "success" });
            fetchData();
        });
    };

    const onCloseModalCopiar = (fetch = false) => {
        setShowModalCopiar(false);
        if(fetch) {
            fetchData();
        }
    }

    return (
        <Row
            xs={{ span: 12, offset: 2 }}
            className="justify-content-md-center rowContainer"
        >
            <Col xs={{ span: 4, offset: 2 }} className='reporte'>
                <h3>{negocio}</h3>
            </Col>
            <Col xs={{ span: 4, }} className='reporte'>
                <ReporteNegocio id={props.match.params.id} />
            </Col>
            <Col xs={8}>
                <Button
                    size="large"
                    variant="contained"
                    className="ml-2"
                    onClick={() =>
                        props.history.push(props.match.url + "/nuevo/producto")
                    }
                >
                    Agregar producto
                </Button>
                <Button
                    size="large"
                    variant="contained"
                    className="ml-2"
                    onClick={() =>
                        props.history.push(props.match.url + "/nuevo/bebida")
                    }
                >
                    Agregar bebida
                </Button>
                <Button
                    size="large"
                    variant="contained"
                    className="ml-2"
                    onClick={() =>
                        props.history.push(props.match.url + "/globales")
                    }
                >
                    Administrar ingredientes globales
                </Button>
                <Button
                    size="large"
                    variant="contained"
                    className="ml-2"
                    onClick={() =>
                        props.history.push(props.match.url + "/info")
                    }
                >
                    Editar Informacion del negocio
                </Button>
                <Button
                    size="large"
                    variant="contained"
                    className="ml-2"
                    onClick={() => setShowModalCopiar(true)}
                >
                    Clonar
                </Button>
                <CopyToClipboard text={text} onCopy={() => { }}>
                    <Button className="btnCopiar" size="small">
                        <CopyIcon />
                    </Button>
                </CopyToClipboard>
                <ProductosList
                    productos={productos}
                    updatePrecio={updatePrecio}
                    setDisponible={setDisponible}
                />
            </Col>
            {
                showModalCopiar &&
                <Col xs={8}>
                    <ModalCopiarProductos open={true} destino={props.match.params.id} onClose={onCloseModalCopiar} />
                </Col>
            }
        </Row >
    );
};

export default withRouter(EditarNegocio);
