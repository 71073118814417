import React, { useEffect } from "react";
import { List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
import { get } from "../../api/axios";
const CadeteriasList = props => {
  const [cadeterias, setCadeterias] = React.useState([]);
  useEffect(() => {
    get("/cadeterias").then(response => {
      let cadeterias = response.data.sort(function (a, b) {
        if (a.nombre < b.nombre) { return -1; }
        if (a.nombre > b.nombre) { return 1; }
        return 0;
      });
      setCadeterias(cadeterias);
    });
  }, []);

  return (
    <List
      component="nav"
      aria-label="main mailbox folders"
      style={{ maxHeight: "400px", overflow: "auto" }}
    >
      {cadeterias.map((cadeteria, key) => {
        return (
          <ListItem
            button
            onClick={() => props.history.push("/cadeterias/editar/" + cadeteria.id)}
            key={key}
          >
            <ListItemText primary={cadeteria.nombre} />
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
          </ListItem>
        );
      })}
    </List>
  );
};

export default withRouter(CadeteriasList);
