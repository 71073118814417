import Echo from 'laravel-echo';
import socketio from 'socket.io-client';

const echo = new Echo({
  host: `${process.env.REACT_APP_LARAVEL_ECHO_SERVER}:${process.env.REACT_APP_LARAVEL_ECHO_PORT}`,
  broadcaster: 'socket.io',
  client: socketio,
});

console.log('echo', echo);
console.log('host',`${process.env.REACT_APP_LARAVEL_ECHO_SERVER}:${process.env.REACT_APP_LARAVEL_ECHO_PORT}`);

export default echo;
