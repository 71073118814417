import React, { useState } from "react";
import { get } from "../../api/axios";
import "./style.css";
import moment from 'moment';
import { Grid, InputAdornment, IconButton, Input, InputLabel, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import ModalBilletera from './ModalBilletera';
const MainBilleteras = () => {
    const [transacciones, setTransacciones] = useState([]);
    const [saldo, setSaldo] = useState(-1);
    const [id, setId] = useState(0);
    const [showModal, setShowModal] = useState(false)

    const searchSaldo = () => {
        get(`/users/${id}/billetera`).then(response => {
            setTransacciones(response.data.transacciones);
            setSaldo(response.data.saldo);
        })
    }

    return (
        <Grid container justify='center' >
            <Grid item xs={6} style={{ marginTop: '69px' }}>
                <InputLabel>Buscar por ID de usuario</InputLabel>
                <Input label='buscar por id' onChange={(e) => setId(e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="buscar pedido"
                                onClick={searchSaldo}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    } />
            </Grid>
            <Grid item xs={3} style={{ marginTop: '69px', alignSelf: 'center' }} >
                <Button variant="outlined" onClick={() => setShowModal(true)}>Modificar saldo</Button>
            </Grid>
            <Grid item xs={12}>
                {saldo !== -1 && <div className="estadisticas">
                    <p>Saldo actual: {saldo}</p>
                </div>}
            </Grid>
            {transacciones.length > 0 ? <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Monto</TableCell>
                        <TableCell>Saldo</TableCell>
                        <TableCell align="center">Tipo</TableCell>
                        <TableCell align="center">Pedido ID</TableCell>
                        <TableCell align="center">User Id</TableCell>
                        <TableCell align="center">Creado en</TableCell>
                        <TableCell align="center">
                            Actualizado en
                            </TableCell>
                        <TableCell align="center">Motivo</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transacciones.map((transaccion, key) => (
                        <TableRow key={key}>
                            <TableCell component='th' scope='row'>{transaccion.id}</TableCell>
                            <TableCell component="th" scope="row">
                                {transaccion.monto}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {transaccion.saldo}
                            </TableCell>
                            <TableCell align="center">
                                {transaccion.tipo}

                            </TableCell>
                            <TableCell component="th" scope="row">
                                {transaccion.pedido_id}
                            </TableCell>
                            <TableCell align="center">
                                {transaccion.user_id}
                            </TableCell>
                            <TableCell align="center">
                                {transaccion.created_at}
                            </TableCell>
                            <TableCell align="center">
                                {transaccion.updated_at}
                            </TableCell>
                            <TableCell align="center">
                                {transaccion.motivo}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table> :
                saldo !== -1 && <Typography>Sin transacciones</Typography>
            }
            {showModal && <ModalBilletera showModal={showModal} setShowModal={setShowModal} />}
        </Grid>
    );
};

export default MainBilleteras;
