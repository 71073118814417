import React from "react";
import { Modal } from "@material-ui/core";
import "./style.css";

const ModalPedidoNegocio = props => {

    const renderPedido = () => {
        let id = props.pedido.items[0].producto_id;
        let detalle = props.pedido.items[0].detalles;
        let counts = [];
        let count = 0;
        let indice = -1;
        props.pedido.items.map(item => {
            if (id === item.producto_id && detalle === item.detalles) {
                count++;
                indice++;
            } else {
                counts.push({ count: count, index: indice, detalle: detalle });
                count = 1;
                detalle = item.detalles;
                indice++;
                id = item.producto_id;
            }
        });
        counts.push({ count: count, index: indice, detalle: detalle });
        return counts.map((count, key) => {
            return (
                <p key={key}>
                    {props.pedido.items[count.index].producto
                        ? props.pedido.items[count.index].producto.titulo
                        : props.pedido.items[count.index].detalle}{" "}
                    x{count.count}
                    <br></br> > {count.detalle}
                </p>
            );
        });
    };

    const renderDetalle = () => {
        switch (props.pedido.tipo) {
            case "Personalizado": {
                return (
                    <div className={props.classes.paper}>
                        <p>
                            <b>PEDIDO PERSONALIZADO</b> <br />
                            <br />
                            <b>ID: </b> {props.pedido.id} <br />
                            <b>Usuario: </b> {props.pedido.user.nombre + ' ' + props.pedido.user.apellido} <b>ID: </b>{props.pedido.user.id} <br />
                            <b>Telefono Usuario: </b> {props.pedido.user.telefono} <br />
                            <br />
                            <b>Direccion Origen: </b> {props.pedido.direccion_origen} <br />
                            <b>Direccion Entrega: </b> {props.pedido.direccion_entrega} <br />
                            <br />
                            <b>Pedido: </b> {props.pedido.detalle_pedido} <br />
                        </p>
                    </div>
                );
            }
            case "PersonalizadoNegocio": {
                return (
                    <div className={props.classes.paper}>
                        <p>
                            <b>PEDIDO PERSONALIZADO</b> <br />
                            {props.pedido.take_away ?
                                <b>Take Away </b>
                                :
                                <><b>Delivery </b><br /><b>Direccion Entrega:</b><p>{props.pedido.direccion_entrega}</p><br /></>
                            }
                            <b>ID: </b> {props.pedido.id} <br />
                            <b>Usuario: </b> {props.pedido.user.nombre + ' ' + props.pedido.user.apellido} <b>ID: </b>{props.pedido.user.id} <br />
                            <b>Telefono Usuario: </b> {props.pedido.user.telefono} <br />
                            <br />
                            <br />
                            <b>Pedido: </b> {props.pedido.detalle_pedido} <br />
                            <b>Total Pedido: </b> {props.pedido.total} <br />
                            <b>Precio Envio: </b> {props.pedido.precio_envio} <br />

                        </p>
                    </div>
                );
            }
            case "PersonalizadoCadeteria": {
                return (
                    <div className={props.classes.paper}>
                        <p>
                            <b>PEDIDO PERSONALIZADO CADETERIA</b> <br />
                            {props.pedido.take_away ?
                                <b>Take Away </b>
                                :
                                <><b>Delivery </b><br /><b>Direccion Entrega:</b><p>{props.pedido.direccion_entrega}</p><br /></>
                            }
                            <b>ID: </b> {props.pedido.id} <br />
                            <b>Usuario: </b> {props.pedido.user.nombre + ' ' + props.pedido.user.apellido} <b>ID: </b>{props.pedido.user.id} <br />
                            <b>Telefono Usuario: </b> {props.pedido.user.telefono} <br />
                            <br />
                            <br />
                            <b>Pedido: </b> {props.pedido.detalle_pedido} <br />
                            <b>Total Pedido: </b> {props.pedido.total} <br />
                            <b>Precio Envio: </b> {props.pedido.precio_envio} <br />

                        </p>
                    </div>
                );
            }
            case "TakeAway": {
                return (
                    <div className={props.classes.paper}>
                        <p>
                            <b>PEDIDO TAKE AWAY</b> <br />
                                <b>Take Away </b>
                            <b>ID: </b> {props.pedido.id} <br />
                            <b>Usuario: </b> {props.pedido.user.nombre + ' ' + props.pedido.user.apellido} <b>ID: </b>{props.pedido.user.id} <br />
                            <b>Telefono Usuario: </b> {props.pedido.user.telefono} <br />
                            <br />
                            <p>
                                <b>Comentarios: </b> {props.pedido.comentario}
                             </p>
                            <p>
                                <b>Productos: </b>
                            </p>
                            {renderPedido()}
                            <p>
                                <b>Total Pedido: </b> {props.pedido.total} <br />
                            </p>
                        </p>
                    </div>
                );
            }
            default: {
                return (
                    <div className={props.classes.paper}>
                        <p>
                            <b>ID: </b> {props.pedido.id}
                            <br />
                            <b>Usuario: </b> {props.pedido.user.nombre + ' ' + props.pedido.user.apellido} <b>ID: </b>{props.pedido.user.id}
                            <br />
                            <b>Negocio: </b> {props.pedido.negocio.nombre + " "}
                            <br />
                            <b>Direccion: </b> {props.pedido.negocio.ubicacion}
                            <br />
                            {
                                props.pedido.direccion &&
                                <><b>Lat-Lon: </b>{props.pedido.direccion.latitud} {props.pedido.direccion.longitud} {props.pedido.direccion.valida ? '(OK)' : '(NO OK)'}</>
                            }
                        </p>
                        <p>{props.pedido.tarjeta ? props.pedido.pagado ? <b>Tarjeta: PAGO</b> : <b>Tarjeta: NO PAGO</b> : <b>Efectivo</b>}</p>
                        <p>
                            <b>Precio: </b> {props.pedido.total} <br />
                            <b>Envio: </b> {props.pedido.precio_envio} <br />
                            {
                                props.pedido.descuento > 0 &&
                                <><b>Descuento: -</b> {props.pedido.descuento} <br /></>
                            }
                            {props.pedido.saldo_billetera && <><b>Billetera:</b> {props.pedido.saldo_billetera}<br /></>}
                            <b>Total: </b> {props.pedido.total + props.pedido.precio_envio - props.pedido.descuento - props.pedido.saldo_billetera} <br />
                        </p>
                        <p>
                            <b>Direccion Entrega: </b>{" "}
                            {props.pedido.direccion_entrega}
                            <br />
                            <b>Entre calles: </b> {props.pedido.entre_calles}
                        </p>
                        <p>
                            <b>Telefono Usuario: </b>{" "}
                            {props.pedido.user.telefono}
                        </p>
                        <p>
                            <b>Comentarios: </b> {props.pedido.comentario}
                        </p>
                        <p>
                            <b>Productos: </b>
                        </p>
                        {renderPedido()}
                        <p>
                            <b> Cupones : </b>
                            {props.pedido.cupon
                                ? props.pedido.cupon.es_porcentaje
                                    ? "%" + props.pedido.cupon.descuento
                                    : "$" + props.pedido.cupon.descuento
                                : "NO"}
                            <br />
                            <b>Hora de entrega: </b> {props.pedido.hora}
                        </p>
                    </div>
                );
            }
        }
    };

    return (
        <Modal
            open={props.showModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClose={() => props.setShowModal(!props.showModal)}
            className="modalBodie"
        >
            {renderDetalle()}
        </Modal>
    );
};

export default ModalPedidoNegocio;
