import React from "react";
import "./style.css";
import moment from 'moment';
import Estadisticas from "./Estadisticas";

const EstadisticasPedidos = () => {
    return (
        <Estadisticas tipo="pedidos"/>
    );
};

export default EstadisticasPedidos;
