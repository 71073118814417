import React from "react";
import "./style.css";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Button, FormControl, MenuItem } from "@material-ui/core";
import Text from "./utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { renderDetalle } from "./utils";
import CopyIcon from "@material-ui/icons/FileCopy";
import ForwardIcon from "@material-ui/icons/Forward";
import DeleteIcon from "@material-ui/icons/Delete";
import ClockIcon from "@material-ui/icons/Timelapse";
import ModalCancelar from "./ModalCancelar";
import EditIcon from "@material-ui/icons/Edit";
import ModalModificar from "./ModalModificar";
import ModalTimer from "./ModalTimer";
import EncabezadoItem from "./EncabezadoItem";
import moment from "moment";
import Countdown from 'react-countdown';
const TIMEFORMAT = "YYYY-MM-DD HH:mm:ss";

const useStyles = makeStyles(theme => ({
    paper: {
        marginLeft: "34%",
        marginTop: "10%",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4)
    }
}));

const PedidoConfirmadoChildren = props => {
    const classes = useStyles();
    const [showModal, setShowModal] = React.useState(false);
    const [showCancelar, setShowCancelar] = React.useState(false);
    const [showTimer, setShowTimer] = React.useState(false);
    const [showModificar, setShowModificar] = React.useState(false);
    const [textToCopy, setTextToCopy] = React.useState("");

    React.useEffect(() => {
        setTextToCopy(Text(props.pedido));
    }, []);

    const onCopy = (text, result) => {
        console.log(text);
    };

    const calcularNivel = (cantPedidos) => {

        if (cantPedidos >= 3) {
            return "rojo";
        }
        if (cantPedidos < 3 && cantPedidos >= 2) {
            return "amarillo";
        }
        return "verde";
    }
    const cancelarPedido = razon => {
        props.cancelarPedido(props.pedido.id, razon, setShowCancelar);
    };

    const setTimer = minutos => {
        props.setTimestamp(props.pedido.id, minutos);
    };

    const showMinutesLeft = () => {
        //return <Typography variant="h5">{props.pedido.timer + `'`}</Typography>
        console.log(new Date(props.pedido.timer), props.pedido.timer);
        return <Countdown overtime={true} date={new Date(props.pedido.timer)} renderer={({ hours, minutes, seconds, completed }) => completed ? <Typography variant="h5" style={{ color: "red" }}>{`-${hours}:${minutes}:${seconds}`}</Typography> : <Typography variant="h5">{`${hours}:${minutes}:${seconds}`}</Typography>} />
    }

    const modificarPedido = body => {
        body.pedido_id = props.pedido.id;
        props.modificarPedido(body);
    }

    return (
        <>
            {renderDetalle(props.pedido, setShowModal, showModal)}
            <ValidatorForm onSubmit={() => props.avanzar(props.pedido, props.index)}>
            {props.pedido.tipo != "TakeAway" ?
                <FormControl fullWidth margin="dense" variant="filled" className="tipoInput">
                    {props.pedido.estado !== "Recibido" && props.pedido.confirmado !== 1 &&
                        <SelectValidator
                            label="Cadete"
                            value={props.cadete && props.cadete.id}
                            onChange={e => props.setCadete(props.pedido, e.target.value)}
                            validators={["required"]}
                            variant="filled"
                            disabled={props.procesandoEstado || props.pedido.confirmado}
                            errorMessages={["Este campo es requerido"]}
                        >
                            {props.cadetes && props.cadetes.map((cadete, key) => {
                                const nivelOcupado = calcularNivel(cadete.cant_pedidos);
                                return (
                                    <MenuItem value={cadete.id} key={key} className={`estado-${nivelOcupado}`}>
                                        {cadete.nombre + " " + cadete.apellido}
                                    </MenuItem>
                                );
                            })}
                        </SelectValidator>
                    }
                </FormControl> : 
                <></>
            }
                {props.pedido.tipo !== "TakeAway" && props.pedido.estado === "Recibido" && <Button type="submit" size="small" disabled={props.procesandoEstado}
                >
                    <ForwardIcon />
                </Button>}
                {props.pedido.tipo === "TakeAway" && 
                    <Tooltip title={!props.pedido.timer ? "Debes asignar un tiempo para  poder confirmar este pedido" : ""} placement="top" >
                        <div>
                        <Button type="submit" size="small" disabled={props.procesandoEstado || !props.pedido.timer}>
                            <ForwardIcon />
                        </Button>
                        </div>
                    </Tooltip>}
                <CopyToClipboard text={Text(props.pedido)} onCopy={() => onCopy()}>
                    <Button className="btnCopiar" size="small">
                        <CopyIcon />
                    </Button>
                </CopyToClipboard>
                {!props.pedido.tarjeta && <Button size="small" onClick={() => setShowModificar(!showModificar)}>
                    <EditIcon />
                </Button>
                }
                <Button size="small" onClick={() => setShowTimer(!showTimer)}>
                    {props.pedido.timer ? showMinutesLeft() : <ClockIcon />}
                </Button>
                {<Button size="small" onClick={() => setShowCancelar(!showCancelar)}>
                    <DeleteIcon />
                </Button>}
            </ValidatorForm>
            {showCancelar && <ModalCancelar
                cancelar={cancelarPedido}
                showModal={showCancelar}
                setShowModal={setShowCancelar}
                classes={classes}
            />}
            {showTimer && <ModalTimer
                setTimestamp={setTimer}
                showModal={showTimer}
                setShowModal={setShowTimer}
                classes={classes}
            />}

            {showModificar && <ModalModificar
                modificar={modificarPedido}
                showModal={showModificar}
                setShowModal={setShowModificar}
                classes={classes}
                pedido={props.pedido}
            />
            }
        </>
    );
};

export default PedidoConfirmadoChildren;
