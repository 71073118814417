import React from "react";
import { Modal, Input, Select, Grid, Typography, TextField } from "@material-ui/core";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { FormControl, MenuItem } from "@material-ui/core";
import { CustomButton } from '../../components/button/CustomButton';
import { get, post } from '../../api/axios';
import './style.css';
import { openAlert } from "simple-react-alert";


const ModalPedidoPersonalizado = props => {
    const [telefono, setTelefono] = React.useState('');
    const [nombre, setNombre] = React.useState('');
    const [apellido, setApellido] = React.useState('');
    const [desde, setDesde] = React.useState('');
    const [hasta, setHasta] = React.useState('');
    const [user, setUser] = React.useState(null);
    const [buscado, setBuscado] = React.useState(false);
    const [ciudadId, setCiudadId] = React.useState(false);
    const [ciudades, setCiudades] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [comentario, setComentario] = React.useState('');

    React.useEffect(() => {
        get('/status/ciudades').then(
            statusRes => {
                setCiudades(statusRes.data.ciudades);
                statusRes.data.ciudades.length === 1 && setCiudadId(statusRes.data.ciudades[0].id)
            });

    }, [])

    const buscarTelefono = () => {
        setLoading(true);
        get(`/users/search-telefono?telefono=${telefono}`).then(response => {
            setLoading(false);
            setBuscado(true);
            let user = response.data;
            if (user.nombre) {
                setNombre(user.nombre);
                setApellido(user.apellido);
                setUser(user);
                user.ciudad_id && setCiudadId(user.ciudad_id)
            }
        })
    }

    const crearPedido = () => {
        let body = {
            desde: desde,
            hasta: hasta,
            detalle: comentario,
            user_id: user ? user.id : null,
            nombre: nombre,
            ciudad_id: ciudadId,
            apellido: apellido,
            telefono: telefono
        }
        setLoading(true);
        if (desde.length < 1 || hasta.length < 1 || comentario.length < 1 || nombre.length < 1 || apellido.length < 1) {
            openAlert({ message: 'parece que faltan completar datos', status: 'warning' });
            setLoading(false);
            return;
        }
        post('/pedidos/manual/personalizado', body).then(response => {
            openAlert({ message: 'pedido creado', status: 'success' });
            setLoading(false);
            close(true);
            setNombre('');
            setApellido('');
            setUser(null);
            setHasta('');
            setDesde('');
            setComentario('');
        })
    }

    const close = (pedidoHecho = false) => {
        if (pedidoHecho) {
            props.recargarPedidos();
        }
        props.setShowModal(!props.showModal);
    };

    return (
        <Modal
            open={props.showModal}
            onClose={() => close()}
            className="modalContainer"
        >
            <div className="modalCiudades" style={{ minHeight: '31rem', width: '44rem' }}
            >
                <Grid container justify='space-between' alignItems='center' direction='column'>
                    <Grid item lg xs>
                        <Typography >
                            Ingresa numero de telefono
                    </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: '2rem' }}>
                        <Input
                            type="string"
                            value={telefono}
                            placeholder='ingresa un telefono'
                            onChange={e => setTelefono(e.target.value)}
                        />
                    </Grid>
                    {buscado &&
                        <Grid container justify='space-between' alignItems='center' direction='column'>

                            <Grid item lg={12} style={{ marginTop: '2rem' }}>
                                <TextField
                                    type="string"
                                    style={{ marginRight: '2rem' }}
                                    value={nombre}
                                    disabled={user !== null}
                                    placeholder='ingresa un nombre'
                                    onChange={e => setNombre(e.target.value)}
                                />
                                <Input
                                    type="string"
                                    value={apellido}
                                    disabled={user !== null}
                                    placeholder='ingresa un apellido'
                                    onChange={e => setApellido(e.target.value)}
                                />
                                <FormControl fullWidth margin="dense" variant="filled" style={{ width: '12rem', marginTop: -16 }}>
                                    <Select
                                        label="Ciudad"
                                        value={ciudadId}
                                        disabled={ciudades.length === 1}
                                        onChange={e => setCiudadId(e.target.value)}
                                        variant="filled"
                                    >
                                        {ciudades.map((ciudad, key) => {

                                            return <MenuItem key={key} value={ciudad.id}>{ciudad.nombre}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={10} style={{ marginTop: '2rem', width: '80%' }}>
                                <TextField
                                    type="string"
                                    value={desde}
                                    style={{ width: '80%' }}
                                    placeholder='ingresa direccion de origen'
                                    onChange={e => setDesde(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={10} style={{ marginTop: '2rem', width: '80%' }}>
                                <TextField
                                    type="string"
                                    value={hasta}
                                    style={{ width: '80%' }}
                                    placeholder='ingresa direccion de destino'
                                    onChange={e => setHasta(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={10} style={{ marginTop: '2rem', width: '80%' }}>
                                <TextField
                                    type="string"
                                    value={comentario}
                                    multiline
                                    style={{ width: '80%', height: '100px' }}
                                    placeholder='ingresa su pedido'
                                    onChange={e => setComentario(e.target.value)}
                                />
                            </Grid>
                            <Grid item lg xs style={{ marginTop: '2rem' }}>
                                <CustomButton
                                    onClick={() => crearPedido()}
                                    label='Crear pedido'
                                    isLoading={loading}

                                />
                            </Grid>
                        </Grid>}
                    {!buscado && <Grid item lg xs style={{ marginTop: '2rem' }}>
                        <CustomButton
                            onClick={() => buscarTelefono()}
                            label='Buscar usuario'
                            isLoading={loading}

                        >
                        </CustomButton>
                    </Grid>}
                </Grid>
            </div>
        </Modal >
    );
};

export default ModalPedidoPersonalizado;
