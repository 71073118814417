import React from "react";
import { Route, Redirect } from "react-router";
import { isAllowed } from './context/methods/isAllowed';
import { constants } from "./context/keys";
import { getInfo } from './context/actions/getInfo';
import { relativeTimeRounding } from "moment";
const Middleware = ({ component: Component, path, ...rest }) => {
  let user = JSON.parse(sessionStorage.getItem(constants.userInfo));

  return user && isAllowed(user, path) ?
    <Route path={path} {...rest} render={() => <Component />} /> :

    <Redirect to={{ pathname: "/login" }} />

}
export default Middleware;
