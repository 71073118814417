import React, { useState } from "react";
import { Button } from "@material-ui/core";
import CadeteriasList from "./CadeteriasList";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "./style.css";
import { openAlert } from 'simple-react-alert';
import { post } from "../../api/axios";

const OptionsCadeteria = props => {

  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <Row className={"rowContainer"}>
        <Col xs={{ span: 8 }} md={{ offset: 2, span: 4 }}>
          <Button
            variant="contained"
            size='large'
            onClick={() => {
              props.history.push(props.history.location.pathname + "/nuevo");
            }}
          >
            Agregar nueva cadeteria
          </Button>
        </Col>
        <Col xs={{ span: 8, offset: 2 }}>
          <CadeteriasList />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(OptionsCadeteria);
