import React from 'react'
import { Button } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import { post } from '../../api/axios';
import PublicidadList from './PublicidadList';
import { openAlert } from "simple-react-alert";


const MainPublicidad = (props) => {


    const eliminarCache = () => {
        post("/publicidades/cache-imagenes").then(response => {
            openAlert({ message: "Cache eliminada", type: "success" });
        });
    };

    return <Row>
        <Col xs={{ span: 8, offset: 2 }} style={{ marginTop: "5%" }}>
            <Button
                variant="contained"
                onClick={() => {
                    props.history.push(props.history.location.pathname + "/nuevo");
                }}
            >
                Agregar Publicidad
          </Button>
            <Button
                variant="contained"
                onClick={() => {
                    eliminarCache();
                }}
            >
                Limpiar cache
          </Button>
        </Col>
        <Col xs={{ span: 8, offset: 2 }} style={{ marginTop: "3%" }}>
            <PublicidadList />
        </Col>
    </Row>
}

export default withRouter(MainPublicidad);