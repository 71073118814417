import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import "./style.css";
import {renderDetalle} from "../../views/pedidos/utils";
import {Button} from "@material-ui/core";
import ModalPedido from "../../views/pedidos/ModalPedido";

const useStyles = makeStyles(theme => ({
    root: {
        // width: "80%",
        overflowX: "auto"
    },
    table: {
        width: "100%"
    },
    paper: {
        marginLeft: "34%",
        // marginTop: "10%",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 4)
    }
}));

const TablaPedidos = props => {
    const { pedidos } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);
    const [showDetails, setShowDetails] = React.useState(false);
    const [pedidoDetails, setPedidoDetails] = React.useState(null);

    const details = (pedido) => {
        setPedidoDetails(pedido);
        setShowDetails(true);
    }

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, pedidos.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setShowDetails(null);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };
    const classes = useStyles();
    return (
        <div className="tablaPedidos">
            <Paper className={classes.root}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id pedido</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Tarjeta</TableCell>
                            <TableCell align="center">Negocio</TableCell>
                            <TableCell align="center">Ciudad</TableCell>
                            <TableCell align="center">Cadete</TableCell>
                            <TableCell align="center">Estado</TableCell>
                            <TableCell align="center">
                                Monto productos
                            </TableCell>
                            <TableCell align="center">Monto Envio</TableCell>
                            <TableCell align="center">Monto Descuento</TableCell>
                            <TableCell align="center">Total</TableCell>
                            <TableCell align="center">Detalle</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pedidos.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                            .map((pedido, key) => (
                                <TableRow key={key}>
                                    <TableCell component='th' scope='row'>{pedido.id}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {pedido.tipo}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {pedido.tarjeta === 1 ? "Si" : "No"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {pedido.tipo === "Negocio"
                                            ? pedido.negocio.nombre
                                            : "-"}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {pedido.ciudad.nombre}
                                    </TableCell>
                                    <TableCell align="center">
                                        {pedido.cadete && pedido.cadete.nombre}
                                    </TableCell>
                                    <TableCell align="center">
                                        {pedido.estado}
                                    </TableCell>
                                    <TableCell align="center">
                                        {pedido.total}
                                    </TableCell>
                                    <TableCell align="center">
                                        {pedido.precio_envio}
                                    </TableCell>
                                    <TableCell align="center">
                                        {pedido.descuento}
                                    </TableCell>
                                    <TableCell align="right">
                                        {pedido.total + pedido.precio_envio - pedido.descuento}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button onClick={() => details(pedido)}>
                                            Ver
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[30]}
                    component="div"
                    count={pedidos.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                {
                    pedidoDetails &&
                    // renderDetalle(pedidoDetails, setShowDetails, showDetails)
                    <ModalPedido
                        pedido={pedidoDetails}
                        showModal={showDetails}
                        setShowModal={setShowDetails}
                        classes={classes}
                    />
                }
            </Paper>
        </div>
    );
};

export default TablaPedidos;
