import React from "react";
import "./style.css";
import moment from 'moment';
import Estadisticas from "./Estadisticas";

const EstadisticasUsuario = () => {
    return (
        <Estadisticas tipo="usuarios"/>
    );
};

export default EstadisticasUsuario;
