import React from "react";
import { Input } from "@material-ui/core";

const ImageUploader = props => {
  const fileChangedHandler = event => {
    const file = event.target.files[0];
    props.setImagen(file);
  };

  return (
    <React.Fragment>
      <Input type="file" variant="filled" onChange={fileChangedHandler} />
    </React.Fragment>
  );
};

export default ImageUploader;
