import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { TextField, Button, FormControl, MenuItem, Select, Grid, Paper, Table, TableCell, TableBody, TableHead, TableRow } from "@material-ui/core";
import { post, get } from '../../api/axios';
import './style.css';
import moment from 'moment';


const ReporteNegocios = props => {

    const [fechaFin, setFechaFin] = React.useState(moment());
    const [fechaInicio, setFechaInicio] = React.useState(moment());
    const [negocios, setNegocios] = React.useState([]);
    const [negocioId, setNegocioId] = React.useState(0);

    React.useEffect(() => {
        get("/negocios").then(response => {
            let negocios = response.data.sort(function (a, b) {
                if (a.nombre < b.nombre) { return -1; }
                if (a.nombre > b.nombre) { return 1; }
                return 0;
            });
            setNegocios(negocios);
        });
    }, []);
    const handleSubmit = (event) => {
        event.preventDefault();
        post('/reportes/v2/negocio', { id: negocioId, desde: fechaInicio.format('YYYY-MM-DD').concat('T00:00:00'), hasta: fechaFin.format('YYYY-MM-DD').concat('T23:59:59') })
            .then(response => {
                window.open(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div className="formWidth">
            <Grid container >
                <form className="formWidth" onSubmit={(event) => handleSubmit(event)} noValidate className="datePicker">
                    <Grid item xs={8}>
                        <TextField
                            id="fecha"
                            label="Desde"
                            type="date"
                            value={fechaInicio.format("YYYY-MM-DD")}
                            InputLabelProps={{
                                shrink: true
                            }}
                            className="datePicker"
                            onChange={e => setFechaInicio(moment(e.target.value))}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="fecha"
                            label="Hasta"
                            type="date"
                            value={fechaFin.format("YYYY-MM-DD")}
                            InputLabelProps={{
                                shrink: true
                            }}
                            className="datePicker"
                            onChange={e => setFechaFin(moment(e.target.value))}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl fullWidth margin="dense" variant="filled" className="float-right">
                            <Select
                                label="Cadete"
                                value={negocioId}
                                onChange={e => setNegocioId(e.target.value)}
                                variant="filled"
                            >
                                {negocios.map((negocio, key) =>
                                    <MenuItem value={negocio.id} key={key}>
                                        {negocio.nombre}
                                    </MenuItem>
                                )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Button type="submit" variant="contained" color="primary">
                        Reporte
                    </Button>
                </form>
            </Grid>
        </div >
    )
}

export default ReporteNegocios;